import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { AppMenuItem, BrandConfig, UserPreferences, NavigationMenuItem, ParentMenuItem, User } from '@webframework/client-core';

import * as fromApp from './app.reducer';
import * as fromSidenavItems from './sidenav-item.reducer';
import * as fromSidenav from './sidenav.reducer';

export interface MasterPageState {
  app: fromApp.State;
  sidenav: fromSidenav.State;
  sidenavItems: fromSidenavItems.State;
}

export const reducers = {
  app: fromApp.reducer,
  sidenav: fromSidenav.reducer,
  sidenavItems: fromSidenavItems.reducer,
};

export const getMasterPageState: MemoizedSelector<object, MasterPageState> = createFeatureSelector<MasterPageState>(
  'master-page'
);

// App selectors
export const getAppState = createSelector(getMasterPageState, state => state.app);
export const getUser = createSelector(getAppState, state => state.user);
export const getBrandingConfig: MemoizedSelector<
  object,
  BrandConfig | undefined,
  DefaultProjectorFn<BrandConfig | undefined>
> = createSelector(getAppState, state => state.brandConfig);
export const getUserPreferences: MemoizedSelector<
  object,
  UserPreferences,
  DefaultProjectorFn<UserPreferences>
> = createSelector(getAppState, state => state.userPreferences);

// Sidenav items selectors
export const getSidenavItemEntitiesState = createSelector(getMasterPageState, state => state.sidenavItems);

export const getSelectedSideNavId = createSelector(getSidenavItemEntitiesState, state => state.selectedItemId);

// Sidenav selectors
export const getSidenavState = createSelector(getMasterPageState, state => state.sidenav);
export const getSidenavCollapsed = createSelector(getSidenavState, state => state.collapsed);
export const getRootSidenavItems = createSelector(getSidenavState, state => state.rootIds);
export const getExpandedSidenavItems = createSelector(getSidenavState, state => state.expandedIds);
export const getSidenavPopoverItemId = createSelector(getSidenavState, state => state.popoverItemId);

/**
 * Sidenav is the last primary configuration to be loaded. When its loaded, we consider the app to be ready
 */
export const selectAppReady = createSelector(getSidenavState, state => state.loaded);

// Adapters
export const {
  selectIds: getSidenavItemIds,
  selectEntities: getSidenavItemEntities,
  selectAll: getAllSidenavItems,
  selectTotal: getTotalSideNavItems
} = fromSidenavItems.adapter.getSelectors(getSidenavItemEntitiesState);

export const getSelectedSideNav = createSelector(
  getSidenavItemEntities,
  getSelectedSideNavId,
  (entities, selectedId) => (selectedId && entities[selectedId]) as AppMenuItem | null
);

const shouldHideSidenavItem = (item: AppMenuItem | undefined, user: User | null) => {
  if (!item || !user) {
    return true;
  }

  const userRoles = user.roles || [];
  const userGroups = (user.groups || []).map(g => g.id);

  let hideGroup = false,
    hideRole = false;
  if (item.userGroups && item.userGroups.filter(g => userGroups.includes(g)).length === 0) {
    hideGroup = true;
  }

  if (item.userRoles && item.userRoles.filter(r => userRoles.includes(r)).length === 0) {
    hideRole = true;
  }

  return hideGroup || hideRole;
};

export const getSidenavTree = createSelector(
  getSidenavItemEntities,
  getExpandedSidenavItems,
  getRootSidenavItems,
  getUser,
  (appMenuEntities, expandedItems, rootItems, user) =>
    // tslint:disable:no-non-null-assertion
    rootItems.map(id => ({
      ...appMenuEntities[id],
      hidden: shouldHideSidenavItem(appMenuEntities[id], user),
      expanded: expandedItems.indexOf(id) >= 0,
      children:
        appMenuEntities[id]!.type === 'parent'
          ? (appMenuEntities[id] as ParentMenuItem<string>).children.map(cid => appMenuEntities[cid])
          : []
    }))
);

/**
 * Selects the home URL for an application
 * Home URL is picked from branding config, if not found, the first menuurl from the sidenav items is chosen
 */
export const selectHomeUrl = createSelector(
  getRootSidenavItems,
  getAllSidenavItems,
  getBrandingConfig,
  (rootSidenavItems, sidenavItems, brandingConfig) => {
    if (!brandingConfig) {
      return '';
    }

    const [firstUrl] = sidenavItems
      .filter(i => rootSidenavItems.includes(i.id) && i.type === 'navigation')
      .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
      .map((i: NavigationMenuItem) => i.menuurl);

    return brandingConfig.landingPageUrl || firstUrl;
  }
);
