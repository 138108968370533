import { Dictionary } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { HostPanel } from '../models/host-panel';
import { Layout } from '../models/layout';
import { Panel } from '../models/panel';
import { SplitPanel } from '../models/split-panel';
import * as fromLayout from './layout.reducer';
import * as fromPanels from './panels.reducer';

export interface LayoutState {
  panels: fromPanels.State;
  layout: fromLayout.State;
}

export const reducers = {
  panels: fromPanels.reducer,
  layout: fromLayout.reducer,
};

export const getLayoutFeatureState: MemoizedSelector<object, LayoutState> = createFeatureSelector<LayoutState>(
  'layout'
);

// Layout selectors
export const getLayoutState = createSelector(getLayoutFeatureState, (state) => state.layout);

export const getLayout: MemoizedSelector<object, Layout | null> = createSelector(
  getLayoutState,
  (state) => state.layout
);

// Panel selectors
export const getPanelEntitiesState = createSelector(getLayoutFeatureState, (state) => state.panels);

export const getMaximizedPanelId = createSelector(getPanelEntitiesState, (state) => state.maximizedPanelId);

// Adapters
export const {
  selectIds: getPanelIds,
  selectEntities: getPanelEntities,
  selectAll: getAllPanels,
  selectTotal: getTotalPanels,
} = fromPanels.adapter.getSelectors(getPanelEntitiesState);

export const getCollapsedPanelsNames = createSelector(getAllPanels, (panels) =>
  panels.filter((p) => p.collapsed).map((panel) => [panel.id, (<HostPanel>panel).config.title])
);

export const getDenormalizedLayout = createSelector(
  getLayout,
  getAllPanels,
  getPanelEntities,
  getMaximizedPanelId,
  (layout, panels, entities, maximizedPanelId) => denormalizeLayout(layout, panels, entities, maximizedPanelId)
);

function denormalizeLayout(
  splitPanel: SplitPanel | null,
  allPanels: Panel[],
  entities: Dictionary<Panel>,
  maximizedPanelId: string | null
): Panel {
  if (!splitPanel) {
    return {
      id: '',
      type: '',
      ratio: 1,
      collapsed: true,
      config: {}
    };
  }

  const childLayouts = splitPanel.layouts.map((id) =>
    // tslint:disable-next-line:no-non-null-assertion
    entities[id]!.type === 'panel'
      ? {
          ...entities[id],
          maximized: !!maximizedPanelId,
          // tslint:disable-next-line:no-non-null-assertion
          collapsed: maximizedPanelId ? id !== maximizedPanelId : entities[id]!.collapsed,
        }
      : denormalizeLayout(entities[id] as SplitPanel, allPanels, entities, maximizedPanelId)
  );

  return {
    ...splitPanel,
    collapsed: childLayouts.every((p) => p.collapsed),
    layouts: childLayouts,
  } as Panel;
}
