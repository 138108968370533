import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const go = createAction(
  '[Router] Go',
  props<{
    payload: {
      path: unknown[];
      query?: Record<string, unknown>;
      extras?: NavigationExtras;
    };
  }>()
);

export const add = createAction(
  '[Router] Add',
  props<{
    url: string;
  }>()
);

export const back = createAction('[Router] Back');
export const forward = createAction('[Router] Forward');
