import { Observable, of } from 'rxjs';

export class TranslateServiceStub {
  public onTranslationChange = of({});
  public onLangChange = of({});
  public onDefaultLangChange = of({});

  public get(key: unknown): Observable<unknown> {
    return of(key);
  }
}
