import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@webframework/client-core';
import { CoreService } from 'libs/shared/src/lib/Services/core.service';

import { ContractManagementService } from '../../../../shared/src/lib/Services/contract-management.service';
import { UsersAdministrationService } from '../../../../shared/src/lib/Services/users-administration.service';

@Component({
  selector: 'lesmes-user-drawer-contract-viewer',
  templateUrl: './user-drawer-contract-viewer.component.html',
  styleUrls: ['./user-drawer-contract-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDrawerContractViewerComponent
  implements OnInit, AfterViewInit {
  public static group = 'Contratos';
  public static title = 'Mis Contratos Asignados';

  contracts: any[];

  constructor(private coreService: CoreService, private cdkRef:ChangeDetectorRef) {
    this.coreService
      .getCurrentUserContracts()
      .then((data) => {
        this.contracts = [...data]
        cdkRef.detectChanges();
      });
  }

  reloadContracts(){
    this.coreService.currentUserContracts = null;
    this.coreService
      .getCurrentUserContracts()
      .then((data) => {
        this.contracts = [...data]
        this.cdkRef.detectChanges();
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}
}
