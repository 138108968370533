import { Component, Input, HostBinding, ChangeDetectionStrategy, Optional } from '@angular/core';
import { ProgressSpinnerService } from '../../services/progress-spinner.service';
import { Observable } from 'rxjs';
import { SpinnerState } from '../../models/spinner-state.interface';

@Component({
  selector: 'wfc-progress-spinner',
  templateUrl: './progress-spinner.html',
  styleUrls: ['./progress-spinner.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerComponent {
  @HostBinding('class.wfc-active') @Input() showProgressSpinner = false;
  spinnerState$?: Observable<SpinnerState>;

  constructor(@Optional() progressSpinnerService?: ProgressSpinnerService) {
    if (progressSpinnerService !== undefined) {
      this.spinnerState$ = progressSpinnerService.showIndicator$;
    }
  }
}
