import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleJumbotronComponent } from './Components/title-jumbotron/title-jumbotron.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CostDetailsComponent } from './Components/cost-details/cost-details.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';

import { DayToDayCostsTableComponent } from './Components/day-to-day-costs-table/day-to-day-costs-table.component';
import { EntrySheetViewerComponent } from './Components/entry-sheet-viewer/entry-sheet-viewer.component';
import { ServiceTicketDetailsComponent } from './Components/service-ticket-details/service-ticket-details.component';
import { ServiceTicketEmailingComponent } from './Components/service-ticket-emailing/service-ticket-emailing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppNotificationsSidebarComponent } from './Components/app-notifications-sidebar/app-notifications-sidebar.component';
import {StepsModule} from 'primeng/steps';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar'
import {CheckboxModule} from 'primeng/checkbox';
import { UserIdPipe } from './Pipes/user-id.pipe';


@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    ButtonModule,
    DynamicDialogModule,
    DialogModule,
    TableModule,
    InputTextModule,
    InputTextareaModule,
    MessageModule,
    MessagesModule,
    CardModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
    ChipsModule,
    ConfirmDialogModule,
    SidebarModule,
    StepsModule,
    CardModule,
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule
  ],
  declarations: [
    TitleJumbotronComponent,
    CostDetailsComponent,
    DayToDayCostsTableComponent,
    EntrySheetViewerComponent,
    ServiceTicketDetailsComponent,
    ServiceTicketEmailingComponent,
    AppNotificationsSidebarComponent,
    UserIdPipe,
  ],
  exports: [
    TitleJumbotronComponent,
    CostDetailsComponent,
    DayToDayCostsTableComponent,
    EntrySheetViewerComponent,
    ServiceTicketDetailsComponent,
    ServiceTicketEmailingComponent,
    AppNotificationsSidebarComponent,
    UserIdPipe,
  ],
 
})
export class SharedModule {}
