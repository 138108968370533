import { Component, EventEmitter, Output } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import {
  ActionBar,
  AppMenuItem,
  BrandConfig,
  BroadcastService,
  BrowserHistoryService,
  HistoryStorage,
  ParentMenuItem,
  RouterActions,
  SessionService,
  User
} from '@webframework/client-core';
import * as fromLayoutPage from '@webframework/client-layout';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as appActions from '../actions/app.actions';
import * as sidenavItem from '../actions/sidenav-item.actions';
import * as sidenav from '../actions/sidenav.actions';
import * as fromMasterPage from '../reducers/root.reducer';
import { fadeOutWhenDone } from './master-page.component.animations';


@Component({
  selector: 'wfw-base, wfc-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
  animations: [fadeOutWhenDone]
})
export class MasterPageComponent {
  @Output()
  showSupportAssistant = new EventEmitter();


  public hamburgerClick$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public sidenavVisible$: Observable<boolean>;

  public sidenavItems$: Observable<any>;
  public selectedSidenavItem$: Observable<AppMenuItem | null>;
  public sidenavCollapsed$: Observable<boolean>;
  public sidenavPopoverItemId$: Observable<string | null>;

  public brandingConfig$: Observable<BrandConfig | undefined> = this.store.pipe(
    select(fromMasterPage.getBrandingConfig)
  );
  public readonly layoutConfig$: Observable<fromLayoutPage.Layout | null> = this.layoutstore.pipe(
    select(fromLayoutPage.getLayout)
  );
  public readonly subHeaderVisible$: Observable<boolean> = this.brandingConfig$.pipe(
    map(brand => !!brand?.subHeaderVisible)
  );
  public readonly actionBar$: Observable<ActionBar[] | undefined> = this.brandingConfig$.pipe(
    map(brand => brand?.actionBar)
  );
  public readonly isStartPage$: Observable<boolean> = this.historyService.storageChanges.pipe(
    map((storage: HistoryStorage) => storage.currentIndex),
    map(index => index === 0)
  );

  public userName$: Observable<string>;
  public user$: Observable<User> = this.sessionService.onReady$;
  public appReady$ = this.store.pipe(select(fromMasterPage.selectAppReady));
  public currentYear: number = new Date().getFullYear();

  constructor(
    private readonly broadcastService: BroadcastService,
    private readonly store: Store<fromMasterPage.MasterPageState>,
    private readonly layoutstore: Store<fromLayoutPage.LayoutState>,
    private readonly sessionService: SessionService,
    private readonly historyService: BrowserHistoryService

  ) {
    this.userName$ = this.sessionService.onReady$.pipe(map(user => user.userName));
    const visiblity$ = this.brandingConfig$.pipe(
      map(config => {
        this.hamburgerClick$.next(!(config && config.hideSidenavOnLoad));

        return !(config && config.hideSidenavOnLoad);
      })
    );
    this.sidenavVisible$ = merge(visiblity$, this.hamburgerClick$);
    this.sidenavItems$ = store.pipe(select(fromMasterPage.getSidenavTree));
    this.selectedSidenavItem$ = store.pipe(select(fromMasterPage.getSelectedSideNav));
    this.sidenavCollapsed$ = store.pipe(select(fromMasterPage.getSidenavCollapsed));
    this.sidenavPopoverItemId$ = store.pipe(select(fromMasterPage.getSidenavPopoverItemId));

    // Deprecated: Close popover via broadcast event
    this.broadcastService.on('leftMenu:popover:close').subscribe(() => {
      console.warn('Closing popover via broadcast event is deprecated. Please use ngrx Actions instead');
      this.closePopover();
    });

    // Deprecated: Update notification count via broadcast event
    this.broadcastService.on('leftMenu:badge:set').subscribe((data: any) => {
      console.warn('Updating notification count via broadcast event is deprecated. Please use ngrx Actions instead');
      this.store.dispatch(
        sidenavItem.update({
          id: data.url,
          changes: {
            notificationcount: data.badgeCount
          }
        })
      );
    });

    this.store.dispatch(appActions.loadUser());
  }



  public toggleSidenav() {
    this.store.dispatch(sidenav.toggle());
  }

  public sidenavItemClicked(payload: AppMenuItem) {
    this.store.dispatch(sidenavItem.click({ payload }));
  }

  public goBack() {

    this.store.dispatch(RouterActions.back())
  }

  public closePopover() {
    this.store.dispatch(sidenav.popover({ payload: null }));
  }
  public sidenavItemToggle({ id }: ParentMenuItem) {
    this.store.dispatch(sidenavItem.toggle({ payload: id }));
  }

  public sidenavIconFlashed({ id }: Pick<AppMenuItem, 'id'>) {
    this.store.dispatch(sidenavItem.update({ id, changes: { flashIcon: false } }));
  }

  public onAction(action: Action) {
    this.store.dispatch(action);
  }

  toggleSidenavVisibility() {
    this.hamburgerClick$.next(!this.hamburgerClick$.value);
  }

  logout() {
    this.store.dispatch({
      type: '[Session] Logout'
    });
  }
}
