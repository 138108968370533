import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ActivityTrackerService,
  AuthenticationService,
  BrandConfig,
  UserPreferences,
  User,
  UserConfigService,
  WebFrameworkService,
  ThemeService
} from '@webframework/client-core';
import { of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as appActions from '../actions/app.actions';
import * as sidenavActions from '../actions/sidenav.actions';
import * as fromMasterPage from '../reducers/root.reducer';

@Injectable()
/**
 * This effect loads all the primary configurations of the app.
 * The configurations are loaded in the following order, once we have the user object:
 * Branding Config => Translations => Sidenav
 */
export class AppEffects {
  loadUser$ = createEffect(() =>
    this._action$.pipe(
      ofType(appActions.loadUser),
      mergeMap(() =>
        this._authService.getUser().pipe(
          switchMap((user: User) => [appActions.loadUserSuccess({ user }), appActions.loadBrandingConfig()]),
          catchError((error: string) => of(appActions.loadUserFail({ error })))
        )
      )
    )
  );

  loadBrandingConfig$ = createEffect(() =>
    this._action$.pipe(
      ofType(appActions.loadBrandingConfig),
      mergeMap(() =>
        this._webframeworkService.getBrandingConfig().pipe(
          tap(brandConfig => this.setupActivityTracker(brandConfig)),
          map(brandConfig => appActions.loadBrandingConfigSuccess({ brandConfig })),
          catchError(error => of(appActions.loadBrandingConfigFail({ error })))
        )
      )
    )
  );

  /**
   * Setup default configs from branding config
   */
  loadBrandingConfigSuccess$ = createEffect(() =>
    this._action$.pipe(
      ofType(appActions.loadBrandingConfigSuccess),
      withLatestFrom(this.store.pipe(select(fromMasterPage.getUserPreferences))),
      // Load the translations once branding config is loaded
      switchMap(([{ brandConfig }, defaultUserPreferences]) => {
        // read all the app configured locales
        const locales = brandConfig.locales || (brandConfig.languages || []).map(l => ({ value: l, label: l }));
        // get either the first entry from configured locales or from system default locale to use it as default locale
        const locale = (locales && locales.length) > 0 ? ({ ...locales[0] }) : defaultUserPreferences.locale;

        // set defaultlang for translate service to use it as a fallback language
        this._translateService.setDefaultLang(locale.value);

        // Try loading the locale configuration for user
        return this._userConfigService.getSharedConfig<UserPreferences>('preferences').pipe(
          map(userPreferences =>
            /* if the prefered locale is not the one user configured for the application,
             then fallback to the default locale above */
            (userPreferences.locale && locales.find((l: any) => l.value == userPreferences.locale.value))
              ? userPreferences
              : ({ ...(userPreferences || defaultUserPreferences || {}),
                locale: locale, __fallbackLocale: userPreferences.locale })),
          // Fallback to the default locale configured in branding configuration
          catchError(() =>
            // get the first locale from locales and set it as default
            of({ ...defaultUserPreferences, locale: locale })
          ));
      }),
      // change the language currently translate service use
      mergeMap((userPreferences) => this._translateService.use(userPreferences.locale.value).pipe(
        // change the theme currently translate service use
        tap(_ => this._themeService.setTheme(userPreferences.theme)),
        map(_ => appActions.setUserPreferencesSuccess({ userPreferences })),
        catchError(error => of(appActions.setUserPreferencesFail(error))))
      ))
  );

  /**
   * Try to load the user preferences and save the preferences to user configuration
  */
  setUserPreferences$ = createEffect(() =>
    this._action$.pipe(
      ofType(appActions.setUserPreferences),
      withLatestFrom(this.store.pipe(select(fromMasterPage.getUserPreferences))),
      map(([currentPreferences, previousPreferences]) => (
        { ...previousPreferences, ...currentPreferences.userPreferences })),
      // change the language currently translate service use
      mergeMap((userPreferences) => this._translateService.use(userPreferences.locale.value).pipe(
        // change the theme currently translate service use
        tap(_ => this._themeService.setTheme(userPreferences.theme)),
        // update user preferences
        switchMap(_ => {
          // destruct preferences object to not to persist `fallback` property when user changes his preferences
          let { __fallbackLocale, ...preferences } = userPreferences;
          // if the fallback locale available, but user hasn't changed locale
          // then do not persist default locale
          if (__fallbackLocale && this._translateService.getDefaultLang() === preferences.locale.value) {
            preferences = { ...preferences, locale: __fallbackLocale };
          }

          return this._userConfigService.setSharedConfig('preferences', preferences);
        }),
        map(_ => appActions.setUserPreferencesSuccess({ userPreferences })),
        catchError(error => of(appActions.setUserPreferencesFail(error))))
      ))
  );

  /**
   * Request to load sidenav when the first translation is loaded
   */
  setUserPreferencesSuccess$ = createEffect(() =>
    this._action$.pipe(
      ofType(appActions.setUserPreferencesSuccess),
      first(),
      map(_ => sidenavActions.load())
    )
  );

  constructor(
    private readonly _action$: Actions,
    private readonly store: Store<any>,
    private readonly _authService: AuthenticationService,
    private readonly _webframeworkService: WebFrameworkService,
    private readonly _activityTracker: ActivityTrackerService,
    private readonly _translateService: TranslateService,
    private readonly _userConfigService: UserConfigService,
    private readonly _themeService: ThemeService
  ) { }

  private setupActivityTracker(brandingConfig: BrandConfig) {
    if (brandingConfig.enableTracking) {
      this._activityTracker.organizationName = brandingConfig.organizationName;
      this._activityTracker.enable();
    }
  }
}
