import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthenticationService, SKIP_AUTH_HEADER } from '../services/authentication.service';
import { OFFLINE_TOKEN_HEADER, WebFrameworkService } from '../services/webframework.service';

@Injectable()
/**
 * An `HttpInterceptor` to add Authorization header to all the HttpClient requests.
 * Additionally, add offline token to requests if needed.
 */
export class AuthInterceptor implements HttpInterceptor {
  // Set this to false for unit testing
  public static shouldCountInstance = true;
  private static instanceCount = 0;

  constructor(
    private readonly _http: HttpClient,
    private readonly _authService: AuthenticationService,
    private readonly _wfService: WebFrameworkService
  ) {
    AuthInterceptor.instanceCount++;

    if (AuthInterceptor.shouldCountInstance && AuthInterceptor.instanceCount > 1) {
      throw new Error(
        'AuthInterceptor: multiple instances found. ' +
        'Please make sure AuthInterceptor is provided only once across your application'
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- type inherited from angular
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.addAuthorization(request).pipe(
      switchMap(req => this.addOfflineToken(req)),
      switchMap(req => next.handle(req)),
      catchError(error => this.handleOfflineTokenRequired(request, error))
    );
  }

  /**
   * Adds offline token to header if the request contains X-WF-OFFLINE-TOKEN header
   * @param req The request object
   */
  private addOfflineToken<T>(req: HttpRequest<T>): Observable<HttpRequest<T>> {
    // The presence of X-WF-OFFLINE-TOKEN header means that we have to include offline token header to the request
    if (req.headers.get(OFFLINE_TOKEN_HEADER) !== null) {
      return this._wfService
        .getOfflineToken(req.headers.get('X-WF-OFFLINE-CLIENT-ID') || void 0)
        .pipe(switchMap(token => of(req.clone({ setHeaders: { [OFFLINE_TOKEN_HEADER]: token } }))));
    } else {
      return of(req);
    }
  }

  /**
   * Intercepts the response from the server to check for the X-WF-OFFLINE-TOKEN-REQUIRED header,
   * and if found, sends the request again with offline token
   * @param request The request object
   * @param error Error thrown from the server
   */
  private handleOfflineTokenRequired<T>(request: HttpRequest<T>, error: HttpErrorResponse) {
    if (error.status === 400 && error.headers.get('X-WF-OFFLINE-TOKEN-REQUIRED') !== null) {
      return this._http.request(request.clone({ setHeaders: { [OFFLINE_TOKEN_HEADER]: 'TRUE' } }));
    } else {
      return throwError(error);
    }
  }

  /**
   * Add the Authorization header to all the requests.
   * Skips if X-WF-SKIP-AUTH header is found
   * @param req The request object
   */
  private addAuthorization<T>(req: HttpRequest<T>): Observable<HttpRequest<T>> {
    // The presence of X-WF-SKIP-AUTH header means that we do not have to inject Authorization header
    if (req.headers.get(SKIP_AUTH_HEADER) === 'true') {
      const headers = req.headers.delete(SKIP_AUTH_HEADER);

      return of(req.clone({ headers }));
    } else {
      return this._authService.getUser().pipe(
        // Get the token from user object
        map(user => `Bearer ${user.token}`),
        // Add authorization header
        // eslint-disable-next-line @typescript-eslint/naming-convention -- HTTP Header
        map(Authorization => req.clone({ setHeaders: { Authorization } }))
      );
    }
  }
}
