import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as layoutActions from '../actions/layout.actions';
import * as panelActions from '../actions/panel.actions';
import { Panel } from '../models/panel';

export interface State extends EntityState<Panel> {
  maximizedPanelId: string | null;
}

export const adapter: EntityAdapter<Panel> = createEntityAdapter<Panel>({
  selectId: (p) => p.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  maximizedPanelId: null,
});

const panelsReducer = createReducer(
  initialState,
  on(layoutActions.loadSuccess, (state, { panels }) => ({
    ...adapter.addMany(panels, adapter.removeAll(state)),
    maximizedPanelId: null,
  })),
  on(panelActions.title, (state, { id, title }) => {
    return state.entities[id] ? ({
      ...adapter.updateOne({
        id: id,
        changes: {
          ...state.entities[id],
          config: {
            ...(state.entities[id] as any).config,
            title: title
          }
        }
      },
        state),
      maximizedPanelId: null,
    }) : state;
  }),
  on(panelActions.maximize, (state, { payload }) => ({
    ...state,
    maximizedPanelId: payload,
  })),
  on(panelActions.collapse, (state, { payload }) => ({
    ...adapter.updateOne({ id: payload, changes: { collapsed: true } }, state),
    maximizedPanelId: null,
  })),
  on(panelActions.restore, (state, { payload }) => ({
    ...adapter.updateOne({ id: payload, changes: { collapsed: false } }, state),
    maximizedPanelId: null,
  }))
);

export function reducer(state: State, action: Action) {
  return panelsReducer(state, action);
}

export const getMaximizedPanelId = (state: State) => state.maximizedPanelId;
