import {
  Component,
  ComponentRef,
  InjectionToken,
  Injector,
  Input,
  NgModuleFactory,
  OnDestroy,
  SystemJsNgModuleLoader,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

export const COMPONENT_OPTIONS = new InjectionToken('component.options');

@Component({
  selector: 'wfc-dynamic-view',
  template: '<ng-container #container></ng-container>',
  // TODO: SystemJsNgModuleLoader is deprecated: the `string` form of `loadChildren` ispart of its implementation.
  // TODO: See `LoadChildren` for more details.
  providers: [SystemJsNgModuleLoader] // tslint:disable-line
})
export class DynamicViewComponent implements OnDestroy {
  @Input()
  set componentPath(value: string) {
    this._componentPath = value;
    this.loadComponent();
  }

  @Input()
  options: any;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  viewContainerRef: ViewContainerRef | null = null;

  private _componentPath: string | null = null;
  private _componetRef: ComponentRef<any> | null = null;

  constructor(private readonly moduleLoader: SystemJsNgModuleLoader) { } // tslint:disable-line

  loadComponent() {
    if (!this._componentPath) {
      return;
    }

    this.ngOnDestroy();

    this.moduleLoader
      .load(this._componentPath)
      .then((moduleFactory: NgModuleFactory<any>) => {
        if (!this.viewContainerRef) {
          throw new Error('Failed to get container reference.');
        }

        const entryComponent = (<any>moduleFactory.moduleType).entry;
        const componentVersion = entryComponent.version;
        if (!entryComponent) {
          throw new Error(`Static property "entry" not found in ${moduleFactory.moduleType.name}`);
        }
        const ngModuleRef = moduleFactory.create(this.viewContainerRef.injector);
        const compFactory = ngModuleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
        const injector = Injector.create({
          providers: [{ provide: COMPONENT_OPTIONS, useValue: this.options }],
          parent: ngModuleRef.injector
        });
        this._componetRef = this.viewContainerRef.createComponent(compFactory, 0, injector);
        if (componentVersion) {
          this._componetRef.location.nativeElement.setAttribute('wfc-version', componentVersion);
        }
      })
      .catch(error => console.error(error));
  }

  ngOnDestroy() {
    if (this._componetRef) {
      this._componetRef.destroy();
    }
  }
}
