import { Action } from '@ngrx/store';

export namespace headerActions {
  export const UPDATE_BADGE = '[Header] Update Badge';

  /**
   * @deprecated The action has been moved to apps' actions. Use fromApp.updateBadge instead
   */
  export class UpdateBadge implements Action {
    readonly type = UPDATE_BADGE;

    constructor(public id: string, public payload: number) {}
  }
}
