import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconRegistry } from './icon-registry';
import { IconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [IconComponent],
  exports: [IconComponent]
})
export class IconModule {
  static done = false;

  constructor(@Optional() registry: IconRegistry) {
    if (registry && !IconModule.done) {
      registry.addSvgIconSet('assets/icons/sprite/svg/sprite.css.svg');
      IconModule.done = true;
    }
  }

  static forRoot(): ModuleWithProviders<IconModule> {
    return {
      ngModule: IconModule,
      providers: [IconRegistry]
    };
  }
}
