/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, Output, EventEmitter, OnChanges, ViewChild} from '@angular/core';
import { DataSourceItem } from './dsp.generic-resource-access-data-source-item';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '@webframework/notification';
import { Listbox } from 'primeng/listbox';
@Component({
  selector: 'wfc-generic-resource-access-dialog',
  templateUrl: './dsp.generic-resource-access-dialog.html',
  styleUrls: ['./dsp.generic-resource-access-dialog.scss']
})
export class GenericResourceAccessDialogComponent implements OnChanges {

  @ViewChild('source', { static: false }) sourceList: Listbox;
  @ViewChild('destination', { static: false }) selectedList: Listbox;

  @Input() resources: any;
  @Input() dialogTitle = '';
  @Input() dialogDescription = '';

  @Input() sourceListTitle = '';
  @Input() sourceListDataSourceInput: any[] = [];

  @Input() selectedListTitle = '';
  @Input() selectedListDataSourceInput: any[] = [];

  @Output() saveButtonClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();
  @Output() searchButtonClicked = new EventEmitter();

  addBtn: boolean;
  addAllBtn: boolean;
  removeBtn: boolean;
  removeAllBtn: boolean;
  selectedItems: any;

  form: FormGroup;

  sourceListDataSource: DataSourceItem[] = [];
  selectedListDataSource: DataSourceItem[] = [];
  sourceDataSourceOrignal: DataSourceItem[] = [];
  sourceListDataSourceSearched: DataSourceItem[] = [];

  popupdisplay = false;

  sourceSearch: string;
  private newSourceItems: string[] = [];

  constructor(private readonly notification: NotificationService) {
    this.sourceSearch = '';
    this.form = new FormGroup({
      sourceSearch: new FormControl()
    });

    // initialize the items array to bind with ui
    this.sourceListDataSource = [];
    this.selectedListDataSource = [];
  }
  ngOnChanges(_temp: any): void {
    this.prepareSourceListItems();
    this.prepareSelectedListItems();
    this.removeSelectedItemFromSourceList();
  }
  saveProduct(_isValid: boolean): void {
    this.hideErrorMessage();
    if (this.saveButtonClicked !== undefined) {
      if (this.newSourceItems.length > 0) {
        this.selectedListDataSource.forEach((item: DataSourceItem) => {
          item.isNew = this.newSourceItems.indexOf(item.value) !== -1;
        });
      }
      this.saveButtonClicked.emit(this.selectedListDataSource);
      this.sourceListDataSourceInput = [];
      this.selectedListDataSourceInput = [];
    }
    // hide this popup control
    this.popupdisplay = false;
  }
  destroyLoadedObject(_event: Event): void {
    this.resetState();
    this.popupdisplay = false;
    if (this.cancelButtonClicked !== undefined) {
      this.cancelButtonClicked.emit();
      this.sourceListDataSourceInput = [];
      this.selectedListDataSourceInput = [];
    }
  }
  resetState(): void {
    this.clearMessages();
    // clear the items array to be bound
    this.sourceListDataSource.length = 0;
    this.selectedListDataSource.length = 0;
  }
  clearMessages(): void {
    this.hideErrorMessage();
    this.hideInfoMessage();
    this.hideSuccessMessage();
  }

  sourceListSelectionChanged(_options: unknown): void {
    this.enableDisableAddButtons(_options);
  }

  selectedListSelectionChanged(_options: unknown): void {
    this.enableDisableRemoveButtons(_options);
  }

  showDialog(): void {
    this.prepareSourceListItems();
    this.prepareSelectedListItems();
    this.removeSelectedItemFromSourceList();
    // show this popup
    this.popupdisplay = true;
  }

  addSelectedItem(): void {
    const sourceListDataSourceItems = this.sourceListDataSource;
    this.sourceSearch = '';
    this.searchClicked();
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(item => {
        // check if this item is not already added
        let existingItems: DataSourceItem[] = [];
        // eslint-disable-next-line arrow-body-style
        existingItems = this.selectedListDataSource.filter((selectedItem: DataSourceItem) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return selectedItem.value === item.value;
        });
        // remove from source list data source as it is going to add to the selected list
        const indx = sourceListDataSourceItems.findIndex((sourceDataSourceItem: DataSourceItem) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          item.value === sourceDataSourceItem.value && item.text === sourceDataSourceItem.text
        );
        if (indx > -1) {
          sourceListDataSourceItems.splice(indx, 1);
        }
        // if it is not already added then add it
        if (existingItems.length < 1) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.selectedListDataSource = [...this.selectedListDataSource, item];
        }
      });
      this.sourceListDataSource = [...sourceListDataSourceItems];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.enableDisableAddButtons(this.selectedItems[0]);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.enableDisableRemoveButtons(this.selectedItems[0]);
    }
  }
  addAllItems(): void {
    const allSourceListItems = this.sourceListDataSource;
    this.sourceSearch = '';
    this.searchClicked();
    if (allSourceListItems.length > 0) {
      allSourceListItems.forEach(item => {
        // check if this item is not already added
        const alreadyAddedItems = this.selectedListDataSource.filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars,  arrow-body-style
          (selectedItem: DataSourceItem, _itemIndex: number) => {
            return selectedItem.value === item.value;
          });
        // if it is not already added then add it
        if (alreadyAddedItems.length < 1) {
          this.selectedListDataSource = [...this.selectedListDataSource, item];
        }
      });
      // remove all items from source list data source as these all are added to selected list data source
      this.sourceListDataSource = [];
      this.enableDisableAddButtons();
      this.enableDisableRemoveButtons();
    }
  }

  removeSelectedItem(): void {
    const selectionListDataSourceItems = this.selectedListDataSource;
    if (this.selectedItems.length > 0) {
      this.selectedItems.forEach(item => {
        // check if this item is not already added
        let existingItems: DataSourceItem[] = [];
        // eslint-disable-next-line arrow-body-style
        existingItems = this.sourceListDataSource.filter((selectedItem: DataSourceItem) => {
          return selectedItem.value === item.value;
        });
        // remove from selection list data source as it is going to be removed from the source list
        const indx = selectionListDataSourceItems.findIndex((selectionDataSourceItem: DataSourceItem) =>
          item.value === selectionDataSourceItem.value && item.text === selectionDataSourceItem.text
        );
        if (indx > -1) {
          selectionListDataSourceItems.splice(indx, 1);
        }
        // if it is not already added then add it
        if (existingItems.length < 1) {
          this.sourceListDataSource = <DataSourceItem[]>[...this.sourceListDataSource, item];
        }
      });
      this.selectedListDataSource = [...selectionListDataSourceItems];
      this.enableDisableAddButtons();
      this.enableDisableRemoveButtons();
    }
  }
  removeAllItems(): void {
    // add these objects back to source list data source
    if (this.selectedListDataSource.length > 0) {
      this.selectedListDataSource.forEach(item => {
        const alreadyAddedItems = this.sourceListDataSource.filter(
          // eslint-disable-next-line arrow-body-style
          (sourceItem: DataSourceItem, _itemIndex: number) => {
            return sourceItem.value === item.value;
          });
          // if it is not already added then add it
        if (alreadyAddedItems.length < 1) {
          this.sourceListDataSource = [...this.sourceListDataSource, item];
        }
      });
      // clear the selected list data source
      this.selectedListDataSource = [];
    }
    this.enableDisableAddButtons();
    this.enableDisableRemoveButtons();
  }

  prepareSelectedListItems(): void {
    if (this.selectedListDataSourceInput !== undefined) {
      let isValid = true;
      if (this.selectedListDataSourceInput instanceof Array) {
        this.selectedListDataSourceInput.forEach((item: DataSourceItem) => {
          if (!(item instanceof DataSourceItem)) {
            isValid = false;
          }
        });
      } else {
        isValid = false;
      }
      if (isValid) {
        // set the selected list items to bind with ui
        this.selectedListDataSource = <DataSourceItem[]>this.selectedListDataSourceInput.slice();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        this.showErrorMessage(this.resources.invalidSelectedListDataSource.Text.
          replace('{0}', this.selectedListTitle));

        return;
      }
    } else {
      // clear the array
      this.selectedListDataSource.length = 0;
    }
  }

  prepareSourceListItems(): void {

    if (this.sourceListDataSourceInput !== undefined) {
      let isValid = true;
      if (this.sourceListDataSourceInput instanceof Array) {
        for (let i = this.sourceListDataSourceInput.length - 1; i > -1; i--) {
          const item = this.sourceListDataSourceInput[i];
          if (!(item instanceof DataSourceItem)) {
            isValid = false;
          }
        }
      } else {
        isValid = false;
      }
      if (isValid) {
        // set the source list items to bind with ui
        this.sourceListDataSource = this.sourceListDataSourceInput.slice();
        this.sourceDataSourceOrignal = this.sourceListDataSourceInput.slice();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        this.showErrorMessage(this.resources.invalidsourceListDataSourceInput.Text.
          replace('{0}', this.sourceListTitle));

        return;
      }
    } else {
      // clear the array
      this.sourceListDataSource.length = 0;
    }
  }

  removeSelectedItemFromSourceList(): void {
    // Remove selected item from source list.
    if (this.selectedListDataSource.length > 0 && this.sourceListDataSource.length > 0) {
      this.selectedListDataSource.forEach(selectedDataSourceItem => {
        for (let i = this.sourceListDataSource.length - 1; i > -1; i--) {
          const sourceDataSourceItem = this.sourceListDataSource[i];
          if (sourceDataSourceItem.value === selectedDataSourceItem.value &&
                    sourceDataSourceItem.text === selectedDataSourceItem.text) {
            this.sourceListDataSource.splice(i, 1);
          }
        }
      });
      this.sourceDataSourceOrignal = this.sourceListDataSource;
    }
    this.enableDisableAddButtons();
    this.enableDisableRemoveButtons();
  }

  getSelectedItemsFromDesiredList(desiredSelectedList: any[], _options?: any): DataSourceItem[] {
    const selectedItemsList: DataSourceItem[] = [];
    for (let i = 0; i < desiredSelectedList.length; i++) {
      const option = desiredSelectedList[i];

      if (option.text === _options.text) {
        const item = new DataSourceItem();
        item.value = option.value;
        item.text = option.text;
        selectedItemsList.push(item);
      }
    }

    return selectedItemsList;
  }

  getSelectedItems(selectElement: any, _options?: any): DataSourceItem[] {
    let desiredSelectedList: any[] = [];
    let selectedItemsList: any[] = [];
    const options = selectElement.options;
    // Check item is selected or not
    if (selectElement !== undefined && options && options.length > 0 && _options !== undefined) {
      // Get selected items with its hierarchical items [This is added for filtered items by search]
      desiredSelectedList = selectElement.options;
      selectedItemsList = this.getSelectedItemsFromDesiredList(desiredSelectedList, _options);
    }

    return <DataSourceItem[]>selectedItemsList;
  }

  enableDisableRemoveButtons(_options?: any): void {
    if (this.selectedList !== undefined) {
      // get selected options from selected list select element
      this.selectedItems = this.getSelectedItems(this.selectedList, _options);
      if (this.selectedItems.length > 0) {
        this.removeBtn = false;
      } else {
        this.removeBtn = true;
      }
      // check if selected list items still has options
      if (this.selectedListDataSource.length > 0) {
        this.removeAllBtn = false;
      } else {
        this.removeAllBtn = true;
      }
    }
  }

  enableDisableAddButtons(_options?: any): void {
    if (this.sourceList !== undefined) {
      // get list of options from source select element
      this.selectedItems = this.getSelectedItems(this.sourceList, _options);
      if (this.selectedItems.length > 0) {
        this.addBtn  = false;
      } else {
        this.addBtn  = true;
      }

      // check if there is at-lease one option in source select element
      if (this.sourceListDataSource.length > 0) {
        this.addAllBtn  = false;
      } else {
        this.addAllBtn  = true;
      }
    }
  }

  showErrorMessage(messageToShow: string): void {
    this.notification.clear();
    this.notification.addMessage({
      severity: 'error',
      summary: messageToShow,
      closable: true,
      autoClear: true,
      notificationTimeout: 10000
    });
  }

  hideErrorMessage(): void {
    this.notification.clear();
  }

  showSuccessMessage(successMessageToShow: string): void {
    this.notification.clear();
    this.notification.addMessage({
      severity: 'success',
      summary: successMessageToShow,
      closable: true,
      autoClear: true,
      notificationTimeout: 10000
    });
  }

  hideSuccessMessage(): void {
    this.notification.clear();
  }

  showInfoMessage(infoMessageToShow: string): void {
    this.notification.clear();
    this.notification.addMessage({
      severity: 'info',
      summary: infoMessageToShow,
      closable: true,
      autoClear: true,
      notificationTimeout: 10000
    });
  }

  hideInfoMessage(): void {
    this.notification.clear();
  }

  performSearch(seachedText: string, orignalListOptions: DataSourceItem[]): void {
    const tempSearchedList: any[] = [];
    this.newSourceItems = (orignalListOptions.filter((source: DataSourceItem) => !!source.isNew))
      .map((item: DataSourceItem) => item.value);
    this.performNormalSearch(seachedText, orignalListOptions, tempSearchedList);
    this.validateButtonStates();
  }

  performNormalSearch(seachedText: string, orignalListOptions: DataSourceItem[], tempSearchedList: any[]): void {
    const finalSearchedListResponse = tempSearchedList;
    for (let i = 0; i < orignalListOptions.length; i++) {
      const listOptionText = orignalListOptions[i].text;
      const lowerListOptionText = orignalListOptions[i].text.toLowerCase();
      const lowerSearchText = seachedText.toLowerCase();
      const regex = new RegExp('^' + seachedText, 'i');
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec -- required
      const match = listOptionText.match(regex);
      const contains = lowerListOptionText.indexOf(lowerSearchText) !== -1;
      if (match || contains) {
        finalSearchedListResponse.push(orignalListOptions[i]);
      }
    }
    this.sourceListDataSource = <DataSourceItem[]>finalSearchedListResponse;
  }

  searchClicked(): void {
    const seachedText = this.sourceSearch;
    const orignalListOptions = this.sourceDataSourceOrignal;
    if (seachedText !== undefined && seachedText !== null && seachedText !== '' && seachedText.length > 0) {
      if (this.searchButtonClicked.observers.length > 0) {
        this.searchButtonClicked.emit({ value: seachedText, source: orignalListOptions });
      } else {
        this.performSearch(seachedText, orignalListOptions);
      }

    } else {
      this.sourceListDataSource = [...this.sourceDataSourceOrignal];
      this.validateButtonStates();
    }
  }

  private validateButtonStates() {

    if (this.sourceListDataSource.length > 0) {
      this.addBtn  = false;
      this.addAllBtn  = false;
    } else {
      this.addBtn  = true;
      this.addAllBtn  = true;
    }
  }
}
