import { Action, createReducer, on } from '@ngrx/store';

import * as layoutActions from '../actions/layout.actions';
import { Layout } from '../models/layout';

export interface State {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  layout: Layout | null;
}

const initialState: State = {
  loaded: false,
  loading: false,
  ids: [],
  layout: null,
};

const layoutReducer = createReducer(
  initialState,
  on(layoutActions.load, (state) => ({ ...state, loading: true })),
  on(layoutActions.loadSuccess, (state, { layout, panels }) => ({
    ...state,
    loaded: true,
    loading: false,
    ids: panels.map((item) => item.id),
    layout,
  }))
);

export function reducer(state: State, action: Action) {
  return layoutReducer(state, action);
}

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getIds = (state: State) => state.ids;

export const getLayout = (state: State) => state.layout;
