import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WebFrameworkService } from './webframework.service';

@Injectable()
export class ConfigTranslateLoader implements TranslateLoader {
  constructor(private readonly webframeworkService: WebFrameworkService) {}

  getTranslation(language: string): Observable<unknown> {
    return this.webframeworkService.getAppConfig(`i18n/configurations/${language}`)
      // incase of requested localization file doesn't exists, translate service will simply throw an error and exits.
      // to avoid this and fallback to default language, returning empty object.
      .pipe(catchError(() => of({})));
  }
}

export const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useClass: ConfigTranslateLoader,
  },
};
