import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Locale, UserPreferences } from '@webframework/client-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as appActions from '../../actions/app.actions';
import * as fromMasterPage from '../../reducers/root.reducer';

@Component({
  templateUrl: './locale-switcher.component.html',
  styleUrls: ['./locale-switcher.component.scss'],
})
export class LocaleSwitcherComponent {
  public static group = 'MASTER_PAGE.USER_DRAWER.SETTINGS';
  public static title = 'MASTER_PAGE.USER_DRAWER.LANGUAGE';

  locales$ = this.store.pipe(
    select(fromMasterPage.getBrandingConfig),
    map((bc) => (bc ? bc.locales : []))
  );

  userPreferences$: Observable<UserPreferences> = this.store.pipe(
    select(fromMasterPage.getUserPreferences)
  );

  constructor(private readonly store: Store<any>) {}

  setLocale(locale: Locale) {
    this.store.dispatch(appActions.setUserPreferences({ userPreferences: { locale, __fallbackLocale: undefined } }));
  }
}
