import { Injectable, isDevMode } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import Swal from 'sweetalert2';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  baseURL: string = 'api/';

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  store(item: any, entity: string) {
    return this.http.post(this.baseURL + entity, item).toPromise();
  }

  update(item: any, entity: string, id: string) {
    return this.http.put(this.baseURL + entity + '/' + id, item).toPromise();
  }

  // getList(table: string, field: string, filter: string) {
  //   let hs = new HttpHeaders();
  //   hs.set("Content-Type", "application/json");

  //   let getParameters = new HttpParams()
  //     .set("table", table)
  //     .set("field", field)
  //     .set("filter", filter);

  //   return this.http
  //     .get(this.baseURL + "getList", {
  //       headers: hs,
  //       params: getParameters,
  //     })
  //     .toPromise();
  // }

  post(item: any, url: string) {
    return this.http.post(this.baseURL + url, item).toPromise();
  }

  get(url: string) {
    return this.http.get(this.baseURL + url).toPromise();
  }

  getAsObservable<T>(url: string, params?:HttpParams) {
    return this.http.get<T>(this.baseURL + url, {params: params});
  }

  getAll(entity: string) {
    return this.http.get(this.baseURL + entity).toPromise();
  }

  getById(id: string, entity: string) {
    return this.http.get(this.baseURL + entity + '/' + id).toPromise();
  }

  deleteById(id: string, entity: string) {
    return this.http.delete(this.baseURL + entity + '/' + id).toPromise();
  }

  // getVAPID() {
  //   return this.http
  //     .get<{ public_vapid: string }>(this.baseURL + "vapid")
  //     .toPromise<{ public_vapid: string }>();
  // }

  showHttpExecutionErrorToast() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error de ejecución',
      detail:
        'Ocurrió un error ejecutando la solicitud. Por favor inténtalo nuevamente',
      closable: true,
      life: 3000,
    });
  }

  showHttpExecutionSuccessToast() {
    this.messageService.add({
      severity: 'success',
      summary: 'Solicitud Ejecutada',
      detail: 'La solicitud fue ejecutada correctamente',
      closable: true,
      life: 3000,
    });
  }
}
