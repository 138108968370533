import { Action } from '@ngrx/store';

export namespace NotificationBarActions {
  export const SHOW_NOTIFICATION_SIDEBAR = '[NotificationBar] Show';

  export class ShowNotificationSidebar implements Action {
    readonly type = SHOW_NOTIFICATION_SIDEBAR;

    constructor() {}
  }

  export type Actions = ShowNotificationSidebar;
}
