import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger } from '@angular/animations';

export const pulseAnimation: AnimationTriggerMetadata = trigger('pulseAnimation', [
  transition(
    '* => true',
    animate(
      '1.2s 400ms ease-in-out',
      keyframes([
        style({ transform: 'scale(1.0)' }),
        style({ transform: 'scale(1.75)' }),
        style({ transform: 'scale(0.75)' }),
        style({ transform: 'scale(1.75)' }),
        style({ transform: 'scale(1.0)' })
      ])
    )
  )
]);
