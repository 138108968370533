<p-progressBar
*ngIf="coreService.isLoading"
  [style]="{
    height: '6px',
    width: '100vw',
    position: 'absolute',
    'z-index': 999,
    top: '80px'
  }"
  mode="indeterminate"
></p-progressBar>


<wfc-master-page>
  <!--
  <header>
    <div class="wfc-action-bar-right">
      <ul class="wfc-action-bar-links">
        <li class="wfc-action-bar-links-item">
          <a href="#">LABEL</a>
        </li>
        <li class="wfc-action-bar-links-item blue">
          <a href="#">LABEL</a>
        </li>
        <li class="wfc-action-bar-links-item green">
          <a href="#">LABEL</a>
        </li>
      </ul>

      <ul class="wfc-action-bar-buttons">
        <li class="wfc-action-bar-buttons-item">
          <button pButton type="button" label="Button"></button>
        </li>
      </ul>

      <ul class="wfc-action-bar-icons p-ml-4 p-mr-2">
        <li class="wfc-action-bar-icons-item">
          <wfc-icon-button size="16" fontSet="fa" icon="fa-th-large"></wfc-icon-button>
        </li>
        <li class="wfc-action-bar-icons-item active">
          <wfc-icon-button size="16" fontSet="fa" icon="fa-map-marker-alt"></wfc-icon-button>
        </li>
      </ul>
    </div>
     <wfc-breadcrumb [breadcrumbItems]="items$ | async"></wfc-breadcrumb> 
  </header>-->
  <router-outlet></router-outlet>
</wfc-master-page>

<lesmes-app-notifications-sidebar></lesmes-app-notifications-sidebar>

