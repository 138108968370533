/* eslint-disable */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_VERSION } from '../../webframework.service';
import { DataModel, ResultModel, Filter, OrderBy } from '../models/dataservice.model';
import { IODataUser } from '../models/user.model';

@Injectable()
export class ODataService {
  public DataModel?: DataModel;
  public oDataUrl?: string;
  private user?: IODataUser;

  constructor(private readonly http: HttpClient, @Inject(API_VERSION) public apiVersion?: string) {}

  public getDataFromOdata(queryData: DataModel) {
    const dataModel = new ResultModel();
    dataModel.application = queryData && queryData.application ? queryData.application : this.getApplicationName();
    dataModel.dataSource = queryData.dataSource;
    dataModel.project = queryData.project;
    dataModel.entity = queryData.entity;
    dataModel.properties = queryData.properties;
    dataModel.filter = this.formQueryfilter(queryData.filter);
    dataModel.orderBy = this.formOrderBy(queryData.orderBy);
    dataModel.top = queryData.top;
    dataModel.skip = queryData.skip;
    dataModel.count = queryData.inlineCount;
    dataModel.format = queryData.format;
    const serviceUrl = this.formServiceUrl(
      dataModel.entity,
      dataModel.properties,
      dataModel.filter,
      dataModel.orderBy,
      dataModel.top,
      dataModel.skip,
      dataModel.count
    );

    return this.http
      .get(
        `${this.apiVersion}/odataservice/getdata/${dataModel.application}/` +
          `${dataModel.dataSource}/${dataModel.project}/${serviceUrl}`
      )
      .pipe(catchError(this.handleError));
  }

  public getApplicationName() {
    const fullPath = window.location.href;
    const pathArray = fullPath.split('/');

    return pathArray[3];
  }

  public getData(queryData: any, callback: any) {
    this.getDataFromOdata(queryData).subscribe((data) => callback(data));
  }

  public getCurrentUser(): Observable<IODataUser> {
    return this.http.get<IODataUser>('/currentuser').pipe(catchError(this.handleError));
  }

  public getDataFromConfig(userToken: any, url: any) {
    const headers = new HttpHeaders();
    const authToken = 'Bearer ' + userToken;
    headers.append('Authorization', authToken);

    return this.http.get(url, { headers: headers }).pipe(catchError(this.handleError));
  }

  public getConfiguration(url: any, callback: any) {
    this.getCurrentUser().subscribe((userModel) => {
      this.user = userModel;
      this.getDataFromConfig(this.user.token, url).subscribe((data) => callback(data));
    });
  }

  // orderByObj is Array of objects
  // orderbyObj = [{column : "xxx", order : "desc"}]
  private formOrderBy(orderByObj: OrderBy[]) {
    let orderByStr = '';
    for (const obj of orderByObj) {
      orderByStr += obj.column + ' ' + obj.order + ',';
    }
    orderByStr = orderByStr.slice(0, -1);

    return orderByStr;
  }

  private formServiceUrl(
    entity: string,
    properties: any,
    filter: string,
    orderby: any,
    top: string,
    skip: string,
    count: boolean
  ) {
    let tempServiceUrl = entity + '?';
    if (properties.length > 0) {
      tempServiceUrl += '$select=' + properties.join();
    }
    if (filter !== '') {
      tempServiceUrl += '&$filter=' + filter;
    }
    if (orderby !== '') {
      tempServiceUrl += '&$orderby=' + orderby;
    }
    if (count) {
      tempServiceUrl += '&$inlinecount=allpages';
    }
    if (top !== '') {
      tempServiceUrl += '&$top=' + top;
    }
    if (skip !== '') {
      tempServiceUrl += '&$skip=' + skip;
    }
    tempServiceUrl += '&$format=json';

    return encodeURIComponent(tempServiceUrl);
  }

  // tslint:disable-next-line:cognitive-complexity
  private formQueryfilter(filter: Filter[]): string {
    const filterObj = filter;
    let filterStr = '';
    if (Object.keys(filterObj).length > 0) {
      for (const obj of filterObj) {
        if (obj.hasOwnProperty('type') && obj.type === 'datetimerange') {
          filterStr += `(${obj.filterName} ge datetime'${obj.startDate}'
           and ${obj.filterName} le datetime'${obj.endDate}')
           and
          (${obj.filterName} ge datetime'${obj.startDate}'
           and ${obj.filterName} le datetime'${obj.endDate}')
           and `;
        } else {
          for (const filterValObj of obj.filterValue) {
            filterStr += obj.filterName + ' ' + obj.operator + ' ';
            if (filterValObj == null) {
              filterStr += filterValObj + ' or ';
            } else {
              filterStr += `'${filterValObj}' or `;
            }
          }
          filterStr = filterStr.slice(0, -4);
          filterStr += ' and ';
        }
      }
      filterStr = filterStr.slice(0, -5);
    }

    return filterStr;
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;

    errMsg = error instanceof HttpErrorResponse ? error.message : error.message ? error.message : error.toString();

    return throwError(errMsg);
  }
}
