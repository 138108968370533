import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextMenuComponent } from './context-menu.component';
import { MenuModule } from 'primeng/menu';
import {TranslateModule} from '@ngx-translate/core';
import { IconModule } from '@webframework/client-shared';

@NgModule({
  imports: [
    CommonModule,
    MenuModule,
    TranslateModule,
    IconModule
  ],
  declarations : [ContextMenuComponent],
  exports : [ContextMenuComponent]
})
export class ContextMenuModule { }
