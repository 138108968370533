import * as fromRouter from '@ngrx/router-store';
import {
  ActionReducer,
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  MetaReducer,
} from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { MenuItem } from 'primeng/api';

import { environment } from '../../environments/environment';
import * as fromBreadcrumb from './breadcrumb';
import * as fromNotificationBar from './notificationbar';
import * as fromWellTree from './welltree';

export interface State {
  routerReducer: fromRouter.RouterReducerState;
  breadcrumbItems: fromBreadcrumb.State;
  notificationBar: fromNotificationBar.State;
  wellTree: fromWellTree.State;
}

export const reducers = {
  routerReducer: fromRouter.routerReducer,
  breadcrumb: fromBreadcrumb.reducer,
  notificationBar: fromNotificationBar.reducer,
  wellTree: fromWellTree.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, storeFreeze]
  : [];

export const getBreadcrumbFeatureState: MemoizedSelector<
  object,
  fromBreadcrumb.State
> = createFeatureSelector<fromBreadcrumb.State>('breadcrumb');

export const getNotificationFeatureState: MemoizedSelector<
  object,
  fromNotificationBar.State
> = createFeatureSelector<fromNotificationBar.State>('notification');

export const getBreadcrumbItem: MemoizedSelector<
  object,
  MenuItem[]
> = createSelector(getBreadcrumbFeatureState, (state) => state.items);

export const getSidebarVisible: MemoizedSelector<
  object,
  boolean
> = createSelector(getNotificationFeatureState, (state) => {
  console.log(state);
  return state.isVisible;
});
