import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AuthenticationService } from '@webframework/client-core';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  isLoading: boolean = false;
  userExistsInOpenWells: boolean;
  userAccessType: string;

  confirmationDialogProperties: any;

  _applicationUsers: any;
  appUsersTimer: any;

  currentUserContracts:any[];

  constructor(
    private restService: RestService,
    private authService: AuthenticationService
  ) {}

  async initUserInfo() {
    let user = await this.authService.getUser().toPromise();
    let username = user.prefUserName;

    if (
      this.userExistsInOpenWells !== false &&
      this.userExistsInOpenWells !== true
    ) {
      try {
        let userData: any = await this.restService.get('user_info/' + username);

        this.userExistsInOpenWells = userData.userExists;
        this.userAccessType = userData.userAccess;
      } catch (e) {
        this.userExistsInOpenWells = false;
        this.userAccessType = 'NONE';
      }
    }

    return {
      userExists: this.userExistsInOpenWells,
      userAccess: this.userAccessType,
    };
  }

  async getCurrentUserContracts() {

    if(this.currentUserContracts) return this.currentUserContracts;

    let user = await this.authService.getUser().toPromise();
    let userProfile =<any>( await this.restService.get('auth/users/' + user.userId));

    this.currentUserContracts = [...userProfile.userContracts];
    return this.currentUserContracts;
  }

  async getApplicationUsers() {
    if (this._applicationUsers) return this._applicationUsers;

    return new Promise((resolve, reject) => {
      clearTimeout(this.appUsersTimer);
      this.appUsersTimer = setTimeout(async () => {
        this._applicationUsers = await this.restService.get(
          'application_users'
        );
        resolve(this._applicationUsers);
      }, 100);
    });
  }
}
