import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { USER_DRAWER_WIDGET } from '@webframework/client-shared';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';

import { LocaleSwitcherComponent } from './locale-switcher/locale-switcher.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { MouseScrollDirectionComponent } from './mouse-scroll-direction/mouse-scroll-direction.component';

const WIDGETS = [ThemeSwitcherComponent, MouseScrollDirectionComponent, LocaleSwitcherComponent];
export const WIDGETS_PROVIDER = [{
  provide: USER_DRAWER_WIDGET,
  useValue: ThemeSwitcherComponent,
  multi: true
}, {
  provide: USER_DRAWER_WIDGET,
  useValue: MouseScrollDirectionComponent,
  multi: true
}, {
  provide: USER_DRAWER_WIDGET,
  useValue: LocaleSwitcherComponent,
  multi: true
}];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RadioButtonModule,
    SelectButtonModule,
    CheckboxModule,
    TranslateModule
  ],
  declarations: WIDGETS,
  exports: WIDGETS,
  entryComponents: WIDGETS
})
export class WidgetsModule { }
