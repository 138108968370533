import { MenuItem } from 'primeng/api';

import { NotificationBarActions } from '../actions/notificationbar';

export interface State {
  isVisible: boolean;
}

export const initialState: State = {
  isVisible: false,
};

export function reducer(
  state = initialState,
  action: NotificationBarActions.Actions
): State {

  switch (action.type) {
    case NotificationBarActions.SHOW_NOTIFICATION_SIDEBAR: {

      return {
        ...state,
        isVisible: !state.isVisible
      };
    }

    default: {
      return state;
    }
  }
}
