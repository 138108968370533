import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AppMenuItem, ParentMenuItem } from '@webframework/client-core';

import * as sidenavItem from '../actions/sidenav-item.actions';
import * as sidenav from '../actions/sidenav.actions';

export const LAYOUT_PANELS_ID = '__LAYOUT_PANELS__';

export interface State extends EntityState<AppMenuItem> {
  selectedItemId: string | null;
}

export const adapter: EntityAdapter<AppMenuItem> = createEntityAdapter<AppMenuItem>({
  selectId: (item: AppMenuItem) => item.id,
  sortComparer: (itemA, itemB) => +itemA.displayOrder - +itemB.displayOrder,
});

export const initialState: State = adapter.getInitialState({
  selectedItemId: null,
});

const sidenavItemReducer = createReducer(
  initialState,
  on(sidenav.loadSuccess, (state, { items }) => ({
    ...adapter.addMany(items, state),
    selectedItemId: state.selectedItemId,
  })),
  on(sidenavItem.select, (state, { payload }) => ({
    ...state,
    selectedItemId: payload,
  })),
  on(sidenav.addItem, (state, { payload, parentId }) => {
    if ((state.ids as string[]).indexOf(payload.id) >= 0) {
      return state;
    }

    const interimState = adapter.addOne(payload, state);

    if (!parentId || !state.entities[parentId]) {
      return interimState;
    }

    const parent = state.entities[parentId] as ParentMenuItem<string>;
    const children = parent.children.concat(payload.id);
    const notificationcount = parent.id === LAYOUT_PANELS_ID ? children.length : parent.notificationcount;

    return adapter.updateOne(
      {
        id: parentId,
        changes: {
          children,
          notificationcount,
          flashIcon:
            // tslint:disable-next-line:no-non-null-assertion
            notificationcount! > 0 && state.entities[parent.id]!.notificationcount !== notificationcount,
        } as ParentMenuItem<string>,
      },
      interimState
    );
  }),
  on(sidenav.removeItem, (state, { payload }) => {
    const parent = Object.keys(state.entities)
      .map((id) => state.entities[id] as ParentMenuItem<string>)
      .filter((item) => item.type === 'parent')
      .find((item) => item.children.indexOf(payload) >= 0);

    if (!parent) {
      return state;
    }

    const children = (parent as ParentMenuItem<any>).children.filter((id) => id !== payload);
    const notificationcount = parent.id === LAYOUT_PANELS_ID ? children.length : parent.notificationcount;

    return adapter.removeOne(
      payload,
      parent
        ? adapter.updateOne(
            {
              id: parent.id,
              changes: {
                children,
                notificationcount,
                flashIcon:
                  // tslint:disable-next-line:no-non-null-assertion
                  notificationcount! > 0 && state.entities[parent.id]!.notificationcount !== notificationcount,
              } as ParentMenuItem<string>,
            },
            state
          )
        : state
    );
  }),
  on(sidenavItem.update, (state, { id, changes }) =>
    adapter.updateOne(
      {
        id: id,
        changes: {
          ...changes,
          flashIcon: !!changes.notificationcount,
        },
      },
      state
    )
  )
);

export function reducer(state: State, action: Action) {
  return sidenavItemReducer(state, action);
}

export const getSelectedId = (state: State) => state.selectedItemId;
