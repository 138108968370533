import { Injectable } from '@angular/core';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class TimeNotificationServiceService {
  constructor(private restService: RestService) {}

  async orderLookup(queryData: any): Promise<any[]> {
    try {
      return <any[]>await this.restService.post({ queryData }, 'order_lookup');
    } catch (error) {
      this.restService.showHttpExecutionErrorToast();
    }
  }

  async getTimeCosts(order, dateStart, dateEnd) {
    try {
      return <any>await this.restService.post(
        {
          event_id: order.event_id,
          well_id: order.well_id,
          dateStart,
          dateEnd,
        },
        'get_time_costs'
      );
    } catch (error) {
      this.restService.showHttpExecutionErrorToast();
    }
  }

  async submitTimeNotification(costItems, approvalInitDate,approvalEndDate,order){
    try {
       <any>await this.restService.post(
        {
          costItems,
          approvalInitDate,
          approvalEndDate,
          order
        },
        'time_notification'
      );
      this.restService.showHttpExecutionSuccessToast();
      return "OK";
    } catch (error) {
      this.restService.showHttpExecutionErrorToast();
      throw error;
    }
  }
}
