import { MenuItem } from 'primeng/api';

import { WellTreeActions } from '../actions/welltree';

export interface State {
  selectedWellId: string;
  selectedEventId:string;
}

export const initialState: State = {
  selectedWellId: null,
  selectedEventId:null
};

export function reducer(
  state = initialState,
  action: WellTreeActions.Actions
): State {

  switch (action.type) {
    case WellTreeActions.WELL_SELECTED: {
      return {
        ...state,
        selectedWellId:action.payload.id
      };
    }

    case WellTreeActions.EVENT_SELECTED: {

      return {
        ...state,
        selectedEventId:action.payload.id
      };
    }

    default: {
      return state;
    }
  }
}
