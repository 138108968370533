import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessagesModule } from 'primeng/messages';

import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './services/notification.service';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    TranslateModule
  ],
  declarations: [
    NotificationComponent
  ],
  entryComponents: [
    NotificationComponent
  ],
  providers: [
    NotificationService
  ],
  exports: [
    NotificationComponent
  ]
})
export class NotificationModule {}
