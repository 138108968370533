import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wfc-popover-panel',
  templateUrl: './popover-panel.component.html',
  styleUrls: ['./popover-panel.component.scss']
})
export class PopoverPanelComponent {
  @Input()
  title?: string;
  @Input()
  componentModule?: string;
  @Input()
  options?: any;

  @Input()
  canClose?: boolean;

  @Output()
  close = new EventEmitter();

  private _isOpen = false;

  closePopover() {
    this.close.emit();
  }

  closeIfOpen() {
    // The clickoutside directive triggers as soon as the popover is open
    // We have to skip the first event to make sure popover does not close
    // right after it opens
    if (this._isOpen) {
      this.closePopover();
    } else {
      this._isOpen = true;
    }
  }
}
