import { animate, AnimationTriggerMetadata, keyframes, style, transition, trigger, state } from '@angular/animations';

export const minimized: AnimationTriggerMetadata = trigger('minimized', [
  state('true', style({ display: 'none' })),
  transition('false => true', [
    animate(
      '.2s ease-in',
      keyframes([
        style({
          transform: 'translateX(0%) scale(1)'
        }),
        style({
          transform: 'translateX(-100%) scale(0)'
        })
      ])
    )
  ]),
  transition('true => false', [
    animate(
      '.2s ease-out',
      keyframes([
        style({
          transform: 'translateX(-100%) scale(0)',
          display: '' // reset display: none at the beginning of animation
        }),
        style({
          transform: 'translateX(0) scale(1)'
        })
      ])
    )
  ])
]);
