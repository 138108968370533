import { Action, createReducer, on } from '@ngrx/store';
import { BrandConfig, UserPreferences, User } from '@webframework/client-core';

import * as fromApp from '../actions/app.actions';

export interface State {
  user: User | null;
  brandConfig?: BrandConfig;
  userPreferences: UserPreferences;
}

export const initialState: State = {
  user: null,
  userPreferences: { locale: { value: 'en-US', label: 'English' }, theme: 'light', reverseMouseScrollDirection: false }
};

const appReducer = createReducer(
  initialState,
  on(fromApp.loadUserSuccess, (state, { user }) => ({ ...state, user })),
  on(fromApp.loadBrandingConfigSuccess, (state, { brandConfig }) => ({ ...state, brandConfig })),
  on(fromApp.setUserPreferencesSuccess, (state, { userPreferences }) => ({ ...state, userPreferences })),

  on(fromApp.updateBadge, (state, action) => {
    if (!state.brandConfig) {
      return state;
    }

    return {
      ...state,
      brandConfig: {
        ...state.brandConfig,
        headerItems: state.brandConfig.headerItems.map((hi) => ({
          ...hi,
          badge: hi.id === action.id ? action.payload : hi.badge,
        })),
      },
    };
  })
);

export function reducer(state: State, action: Action) {
  return appReducer(state, action);
}
