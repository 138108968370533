import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '../../models/notification';
import { NotificationService } from '../../services/notification.service';
import { WFC_VERSION } from '../../version';

@Component({
  selector: 'wfc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  public static version = WFC_VERSION.full;
  @Input() parent: string | null = null;
  public messages: Observable<Notification[]> = this.notificationService.getMessages();
  constructor(
    public notificationService: NotificationService
  ) {
  }

  clear(id: number): void {
    this.notificationService.clearMessageById(id);
  }

}
