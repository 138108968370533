import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { AppTitleComponent } from './app-title/app-title.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { DropdownMenuComponent } from './dropdown/dropdown.component';
import { DynamicViewComponent } from './dynamic-view/dynamic-view.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconModule } from './icon/icon.module';
import { PopoverPanelComponent } from './popover-panel/popover-panel.component';

@NgModule({
  imports: [
    A11yModule,
    ButtonModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    IconModule,
    InputTextModule,
    MatBadgeModule,
    MatIconModule,
    MatSlideToggleModule,
    MessageModule,
    OverlayModule,
    OverlayPanelModule,
    RouterModule,
    TooltipModule,
    TranslateModule
  ],
  declarations: [
    AppTitleComponent,
    ClickOutsideDirective,
    DropdownMenuComponent,
    DynamicViewComponent,
    ErrorComponent,
    HeaderComponent,
    IconButtonComponent,
    PopoverPanelComponent
  ],
  exports: [
    AppTitleComponent,
    ClickOutsideDirective,
    DropdownMenuComponent,
    DynamicViewComponent,
    HeaderComponent,
    IconButtonComponent,
    PopoverPanelComponent
  ]
})
export class SharedModule { }
