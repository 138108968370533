import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

export class CustomPreloadingStrategy implements PreloadingStrategy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- as per angular router preload method
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const loadRoute = (delay: number) => (delay ? timer(150).pipe(flatMap(() => load())) : load());

    return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
  }
}
