import { Injectable, ErrorHandler } from '@angular/core';
import { Newrelic } from '../models/newrelic';


declare let newrelic: Newrelic;

@Injectable({
  providedIn: 'root'
})
export class AppGlobalErrorhandler implements ErrorHandler {
  handleError(error: Error): void {
    console.error(error);
    if ('newrelic' in window) {
      newrelic.noticeError(error);
    }
  }
}
