export class DataSourceItem {
  id = '';
  value = '';
  text = '';
  cssClass = '';
  cssStyle = '';
  isNew = false;
}
export class HierarchicalDataSourceItem extends DataSourceItem {
  children: HierarchicalDataSourceItem[] = [];
}
