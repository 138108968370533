import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { map, tap } from 'rxjs/operators';

import * as routerActions from '../actions/router.actions';
import { BrowserHistoryService } from '../services/browser-history.service';

@Injectable()
export class RouterEffects {
  @Effect({ dispatch: false })
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.go),
        map(({ payload }) => payload),
        map(({
          path,
          query: queryParams,
          extras
        }) => this.router.navigate(path, { queryParams, ...extras }))
      ),
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.back),
        map(() => this.historyService.back()),
        tap(url => {
          if (url && !this.router.isActive(url, true)) {
            void this.router.navigateByUrl(url);
          }
        })
      ),
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.forward),
        map(() => this.historyService.forward()),
        tap(navUrl => {
          if (navUrl && !this.router.isActive(navUrl, true)) {
            void this.router.navigateByUrl(navUrl);
          }
        })
      ),
    { dispatch: false }
  );

  navigated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap((action: RouterNavigatedAction) => {
          const urlAfterRedirects = action.payload.event.urlAfterRedirects ?
            action.payload.event.urlAfterRedirects :
            action.payload.event.url;

          this.historyService.add(urlAfterRedirects);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly historyService: BrowserHistoryService
  ) { }
}
