import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { GridModule } from '@webframework/grid';
import { SecureStoreServiceManager } from '@webframework/landmark-sdk';
import { NotificationModule } from '@webframework/notification';
import { ProgressSpinnerModule } from '@webframework/progress-spinner';

import { CredentialsComponent } from './components/credentials/credentials.component';
import { SetCredentialsComponent } from './components/set-credentials/set-credentials.component';
import { SetCredentialsWidgetComponent } from './components/widgets/set-credentials.widget.component';

import { CredentialsService } from './services/credentials.service';
import { TranslateModule } from '@ngx-translate/core';
import { USER_DRAWER_WIDGET } from '@webframework/client-shared';

export const CRED_PROVIDERS = [

  CredentialsService,
  {
    provide: USER_DRAWER_WIDGET,
    useValue: SetCredentialsWidgetComponent,
    multi: true
  }
];
@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    TooltipModule,
    ProgressSpinnerModule,
    FormsModule,
    NotificationModule,
    ReactiveFormsModule,
    GridModule,
    TranslateModule
  ],
  declarations: [CredentialsComponent, SetCredentialsComponent, SetCredentialsWidgetComponent],
  entryComponents: [SetCredentialsComponent, SetCredentialsWidgetComponent],
  exports: [CredentialsComponent, SetCredentialsComponent, SetCredentialsWidgetComponent],
  providers: [SecureStoreServiceManager, CredentialsService]
})
export class CredentialsModule {
  public static entry = SetCredentialsComponent;

  static forRoot(): ModuleWithProviders<CredentialsModule> {
    return {
      ngModule: CredentialsModule,
      providers: [...CRED_PROVIDERS]
    };
  }
}
