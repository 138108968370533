import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconRegistry {
  constructor(private readonly registry: MatIconRegistry, private readonly sanitizer: DomSanitizer) { }

  public addSvgIconSet(url: string) {
    this.registry.addSvgIconSet(
      this.sanitizer.bypassSecurityTrustResourceUrl(url)
    );
  }
  public addSvgIcon(iconName: string, url: string) {
    this.registry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(url)
    );
  }
}
