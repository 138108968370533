/**
 * User Model
 */
export interface IODataUser {
  /**
   * User Name
   */
  userName: string;

  /**
   * User ID
   */
  userId: string;

  /**
   * User's token
   */
  token: string;

  /**
   * User role
   */
  roles: string;

  /**
   * User preffered name
   */
  prefUserName: string;
}
