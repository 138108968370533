<p-dialog
  #timeNotificationDialog
  header="Notificaciones de Tiempos"
  appendTo="body"
  [visible]="visible$ | async"
  (visibleChange)="hideBar()"
  [style]="{ width: '70vw', maxHeight: '80vh' }"
  [draggable]="true"
  [modal]="true"
>
  <p-card class="p-m-3" header="Creación de Notificación de tiempos">
    <p>
      Introduce la información de la notificación de tiempos que deseas crear,
      esto generará automáticamente un documento de notificación de tiempos en
      SAP.
    </p>
  </p-card>

  <p-card class="p-m-3" header="Información de Servicio">
    <div class="p-grid">
      <div class="p-col-12">
        <ng-container>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label>No. Orden</label>

              <p-autoComplete
                [(ngModel)]="order"
                [suggestions]="ordersResults"
                forceSelection="true"
                minLength="3"
                field="order_number"
                delay="300"
                appendTo="body"
                (completeMethod)="orderLookup($event)"
                (onSelect)="resetForm()"
              >
                <ng-template let-item pTemplate="item">
                  <div>
                    <h5 class="p-m-0">{{ item.order_number }}</h5>
                    <p class="p-m-0">{{ item.well_common_name }}</p>
                    <p class="p-m-0">
                      <small>{{ item.event_name }}</small>
                    </p>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>

            <div class="p-field p-col-4">
              <label>Pozo</label>
              <input
                type="text"
                pInputText
                [value]="order?.well_common_name ? order.well_common_name : ''"
                [disabled]="true"
              />
            </div>

            <div class="p-field p-col-4">
              <label>Evento</label>
              <input
                type="text"
                pInputText
                [value]="order?.event_name ? order.event_name : ''"
                [disabled]="true"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </p-card>

  <p-card class="p-m-3" header="Información de Notificación" *ngIf="order">
    <div class="p-grid">
      <div class="p-col-12">
        <ng-container>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label>Fecha Inic. Evento</label>
              <input
                type="text"
                pInputText
                [value]="
                  order?.date_ops_start
                    ? (order.date_ops_start | date: 'dd/MM/yyyy')
                    : ''
                "
                [disabled]="true"
              />
            </div>

            <div class="p-field p-col-3">
              <label>Fecha Fin. Evento</label>
              <input
                type="text"
                pInputText
                [value]="
                  order?.date_ops_end
                    ? (order.date_ops_end | date: 'dd/MM/yyyy')
                    : ''
                "
                [disabled]="true"
              />
            </div>

            <div class="p-field p-col-3">
              <label>Fecha Inic. Notificación</label>

              <p-calendar
                id="tbStartDate"
                [(ngModel)]="approval_init_date"
                [locale]="es"
                dateFormat="dd/mm/yy"
                yearNavigator="true"
                monthNavigator="true"
                baseZIndex="3000"
                appendTo="body"
                yearRange="2010:2030"
                readonlyInput="true"
                (onBlur)="costList = null"
              ></p-calendar>
            </div>

            <div class="p-field p-col-3">
              <label>Fecha Fin. Notificación</label>

              <p-calendar
                id="tbStartDate"
                [(ngModel)]="approval_end_date"
                [locale]="es"
                dateFormat="dd/mm/yy"
                yearNavigator="true"
                monthNavigator="true"
                baseZIndex="3000"
                appendTo="body"
                yearRange="2010:2030"
                readonlyInput="true"
                (onBlur)="costList = null"
              ></p-calendar>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </p-card>

  <ng-container *ngIf="order && errors.length > 0">
    <p-card
      styleClass="bg-danger p-py-1 p-px-3 p-m-3"
      *ngFor="let error of errors"
    >
      <p class="text-white font-weight-bold p-m-0">{{ error }}</p>
    </p-card>
  </ng-container>

  <p-card
    *ngIf="
      order && approval_init_date && approval_end_date && errors.length == 0
    "
  >


    <ng-container *ngIf="!costList">
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 300px"
      >
        <img src="/assets/images/empty.svg" style="height: 80%" />

        <div class="d-flex justify-content-center align-items-center">
          <button
            pButton
            (click)="getCosts()"
            pRipple
            pTooltip="Actualizar"
            type="button"
            icon="pi pi-refresh"
            class="p-button-rounded p-button-sm mr-2"
          ></button>

          <h1 class="mt-3">No hay costos cargados</h1>
        </div>

        <p style="text-align: center">
          Haz click en el botón para actualizar y recargar los costos.
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="costList && costList.length == 0">
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 300px"
      >
        <img src="/assets/images/building.svg" style="height: 80%" />

        <div class="d-flex justify-content-center align-items-center">
          <button
            pButton
            (click)="getCosts()"
            pRipple
            pTooltip="Actualizar"
            type="button"
            icon="pi pi-refresh"
            class="p-button-rounded p-button-sm mr-2"
          ></button>

          <h1 class="mt-3">No se encontraron resultados</h1>
        </div>

        <p style="text-align: center">
          No fue posible encontrar ningun resultado en las fechas seleccionadas. Modifícalas e intenta nuevamente
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="costList?.length > 0">
      <p-table
        [columns]="columns"
        [value]="costList"
        [scrollable]="true"
        [rows]="10"
        [paginator]="true"
        [style]="{ width: '100%', tableLayout: 'fixed' }"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col
              *ngFor="let col of columns"
              [style.width]="col.width ? col.width : 'auto'"
            />
          </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <ng-container [ngSwitch]="col.type" *ngFor="let col of columns">
              <td class="text-center" *ngSwitchCase="'checkbox'">
                <p-checkbox
                  [(ngModel)]="costSelection"
                  [value]="rowData"
                ></p-checkbox>
              </td>


              <td class="text-center" *ngSwitchCase="'final_checkbox'"> 
                
                <p-checkbox
                [ngModel]="isNotificationFinal(rowData.personnel_cost_id)"
                binary="true"
                (onChange)="toggleCostAsFinal($event, rowData)"
                ></p-checkbox>
              </td>

              <td *ngSwitchCase="'date'">
                {{ rowData[col.field] | date: 'dd/MM/yyyy HH:mm' }}
              </td>

              <td *ngSwitchCase="'number'">
                {{ rowData[col.field] | number: '1.3-3' }}
              </td>

              <td *ngSwitchDefault>
                {{ rowData[col.field] }}
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>


  </p-card>

  <p-card class="p-m-3" header="Revisión Final" *ngIf="costSelection?.length>0">
    Se realizará la creación de {{costSelection.length}} notificacione(s) de tiempos


    <p-card
    styleClass="p-py-1 p-px-3 p-m-3"
    *ngFor="let costItem of costSelection"
  >

  <div class="d-flex justify-content-between align-items-center">

    <div>
      <p class="font-weight-bold p-m-0"> {{costItem.operation_description}}</p>
      <p *ngIf="costItem.is_final" class="font-weight-bold text-danger p-m-0"><small>Notificación Final</small></p>
    </div>

    <div>
      <i class="pi pi-spin pi-spinner" *ngIf="costItem.is_loading"></i>
      <i class="pi pi-check" *ngIf="costItem.status=='SUCCESS'"></i>
      <i class="pi pi-times" *ngIf="costItem.status=='ERROR'"></i>
    </div>
  </div>
  </p-card>

  <button
        pButton
        [disabled]="coreService.isLoading"
        pRipple
        type="button"
        label="Enviar Notificacion(es)"
        icon="pi pi-rocket"
        class="p-button-sm mr-2"
        (click)="submitNotification()"
      ></button>


  </p-card>


</p-dialog>
