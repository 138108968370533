import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- factory function
export function getSessionStorageFactory(): Storage {
  return sessionStorage;
}
export const BROWSER_SESSION_STORAGE = new InjectionToken<Storage>('Browser Session Storage', {
  factory: getSessionStorageFactory
});

export const MAX_SIZE_SESSION_STORAGE = new InjectionToken<number>('Maximum number of records in session storage');

export interface HistoryStorage {
  currentIndex: number;
  entries: string[];
}

export const initialValue: HistoryStorage = {
  currentIndex: 0,
  entries: []
};

/** @dynamic */
@Injectable()
export class BrowserHistoryService {

  public readonly key = 'BROWSER_HISTORY';

  private historyStorage: HistoryStorage;

  private readonly _storageChanges: BehaviorSubject<HistoryStorage>;

  get storageChanges(): Observable<HistoryStorage> {
    return this._storageChanges.asObservable();
  }

  constructor(
    @Inject(BROWSER_SESSION_STORAGE) private readonly sessionStorage: Storage,
    @Inject(MAX_SIZE_SESSION_STORAGE) private readonly maxSizeSessionStorage: number) {
    this.historyStorage = this.getStorageValue();
    this._storageChanges = new BehaviorSubject(this.historyStorage);
  }

  add(url: string): void {
    if (url === this.historyStorage.entries[this.historyStorage.currentIndex]) {
      return;
    }

    const history = this.historyStorage.entries.slice(-this.maxSizeSessionStorage, this.historyStorage.currentIndex +
      1);
    const currentIndex = history.push(url) - 1;

    this.historyStorage = {
      entries: history,
      currentIndex: currentIndex
    };

    this.updateStorage(this.historyStorage);
  }

  back(): string {
    this.historyStorage.currentIndex = this.historyStorage.currentIndex > 0 ? this.historyStorage.currentIndex - 1 : 0;
    this.updateStorage(this.historyStorage);

    return this.historyStorage.entries[this.historyStorage.currentIndex];
  }

  forward(): string {
    this.historyStorage.currentIndex = this.historyStorage.currentIndex < this.historyStorage.entries.length - 1 ?
      this.historyStorage.currentIndex + 1 :
      this.historyStorage.currentIndex;
    this.updateStorage(this.historyStorage);

    return this.historyStorage.entries[this.historyStorage.currentIndex];
  }

  getStorageValue(): HistoryStorage {
    try {
      return typeof this.sessionStorage.getItem(this.key) === 'string' ?
        <HistoryStorage>JSON.parse(<string>this.sessionStorage.getItem(this.key)) :
        initialValue;
    } catch(e) {
      console.error(e);

      return initialValue;
    }
  }

  private updateStorage(value: HistoryStorage): void {
    try {
      this.sessionStorage.setItem(this.key, JSON.stringify(value));
    } catch(e) {
      console.error(e);
    }
    this._storageChanges.next(value);
  }
}
