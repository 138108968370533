import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  constructor(private readonly _http: HttpClient) {

  }

  public getAppConfig<TConfig>(configName: string): Observable<TConfig> {
    return this._http.get<TConfig>(`/api/user-config/app/${configName}`);
  }

  public setAppConfig<TConfig>(configName: string, config: TConfig): Observable<TConfig> {
    return this._http.post<TConfig>(`/api/user-config/app/${configName}`, config);
  }

  public getSharedConfig<TConfig>(configName: string): Observable<TConfig> {
    return this._http.get<TConfig>(`/api/user-config/shared/${configName}`);
  }

  public setSharedConfig<TConfig>(configName: string, config: TConfig): Observable<TConfig> {
    return this._http.post<TConfig>(`/api/user-config/shared/${configName}`, config);
  }
}
