export class ResizeObserverEntry {
  public contentRect: { width: number; height: number };

  constructor(target: any) {
    this.contentRect = {
      width: getWidth(target),
      height: getHeight(target)
    };
  }
}

export class ResizeObservation {
  public _lastBroadcastWidth: number;
  public _lastBroadcastHeight: number;
  private _firstCheck = true;

  constructor(private readonly _target: Element) {
    this._lastBroadcastWidth = getWidth(this._target);
    this._lastBroadcastHeight = getHeight(this._target);
  }

  target() {
    return this._target;
  }

  lastBroadcastWidth() {
    return this._lastBroadcastWidth;
  }

  lastBroadcastHeight() {
    return this._lastBroadcastHeight;
  }

  isActive() {
    if (this._firstCheck) {
      this._firstCheck = false;

      return true;
    }

    if (
      getWidth(this._target) !== this.lastBroadcastWidth() ||
      getHeight(this._target) !== this.lastBroadcastHeight()
    ) {
      return true;
    }

    return false;
  }
}

export class ResizeObserver {
  public static resizeObservers: ResizeObserver[] = [];
  private static _frameHandlerInitialized = false;

  public activeTargets: ResizeObservation[] = [];
  private _observationTargets: ResizeObservation[] = [];

  constructor(public callback: (arg0: ResizeObserverEntry[]) => void) {
    if (typeof callback !== 'function') {
      throw new TypeError();
    }

    if (!ResizeObserver._frameHandlerInitialized) {
      // This function must be called only once
      // and only if we are using this ResizeObserver
      setFrameWait(frameHandler);
      ResizeObserver._frameHandlerInitialized = true;
    }

    ResizeObserver.resizeObservers.push(this);
  }

  observe(target: Element) {
    if (!(target instanceof Element)) {
      throw new TypeError();
    }

    const resizeObservationIndex = this.findTargetIndex(this._observationTargets, target);
    if (resizeObservationIndex >= 0) {
      return;
    }

    const resizeObservation = new ResizeObservation(target);
    this._observationTargets.push(resizeObservation);
  }

  unobserve(target: Element) {
    if (!(target instanceof Element)) {
      throw new TypeError();
    }
    const resizeObservationIndex = this.findTargetIndex(this._observationTargets, target);
    if (resizeObservationIndex === -1) {
      return;
    }

    this._observationTargets.splice(resizeObservationIndex, 1);
  }

  disconnect() {
    this._observationTargets = [];
    this.activeTargets = [];
  }

  public populateActiveTargets() {
    this.activeTargets = [];
    for (const resizeObservation of this._observationTargets) {
      if (resizeObservation.isActive()) {
        this.activeTargets.push(resizeObservation);
      }
    }
  }

  private findTargetIndex(collection: ResizeObservation[], target: Element) {
    for (let index = 0; index < collection.length; index += 1) {
      if (collection[index].target() === target) {
        return index;
      }
    }

    return -1;
  }
}

function getWidth(target: Element) {
  const { paddingLeft, paddingRight } = getComputedStyle(target);

  return target.getBoundingClientRect().width - parseFloat(paddingLeft || '') - parseFloat(paddingRight || '');
}

function getHeight(target: Element) {
  const { paddingTop, paddingBottom } = getComputedStyle(target);

  return target.getBoundingClientRect().height - parseFloat(paddingTop || '') - parseFloat(paddingBottom || '');
}

function gatherActiveObservers() {
  for (let index = 0; index < ResizeObserver.resizeObservers.length; index += 1) {
    ResizeObserver.resizeObservers[index].populateActiveTargets();
  }
}

function broadcastActiveObservations() {
  for (let roIndex = 0; roIndex < ResizeObserver.resizeObservers.length; roIndex++) {
    const resizeObserver = ResizeObserver.resizeObservers[roIndex];
    if (resizeObserver.activeTargets.length === 0) {
      continue;
    }

    const entries = [];

    for (let atIndex = 0; atIndex < resizeObserver.activeTargets.length; atIndex += 1) {
      const resizeObservation = resizeObserver.activeTargets[atIndex];
      const entry = new ResizeObserverEntry(resizeObservation.target());
      entries.push(entry);
      resizeObservation._lastBroadcastWidth = getWidth(resizeObservation.target());
      resizeObservation._lastBroadcastHeight = getHeight(resizeObservation.target());
    }

    resizeObserver.callback(entries);
    resizeObserver.activeTargets = [];
  }
}

function frameHandler() {
  gatherActiveObservers();
  broadcastActiveObservations();

  setFrameWait(frameHandler);
}

function setFrameWait(callback: () => void) {
  if (typeof window.requestAnimationFrame === 'undefined') {
    window.setTimeout(callback, 1000 / 60);
  } else {
    window.requestAnimationFrame(callback);
  }
}
