import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationBarActions } from 'apps/dashboard/src/app/actions/notificationbar';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';

import { pluck } from 'rxjs/operators';

import * as fromIndex from '../../../../../../apps/dashboard/src/app/reducers';
import { CoreService } from '../../Services/core.service';
import { TimeNotificationServiceService } from '../../Services/time-notification-service.service';

@Component({
  selector: 'lesmes-app-notifications-sidebar',
  templateUrl: './app-notifications-sidebar.component.html',
  styleUrls: ['./app-notifications-sidebar.component.scss'],
})
export class AppNotificationsSidebarComponent implements OnInit {
  visible$: Observable<boolean>;
  order: any;

  es = {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic',
    ],
    today: 'Hoy',
    clear: 'Borrar',
  };

  ordersResults: any[] = [];
  costList: any[];

  approval_init_date: Date;
  approval_end_date: Date;

  columns: any[] = [
    { width: '5em', header: '', field: '', type: 'checkbox' },
    {
      width: '10em',
      header: 'Notif. Final',
      field: '',
      type: 'final_checkbox',
    },
    { width: '15ch', header: 'Operacion', field: 'operation', type: 'string' },
    {
      width: '30ch',
      header: 'Desc. Operación',
      field: 'operation_description',
      type: 'string',
    },
    { width: '20ch', header: 'Orden', field: 'order_number', type: 'string' },
    {
      width: '20ch',
      header: 'Puesto de Trabajo',
      field: 'work_center',
      type: 'string',
    },
    {
      width: '30ch',
      header: 'Desc. Puesto de Trabajo',
      field: 'work_center_description',
      type: 'string',
    },
    {
      width: '18ch',
      header: 'Consumido',
      field: 'total_consummed',
      type: 'number',
    },
    { width: '15ch', header: 'Unidades', field: 'work_uom', type: 'string' },
  ];

  costSelection: any = [];

  get errors() {
    let errors = [];
    const order = this.order;

    if (!order.well_id || !order.event_id) errors.push('Orden Inválida');
    if (order.date_ops_end && order.date_ops_end < this.approval_init_date)
      errors.push('Fecha inicial de notificación fuera de las fechas del evento');
    if (order.date_ops_start && order.date_ops_start > this.approval_end_date)
      errors.push('Fecha fin de notificación fuera de las fechas del evento');
    if (this.approval_init_date > this.approval_end_date)
      errors.push('Fecha fin de notificación fuera de las fechas del evento');
    if (!this.approval_init_date) errors.push('Fecha Inicial no Definida');
    if (!this.approval_end_date) errors.push('Fecha Final no Definida');

    if (errors.length > 0) this.costList = null;

    return errors;
  }

  constructor(
    private readonly store: Store<fromIndex.State>,
    private timeNotificationService: TimeNotificationServiceService,
    public coreService: CoreService
  ) {
    this.visible$ = store.select('notificationBar').pipe(pluck('isVisible'));
  }

  onShow() {
    this.order = null;
    this.resetForm();

  }

  async orderLookup(event) {
    this.ordersResults = await this.timeNotificationService.orderLookup(
      event.query
    );
  }

  hideBar() {
    this.store.dispatch({
      type: NotificationBarActions.SHOW_NOTIFICATION_SIDEBAR,
    });
  }

  async getCosts() {
    this.costList = await this.timeNotificationService.getTimeCosts(
      this.order,
      this.approval_init_date,
      this.approval_end_date
    );

    this.costList = this.costList.map((item) => {
      return {
        total_consummed: item.total_consummed,
        date_consumption: item.date_consumption,
        is_final: 0,
        key: item.key,
        ...item.afe_line_info,
      };
    });
  }

  showCostDetails(cost, event) {}

  isNotificationFinal(personnelCostId) {
    if (!this.costSelection) return false;
    let cost = this.costSelection.find(
      (c) => c.personnel_cost_id == personnelCostId
    );
    return cost ? cost.is_final == '1' : false;
  }

  toggleCostAsFinal(checked, cost) {
    if (!this.costSelection) return;

    let costFind = this.costSelection.find(
      (costItem) => cost.personnel_cost_id == costItem.personnel_cost_id
    );

    costFind.is_final = checked.checked;
  }

  async submitNotification() {
    const costQueue = [];

    for (let cost of this.costSelection) {
      if (cost.status != 'SUCCESS') {
        cost.is_loading = true;
        cost.status = '';
        cost.status_desc="";
        costQueue.push(cost);
      }
    }

    for (let cost of costQueue) {
      await this.submitCost(cost);
    }
  }

  async submitCost(cost) {
    try {
      await this.timeNotificationService.submitTimeNotification(
        cost,
        this.approval_init_date,
        this.approval_end_date,
        this.order
      );
      cost.status = 'SUCCESS';
    } catch (error) {
      cost.status = 'ERROR';
      cost.status_desc = error.status;
    } finally {
      cost.is_loading = false;
    }
  }

  resetForm()
  {
    this.approval_init_date = null;
    this.approval_end_date = null;
    this.costList  = null;
  }

  ngOnInit(): void {}
}
