import { createAction, props } from '@ngrx/store';
import { AppMenuItem } from '@webframework/client-core';

export const addItem = createAction('[Sidenav] Add AppMenuItem', props<{ payload: AppMenuItem; parentId?: string }>());
export const removeItem = createAction('[Sidenav] Remove AppMenuItem', props<{ payload: string }>());
export const load = createAction('[Sidenav] Load');
export const loadSuccess = createAction(
  '[Sidenav] Load Success',
  props<{ items: AppMenuItem[]; rootItems: string[] }>()
);
export const loadFail = createAction('[Sidenav] Load Fail', props<{ error: any }>());
export const toggle = createAction('[Sidenav] Toggle');
export const popover = createAction('[Sidenav] Popover', props<{ payload: string | null }>());
export const replaceItems = createAction('[Sidenav] Replace AppMenuItems', props<{ payload: AppMenuItem[] }>());
