/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { landmarkSDKResourceClass, BaseComponent, SecureStoreServiceManager } from '@webframework/landmark-sdk';
import { NotificationService } from '@webframework/notification';
import { API_VERSION, User } from '@webframework/client-core';

export class Credential {
  username = '';
  password = '';
}
const WFC_CREDENTIALS_DIALOG= 'wfc-credentials-dialog';
@Component({
  selector: WFC_CREDENTIALS_DIALOG,
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss'],
})
export class CredentialsComponent extends BaseComponent {

  popupDisplay = false;
  headerText = 'SET_CREDENTIALS_DIALOG.DIALOG_TITLE';
  contentMessage = 'SET_CREDENTIALS_DIALOG.CONTENT_MSG';
  landmarkSDKResources = landmarkSDKResourceClass;
  invalidCredentialsMessage = 'SET_CREDENTIALS_DIALOG.INVALID_CREDENTIALS_MSG';
  saveCredentialsForm: any = '';
  public onCredentialSaveSuccess: EventEmitter<string>;
  formSubmitted = false;
  credential: Credential;

  showProgressIndicator = false;
  userName = '';
  dialogContext = '';
  landmarkSSServiceUrl = '';

  exceptionLoggerServiceURL = '/exceptionLoggerService';
  form: FormGroup;

  storeFrontURL = '';
  citrixServiceURL = '';
  leaProductsManagementService = '';
  landmarkAppName = '';

  constructor(@Inject(API_VERSION) public apiVersion: string,
    private readonly _service: SecureStoreServiceManager,
    private readonly fb: FormBuilder, _http: HttpClient,
    private readonly notification: NotificationService) {
    super(apiVersion, undefined, undefined, undefined, undefined, undefined, _http);

    this.form = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    });

    this.formSubmitted = false;
    this.userName = '';
    this.onCredentialSaveSuccess = new EventEmitter<string>();
    this.credential = new Credential();
    this.dialogContext = 'setcredentialbutton';
    this.registerValidation();
  }

  public initialize(): void {
    if (this._service !== undefined) {
      this._service.initialize()
        .subscribe((_userObj: User) => {
          this.landmarkAppName = _userObj.clientName;
          this.setApplicationName(_userObj.clientName);
          this._service.get(`${this.apiVersion}/api/services/CitrixService`)
            .subscribe((serviceSetting: any) => {

              if (typeof serviceSetting.ConnectionURL === 'undefined') {
                this.showErrorMessage(this.landmarkSDKResources.configError.Text);
              } else {
                this.storeFrontURL = serviceSetting.ConnectionURL;
              }

              this.accessTokenIsReady();
            },
            (_error: any) => {
              this.showErrorMessage(this.landmarkSDKResources.configError.Text);

              this._service.log(this.exceptionLoggerServiceURL, {
                componentName: this._service.component,
                type: 'exception',
                errorCode: '',
                errorMessage: this.landmarkSDKResources.failedToLoadConfigurationWithKey.
                  Text.replace('{0}',
                    'CitrixService'),
                host: ''
              });
            });

        },
        (_error: any) => {
          this.showProgressIndicator = false;
        },
        () => {
          this.showProgressIndicator = false;
        });
    }
  }
  accessTokenIsReady(): void {
    this.getConfigurations('adminconsolecomponentConfigurations', 'adminConsoleConfiguration')
      .subscribe((landmarkConfigData: any) => {

        if (this.landmarkAppName !== undefined) {

          this.landmarkSSServiceUrl = landmarkConfigData.landmarkSSServiceUrl;
          this.citrixServiceURL = landmarkConfigData.citrixServiceURL;
          this.leaProductsManagementService = landmarkConfigData.leaProductsManagementService;

        } else {

          if (this.landmarkAppName === undefined) {
            this.showErrorMessage(this.landmarkSDKResources.applicationNotFound.Text);
          }

          let missingConfigurationKey = '';
          if (this.landmarkSSServiceUrl === undefined) {
            missingConfigurationKey = 'landmarkSSServiceUrl';
          }

          if (missingConfigurationKey !== '') {
            this._service.log(this.exceptionLoggerServiceURL, {
              componentName: this._service.component,
              type: 'exception',
              errorCode: '',
              errorMessage: this.landmarkSDKResources.failedToLoadConfigurationWithKey.
                Text.replace('{0}',
                  missingConfigurationKey),
              host: ''
            });
          }
        }
      },
      (_error: any) => {
        console.log('error while getting configurations');
      });
  }

  registerValidation(): void {
    if (this.fb) {
      this.saveCredentialsForm = {};
      this.saveCredentialsForm = this.fb.group({
        // eslint-disable-next-line @typescript-eslint/unbound-method
        username: ['', Validators.required],
        // eslint-disable-next-line @typescript-eslint/unbound-method
        password: ['', Validators.required],
      });
    }
  }

  destroyLoadedObject(formModel: any, _event: Event): void {
    this.resetState();

    formModel.form.controls.username._touched = false;
    formModel.form.controls.password._touched = false;
    this.popupDisplay = false;
  }

  resetState(): void {
    this.credential = new Credential();
    this.formSubmitted = false;
    this.hideErrorMessage();
    this.hideInfoMessage();
    this.hideSuccessMessage();
  }

  showErrorMessage(messageToShow: any) {
    this._service.log(this.exceptionLoggerServiceURL, {
      componentName: this._service.component,
      type: 'exception',
      errorCode: '',
      errorMessage: messageToShow,
      host: ''
    });
    if ((typeof messageToShow === 'object') && messageToShow.status !== undefined &&
            messageToShow.statusText !== undefined) {
      this.notification.clear();
      this.notification.addMessage({
        severity: 'error',
        summary: 'LANDMARK_SDK.PRODUCTS.ERROR_MSG.SERVICE_ERROR',
        parent:WFC_CREDENTIALS_DIALOG,
        closable: true,
        autoClear: true,
        notificationTimeout: 10000
      });
    } else {
      this.notification.clear();
      this.notification.addMessage({
        severity: 'error',
        parent:WFC_CREDENTIALS_DIALOG,
        summary: messageToShow,
        closable: true,
        autoClear: true,
        notificationTimeout: 10000
      });
    }
  }

  public hideErrorMessage(): void {
    this.notification.clear();
  }

  public hideSuccessMessage(): void {
    this.notification.clear();
  }

  public showInfoMessage(messageToShow: string): void {
    this.notification.clear();
    this.notification.addMessage({
      severity: 'info',
      parent:WFC_CREDENTIALS_DIALOG,
      summary: messageToShow,
      closable: true,
      autoClear: true,
      notificationTimeout: 10000
    });
  }

  public hideInfoMessage(): void {
    this.notification.clear();
  }

  togglePopup(userName: string, message: string): void {
    if (this.popupDisplay) {
      this.popupDisplay = false;
    } else {
      this.popupDisplay = true;

      this.userName = userName;

      if (message !== null) {
        this.showErrorMessage(message);
        this.credential.username = '';
        this.credential.password = '';
        this.dialogContext = 'launchapp';
      } else {
        this.dialogContext = 'setcredentialbutton';
        this.getCredentails();
      }

    }
  }

  saveCredentialsButtonClick(isValid: boolean): void {
    if (isValid) {
      this.hideErrorMessage();
      if (this.credential.username.lastIndexOf('\\') > 0 || this.credential.username.lastIndexOf('@') > 0) {
        this.showProgressIndicator = true;
        this.validateCredentials();
      } else {
        this.showErrorMessage('Please provide domain qualified username.');
      }
    } else {
      this.formSubmitted = true;
    }
  }

  validateCredentials(): void {
    this._service.validateCredentials(this.userName, this.credential.username, this.credential.password,
      this.citrixServiceURL, this.storeFrontURL)
      .subscribe((response: any) => {
        try {
          this.showProgressIndicator = true;

          if (response.status === 'success') {
            this.saveCredentials();
          } else {
            this.hideInfoMessage();
            const message = this.invalidCredentialsMessage;
            this.showErrorMessage(message);
            this._service.log(this.exceptionLoggerServiceURL, {
              componentName: this._service.component,
              type: 'exception',
              errorCode: '',
              errorMessage: message,
              host: ''
            });
          }
        } catch (exp) {
          this.showErrorMessage(exp.message);
          this.showProgressIndicator = false;
          console.log(exp);
          this.hideInfoMessage();
        }
      },
      (error: any) => {
        console.log(error);
        const message = this.landmarkSDKResources.failedToSaveCredentialsInvalidSSUrl.Text;
        if (typeof error !== 'undefined' && error.status === 404) {

          this.showErrorMessage(message);
          this._service.log(this.exceptionLoggerServiceURL, {
            componentName: this._service.component,
            type: 'exception',
            errorCode: error.status,
            errorMessage: error._body,
            host: ''
          });
        } else {
          this.showErrorMessage(this.invalidCredentialsMessage);
        }
        this.showProgressIndicator = false;
        this.hideInfoMessage();
      },
      () => {
        this.showProgressIndicator = false;
      }
      );
  }

  saveCredentials(): void {
    this._service.saveCredentials(this.userName, this.credential.username,
      this.credential.password, this.landmarkSSServiceUrl)
      .subscribe((response: any) => {
        try {
          this.showProgressIndicator = false;
          if (response.isSuccessful === true) {
            this.onCredentialSaveSuccess.emit(this.dialogContext);
            this.resetState();
          } else {
            this.showErrorMessage('Unable to save credentials.');
            this._service.log(this.exceptionLoggerServiceURL, {
              componentName: this._service.component,
              type: 'exception',
              errorCode: response.statusCode,
              errorMessage: response.message,
              host: ''
            });
          }
        } catch (exp) {
          this.showErrorMessage(exp.message);
          this.showProgressIndicator = false;
          console.log(exp);
        }
      },
      (error: any) => {
        console.log(error);
        const message = this.landmarkSDKResources.failedToSaveCredentialsInvalidSSUrl.Text;
        if (error !== undefined && error !== null && error.status === 404) {

          this.showErrorMessage(message);
          this._service.log(this.exceptionLoggerServiceURL, {
            componentName: this._service.component,
            type: 'exception',
            errorCode: error.status,
            errorMessage: error._body,
            host: ''
          });
        } else if (error !== undefined && error !== null && error.status === 200) {
          this.onCredentialSaveSuccess.emit(this.dialogContext);
          this.popupDisplay = false;

        } else {
          this.showErrorMessage('Unable to save credentials.');
        }

        this.showProgressIndicator = false;
      },
      () => {
        this.showProgressIndicator = false;
      }
      );
  }

  getCredentails(): void {
    this.showProgressIndicator = true;
    this._service.getCredentials(this.userName, this.landmarkSSServiceUrl)
      .subscribe((response: any) => {
        try {
          this.showProgressIndicator = false;
          this.resetState();

          if (response.status === 'success' && response.credentials !== null) {
            this.credential.username = response.credentials.Username;
            this.credential.password = response.credentials.Password;
          }
        } catch (exp) {
          this.showErrorMessage(exp.message);
          this.showProgressIndicator = false;
          console.log(exp);
        }
      },
      (error: any) => {
        const message = this.landmarkSDKResources.invalidSSUrl.Text;
        if (error !== undefined && error !== null && error.status === 404) {

          this.showErrorMessage(message);
          this._service.log(this.exceptionLoggerServiceURL, {
            componentName: this._service.component,
            type: 'exception',
            errorCode: error.status,
            errorMessage: error._body,
            host: ''
          });
        } else {
          this.showErrorMessage('User credentials could not be found.');
        }

        console.log(error);
        this.showProgressIndicator = false;
      },
      () => {
        this.showProgressIndicator = false;
      }
      );
  }

  validateCredentialsButtonClick(isValid: boolean): void {
    if (isValid) {
      this.hideErrorMessage();
      if (this.credential.username.lastIndexOf('\\') > 0 || this.credential.username.lastIndexOf('@') > 0) {
        this.showProgressIndicator = true;
        this._service.validateCredentials(this.userName, this.credential.username, this.credential.password,
          this.citrixServiceURL, this.storeFrontURL)
          .subscribe((response: any) => {

            this.showProgressIndicator = false;
            if (response.status === 'success') {
              this.showInfoMessage('Your credentials are valid.');
            } else {
              this.hideInfoMessage();
              const message = this.invalidCredentialsMessage;
              this.showErrorMessage(message);
              this._service.log(this.exceptionLoggerServiceURL, {
                componentName: this._service.component,
                type: 'exception',
                errorCode: '',
                errorMessage: message,
                host: ''
              });
            }
          },
          (error: any) => {
            this.handleErrorMessage(error);
          },
          () => {
            this.showProgressIndicator = false;
          }
          );
      } else {
        this.showErrorMessage('Please provide domain qualified username.');
      }
    } else {
      this.formSubmitted = true;
    }
  }

  handleErrorMessage(error: any): void {
    const message = this.landmarkSDKResources.invalidSSUrl.Text;
    if (typeof error !== 'undefined' && error.status === 404) {

      this.showErrorMessage(message);
      this._service.log(this.exceptionLoggerServiceURL, {
        componentName: this._service.component,
        type: 'exception',
        errorCode: error.status,
        errorMessage: error._body,
        host: ''
      });
    } else {
      this.showErrorMessage(this.invalidCredentialsMessage);
    }
    console.log(error);

    this.showProgressIndicator = false;
    this.hideInfoMessage();
  }
}
