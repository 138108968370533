import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccessManagementServiceManager } from '../services/access-management.service';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models/user';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly accessMgmService: AccessManagementServiceManager,
    public readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this.authService.initializeAuthentication().pipe(
      switchMap((user: User) => {
        if (user) {
          if (route && route.params && route.params['layout']) {
            return this.accessMgmService.checkUserAccessOnActivity(route.params['layout']);
          } else {
            return of(false);
          }
        } else {
          return of(false);
        }
      }),
      map((doesUserHavePermission) => {
        if (!doesUserHavePermission) {
          void this.router.navigate([
            '/error',
            {
              error: 'Access Denied!',
              errorDetails:
                'You do not have permissions to access this page.' +
                'Please contact administrator to get appropriate access.',
            },
          ]);
        }

        return doesUserHavePermission;
      })
    );
  }
}
