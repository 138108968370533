import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectedItem } from '../../model/grid.interface';
import { WFC_VERSION } from '../../version';


@Component({
  selector: 'wfc-grid-select-box',
  templateUrl: './select-box.component.html'
})
export class SelectBoxComponent {
  public static version = WFC_VERSION.full;
  @Input() itemId = '';
  @Input() selectedResults: string[] = [];
  @Input() totalRecordsSelected = false;
  @Input() recordsOnPageSelected = false;
  @Input() isSelectAll = false;
  @Input() result: unknown;
  @Output() itemSelectedEvent: EventEmitter<SelectedItem> = new EventEmitter<SelectedItem>();

  /**
   * @method itemSelected {getter}
   * @description gets the preset status of the checkbox
  */
  get itemSelected(): boolean {
    if (this.totalRecordsSelected) {
      return true;
    }

    return (!this.isSelectAll ?
      this.selectedResults.findIndex( item => item === this.itemId ) > -1 :
      this.recordsOnPageSelected);
  }


  /**
   * @method itemSelected {setter}
   * @description update the items selected array on selection/unselection of checkbox
   * @param value current value of the checkbox
  */
  set itemSelected(value: boolean) {
    this.itemSelectedEvent.emit({
      value: value,
      itemId: this.itemId,
      isSelectAll: this.isSelectAll,
      result: this.result
    });
  }
}
