import {
  ChangeDetectionStrategy, Component, EventEmitter,
  HostBinding, HostListener, Input, Output
} from '@angular/core';
import { map, mergeMap, pairwise, takeUntil } from 'rxjs/operators';

export interface DragDelta {
  dx: number;
  dy: number;
}

@Component({
  selector: 'wfc-split-bar',
  template: '',
  styleUrls: ['./split-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitBarComponent {
  @Input()
  orientation?: 'vertical' | 'horizontal';

  @Output()
  dragStart = new EventEmitter<MouseEvent>();
  @Output()
  drag = new EventEmitter<DragDelta>();

  @HostBinding('style.cursor')
  get cursor(): string {
    return this.orientation === 'vertical' ? 'col-resize' : 'row-resize';
  }

  private readonly mouseup = new EventEmitter<MouseEvent>();
  private readonly mousemove = new EventEmitter<MouseEvent>();

  constructor() {
    this.dragStart
      .pipe(
        mergeMap(() =>
          this.mousemove.pipe(
            pairwise(),
            map(pos => ({
              dx: pos[1].clientX - pos[0].clientX,
              dy: pos[1].clientY - pos[0].clientY
            })),
            takeUntil(this.mouseup)
          )
        )
      )
      .subscribe(delta => {
        this.drag.emit(delta);
      });
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event: MouseEvent): void {
    this.mouseup.emit(event);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event: MouseEvent): boolean {
    this.dragStart.emit(event);

    return false; // Call preventDefault() on the event
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove(event: MouseEvent): void {
    this.mousemove.emit(event);
  }
}
