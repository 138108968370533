import { CommonModule } from '@angular/common';
import { APP_BOOTSTRAP_LISTENER, ComponentRef, ErrorHandler,
  ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import Keycloak, { KeycloakConfig, KeycloakInstance } from 'keycloak-js';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

import { BroadcastEffects } from './effects/broadcast.effects';
import { SessionEffects } from './effects/session.effects';
import { AppGlobalErrorhandler } from './error-handlers/appglobalerrorhandler';
import { AuthGuard } from './route-guards/auth-guard.service';
import { ActivityTrackerService } from './services/activity-tracker.service';
import { KEYCLOAK_FACTORY } from './services/authentication.service';
import { BroadcastService } from './services/broadcast.service';
import {
  BROWSER_SESSION_STORAGE,
  BrowserHistoryService,
  getSessionStorageFactory,
  MAX_SIZE_SESSION_STORAGE,
} from './services/browser-history.service';
import { ContentService } from './services/content.service';
import { CustomPreloadingStrategy } from './services/custom-preload-strategy';
import { NewrelicInteractionService } from './services/newrelic-interaction.service';
import { ODataService } from './services/odata-service/service/odata.service';
import { SessionService } from './services/session.service';
import { API_VERSION } from './services/webframework.service';
import { WEB_FRAMEWORK_VERSION } from './version';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- factory function
export function setWebframeworkVersion(componentRef: ComponentRef<unknown>): void {
  (componentRef.location.nativeElement as HTMLElement).setAttribute('wf-version', WEB_FRAMEWORK_VERSION.full);
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- factory function
export function createKeycloak(config: KeycloakConfig): KeycloakInstance {
  return Keycloak(config);
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- factory function
export function getKeycloakFactory(): (config: KeycloakConfig) => KeycloakInstance {
  return createKeycloak;
}

@NgModule({
  imports: [
    CommonModule,
    DynamicDialogModule,
    EffectsModule.forFeature([
      SessionEffects,
      BroadcastEffects
    ]),
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ActivityTrackerService,
        AuthGuard,
        BroadcastService,
        ContentService,
        CustomPreloadingStrategy,
        NewrelicInteractionService,
        ODataService,
        SessionService,
        DialogService,
        BrowserHistoryService,
        // The following code injects webframework version as an attribute to the bootstrapped component
        // which will the the root component in case of applications
        {
          provide: APP_BOOTSTRAP_LISTENER,
          multi: true,
          useValue: setWebframeworkVersion
        },
        {
          provide: API_VERSION,
          useValue: ''
        },
        {
          provide: KEYCLOAK_FACTORY,
          useFactory: getKeycloakFactory
        },
        {
          provide: ErrorHandler,
          useClass: AppGlobalErrorhandler
        },
        {
          provide: BROWSER_SESSION_STORAGE,
          useFactory: getSessionStorageFactory
        },
        {
          provide: MAX_SIZE_SESSION_STORAGE,
          useValue: 50
        }
      ]
    };
  }

  static forShared(providers: Provider[]): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers
    };
  }
}
