import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as layoutActions from '../actions/layout.actions';
import * as panelActions from '../actions/panel.actions';
import { HostPanel } from '../models/host-panel';
import { Panel } from '../models/panel';
import * as fromLayout from '../reducers/root';

@Component({
  selector: 'wfc-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
})
export class LayoutContainerComponent implements OnInit {
  @ContentChild(TemplateRef, { static: false })
  templateRef: any;

  public error?: string;

  public layout$: Observable<Panel>;

  constructor(private readonly store: Store<fromLayout.LayoutState>, private readonly route: ActivatedRoute) {
    this.layout$ = store.pipe(select(fromLayout.getDenormalizedLayout));
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      ({ layout }) => layout && this.store.dispatch(layoutActions.load({ layoutName: layout }))
    );
  }

  onPanelCollapse({ id }: HostPanel): void {
    this.store.dispatch(panelActions.collapse({ payload: id }));
  }

  onPanelMaximize({ id }: HostPanel): void {
    this.store.dispatch(panelActions.maximize({ payload: id }));
  }

  onPanelRestore({ id }: HostPanel): void {
    this.store.dispatch(panelActions.restore({ payload: id }));
  }
}
