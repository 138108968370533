import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]' // tslint:disable-line
})
export class ClickOutsideDirective {
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  constructor(private readonly _elementRef: ElementRef) { }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = (this._elementRef.nativeElement === targetElement ||
                           this._elementRef.nativeElement.contains(targetElement) ||
                           event.composedPath().indexOf(this._elementRef.nativeElement) !== -1);
    if (!clickedInside) {
      this.clickOutside.emit(event);
    }
  }
}
