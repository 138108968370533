import { Component, OnDestroy } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ConfigEditorService } from '../../services/config-editor.service';

interface EditableConfigs {
  paths: string[];
}

interface DsisCollections {
  id: string;
  name: string;
  applicationId: string;
}

interface DsisConfigurations {
  id: string;
  key: string;
  value: unknown;
}

@Component({
  selector: 'wfc-config-tree',
  templateUrl: './config-tree.component.html',
  styleUrls: ['./config-tree.component.scss']
})
export class ConfigTreeComponent implements OnDestroy {
  public path = '';
  public loading = false;
  public editableConfigs = [];
  public showDialog$ = new BehaviorSubject<boolean>(false);
  public selectedNode: TreeNode;
  treeNodes$: Observable<TreeNode[]>;
  hasPermission$: Observable<boolean>;
  getNodeChildrenSubscription!: Subscription;
  getSelectedNodeSubscription!: Subscription;

  constructor(public configEditorService: ConfigEditorService) {
    this.hasPermission$ = this.configEditorService
      .hasPermission()
      .pipe(tap(isAdmin => this.showDialog$.next(!isAdmin)));
    this.treeNodes$ = this.hasPermission$.pipe(
      filter(isAdmin => isAdmin),
      switchMap(() => this.loadCollection())
    );
  }

  loadCollection(): Observable<{ label: string; leaf: boolean; data: string[] }[]> {
    return this.configEditorService
      .getCollections<EditableConfigs>('/visibleEditableConfigurations/configurations/editableConfigs')
      .pipe(
        switchMap(editableConfigs => this.configEditorService.getCollections<DsisCollections[]>().pipe(
          map(configs => configs.map(config => config.name)),
          map(names => editableConfigs.paths.filter(path => names.includes(path.split('/')[1])))
        )),
        map(paths => {
          // Create a list with unique labels
          const labels = [...new Set(paths.map(p => p.split('/')[1]))];
          this.editableConfigs = paths;

          return labels.map(item => ({
            label: item,
            leaf: false,
            // data will have all the paths which are editable under this label
            data: paths.filter(p => p.startsWith(`/${item}`))
          }));
        })
      );

  }

  expandNode(event: TreeNode): void {
    if (event && event.parent === undefined) {
      event.children = [];
      this.loading = true;
      const route = `/${event.label}/configurations`;
      this.getNodeChildrenSubscription = this.configEditorService.getCollections<DsisConfigurations[]>(route)
        .subscribe(nodes => {
        // Add children which are available in editableConfigs
          event.children = nodes.filter(node => this.editableConfigs.includes(
            `/${event.label}/configurations/${node.key}`)).map(item => ({
            label: item.key,
            data: item.key
          }));
          this.loading = false;
        });
    }
  }

  selectNode(event: TreeNode): void {
    if (event && event.parent !== undefined) {
      this.configEditorService.sendResultsToJsonEditor.next({});
      this.path = `/${event.parent.label}/configurations/${event.label}`;
      this.getSelectedNodeSubscription = this.configEditorService.getCollections(this.path).subscribe(result => {
        this.configEditorService.sendResultsToJsonEditor.next({ data: result, path: this.path });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.getNodeChildrenSubscription) {
      this.getNodeChildrenSubscription.unsubscribe();
    }
    if (this.getSelectedNodeSubscription) {
      this.getSelectedNodeSubscription.unsubscribe();
    }
  }
}
