import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// https://github.com/angular/angular/issues/20351#issuecomment-344009887
/** @dynamic */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly themeObs$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(@Inject(DOCUMENT) private readonly document: Document) { }

  public setTheme(theme: string): void {
    this.themeObs$.next(theme);

    if (!theme) {
      this.document.body.removeAttribute('data-theme');

      return;
    }

    this.document.body.setAttribute('data-theme', theme);
  }

  public getThemeObs(): Observable<string> {
    return this.themeObs$.asObservable();
  }
}
