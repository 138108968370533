import { MenuItem } from 'primeng/api';

import { breadcrumbActions } from '../actions/breadcrumb';

export interface State {
  items: MenuItem[];
}

export const initialState: State = {
  items: []
};

export function reducer(
  state = initialState,
  action: breadcrumbActions.Actions
): State {
  switch (action.type) {
    case breadcrumbActions.LOAD_BREADCRUMB: {
      return {
        ...state
      };
    }

    case breadcrumbActions.LOAD_BREADCRUMB_SUCCESS: {
      return {
        ...state,
        items: action.payload
      };
    }

    case breadcrumbActions.REMOVE_REST: {
      return {
        ...state,
        items: removeRest(state.items, action.payload)
      };
    }

    case breadcrumbActions.ADD_BREADCRUMB: {
      console.log("ADded Breadcrumb");
      return {
        ...state,
        items: state.items.concat(action.payload)
      };
    }

    case breadcrumbActions.ADD_BREADCRUMB_FROM_SIDENAV: {
      return {
        ...state,
        items: [action.payload]
      };
    }

    case breadcrumbActions.REMOVE_BREADCRUMB: {
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id)
      };
    }

    default: {
      return state;
    }
  }
}

function removeRest(arr: MenuItem[], payload: MenuItem): MenuItem[] {
  const index = arr.findIndex((item: any) => item.id === payload.id);

  return arr.slice(0, index + 1);
}
