import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpinnerState } from '../models/spinner-state.interface';

@Injectable()
export class ProgressSpinnerService {
  showIndicator$: Observable<SpinnerState>;
  private readonly showIndicator = new BehaviorSubject<SpinnerState>({
    show: false, id: '', message: '',
    translateParams: {}
  });

  constructor() {
    this.showIndicator$ = this.showIndicator.asObservable();
  }

  public show(id: string | string[], msg = '', params?: { [key: string]: string} ): void {
    this.showIndicator.next({ show: true, id: id, message: msg, translateParams: params });
  }

  public hide(): void {
    this.showIndicator.next(<SpinnerState><unknown>{ show: false, id: '', message: '', translateParams: [] });
  }
}
