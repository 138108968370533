import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'wfc-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  isPopoverOpen = false;

  @Input()
  badge?: number;

  @Input()
  icon?: string;

  @Input()
  fontSet?: string;

  @Input()
  size = 18;

  @Input()
  type = 'action';

  @Input()
  text = '';

  @Input()
  target = '';

  @Input()
  disabled = false;

  @Input()
  data = {};

  @Input()
  textPosition? = 'right';

  @Input()
  popoverDirection: 'ltr' | 'rtl' = 'ltr';

  @Output()
  action = new EventEmitter();

  @HostBinding('style.height.px')
  get height() {
    return this.size;
  }

  @HostBinding('style.width.px')
  get width() {
    return this.size;
  }

  @HostBinding('style.fontSize.px')
  get fontSize() {
    return this.size;
  }

  onClick() {
    if (this.type === 'popover') {
      this.isPopoverOpen = !this.isPopoverOpen;
    } else {
      this.action.emit(this.data);
    }
  }
}
