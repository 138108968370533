import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebFrameworkService } from '@webframework/client-core';
import { normalize, schema } from 'normalizr';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as layoutActions from '../actions/layout.actions';
import { Layout } from '../models/layout';
import { Panel } from '../models/panel';

@Injectable()
export class LayoutEffects {
  loadLayout$ = createEffect(() =>
    this.action$.pipe(
      ofType(layoutActions.load),
      switchMap(({ layoutName }) => this.webframeworkService.getLayoutConfiguration(layoutName).pipe(
        map((l: Layout) => layoutActions.loadSuccess(this.normalizeLayout(l))),
        catchError((error) => of(layoutActions.loadFail({ error })))
      )), 
    )
  );

  constructor(private readonly action$: Actions, private readonly webframeworkService: WebFrameworkService) {}

  private normalizeLayout(root: Layout): { layout: Layout; panels: Panel[] } {
    const individualLayout = new schema.Entity('layouts');
    const layouts = new schema.Array(individualLayout);
    individualLayout.define({ layouts });
    const rootLayout = new schema.Entity('rootLayout', { layouts });
    const normalized = normalize(root, rootLayout);

    return {
      // tslint:disable-next-line: no-non-null-assertion no-useless-cast
      layout: normalized.entities['rootLayout']![normalized.result],
      // tslint:disable-next-line: no-non-null-assertion no-useless-cast
      panels: Object.keys(<any>normalized.entities['layouts']).map((key) => normalized.entities['layouts']![key]),
    };
  }
}
