import { Action } from '@ngrx/store';

import { MenuItem } from 'primeng/api';

export namespace breadcrumbActions {
  export const LOAD_BREADCRUMB = '[Breadcurmb]: Load Breadcurmb';
  export const LOAD_BREADCRUMB_SUCCESS =
    '[Breadcurmb]: Load Breadcurmb Success';
  export const LOAD_BREADCRUMB_FAIL = '[Breadcurmb]: Load Breadcurmb Fail';

  export const ADD_BREADCRUMB = '[Breadcurmb]: Add Breadcrumb';
  export const ADD_BREADCRUMB_FROM_SIDENAV =
    '[Breadcurmb]: Add Breadcrumb From Sidenav';
  export const REMOVE_BREADCRUMB = '[Breadcurmb]: Remove Breadcrumb';
  export const REMOVE_REST = '[Breadcurmb]: Remove Rest';

  export class LoadBreadcrumb implements Action {
    readonly type = LOAD_BREADCRUMB;

    constructor(public items: MenuItem[]) {}
  }

  export class LoadBreadcrumbSuccess implements Action {
    readonly type = LOAD_BREADCRUMB_SUCCESS;

    constructor(public payload: MenuItem[]) {}
  }

  export class RemoveRest implements Action {
    readonly type = REMOVE_REST;

    constructor(public payload: any) {}
  }

  export class LoadBreadcrumbFail implements Action {
    readonly type = LOAD_BREADCRUMB_FAIL;
  }

  export class AddBreadcrumb implements Action {
    readonly type = ADD_BREADCRUMB;

    constructor(public payload: MenuItem) {}
  }

  export class AddBreadcrumbFromSidenav implements Action {
    readonly type = ADD_BREADCRUMB_FROM_SIDENAV;

    constructor(public payload: MenuItem) {}
  }

  export class RemoveBreadcrumb implements Action {
    readonly type = REMOVE_BREADCRUMB;

    constructor(public payload: MenuItem) {}
  }

  export type Actions =
    | LoadBreadcrumb
    | LoadBreadcrumbSuccess
    | AddBreadcrumb
    | AddBreadcrumbFromSidenav
    | RemoveRest
    | RemoveBreadcrumb;
}
