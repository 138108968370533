import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@webframework/client-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { USER_DRAWER_WIDGET, Widget } from './widget';

@Component({
  selector: 'wfc-user-drawer',
  templateUrl: './user-drawer.component.html',
  styleUrls: ['./user-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDrawerComponent {
  @Input() user?: User;

  _visible = false;
  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.cdr.detectChanges();
  }
  get visible() {
    return this._visible;
  }

  @Output() logout = new EventEmitter();

  widgetGroups$: Observable<[string, Widget[]][]>;

  constructor(
    @Inject(USER_DRAWER_WIDGET) widgets: Widget[],
    private readonly cdr: ChangeDetectorRef,
    translation: TranslateService
  ) {
    // Group the widgets by individual tabs
    // Translate the group names before grouping
    const groups = widgets.map((w) => w.group);
    this.widgetGroups$ = translation.stream(groups).pipe(
      map((translations: Record<string, string>) =>
        widgets.reduce((widgetGroups, widget) => {
          const groupName = translations[widget.group];
          let list = widgetGroups.find((g) => g[0] === groupName);

          if (!list) {
            list = [groupName, []];
            widgetGroups.push(list);
          }

          list[1].push(widget);

          return widgetGroups;
        }, [] as [string, Widget[]][])
      )
    );
  }
}
