import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppMenuItem } from '../models/app-menu-item';
import { BrandConfig } from '../models/brand-config';
import { User } from '../models/user';
import { WEB_FRAMEWORK_VERSION } from '../version';
import { AuthenticationService } from './authentication.service';
import { SessionService } from './session.service';

export const APPLICATION_VERSION = new InjectionToken('application.version');
export const API_VERSION = new InjectionToken('api.version');
export const OFFLINE_TOKEN_HEADER = 'X-WF-OFFLINE-TOKEN';

interface OfflineTokenApi {
  loadKeycloak: () => void;
  setOfflineToken: (token: string) => void;
  error: (error: string) => void;
}

interface OfflineTokenWindow extends Window {
  loadKeycloak(path: string): void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention --  intentional naming to avoid clashes
  interface Window { __WF_OFFLINE_TOKEN_API__: OfflineTokenApi }
}

// mark as @dynamic to suppress issues with injecting Document until we switch to Ivy
/** @dynamic */
@Injectable({
  providedIn: 'root',
})
/**
 * The primary utility service for Web Framework based applications
 */
export class WebFrameworkService {
  public version: string = WEB_FRAMEWORK_VERSION.full;
  private offlineToken?: string;

  constructor(
    private readonly sessionService: SessionService,
    public readonly auth: AuthenticationService,
    private readonly http: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(API_VERSION) public apiVersion?: string
  ) {
  }

  /**
   * Does a GET request on the URl with `apiVersion` prepended to it
   * @param url The url for resource
   */
  public getResource<TResource>(url: string): Observable<TResource> {
    return this.http.get<TResource>(`${this.apiVersion || ''}` + url);
  }

  /**
   * Return the current User object
   */
  public getCurrentUser(): Observable<User> {
    return this.sessionService.getCurrentUser();
  }

  /**
   * Gets weather the current user should have access to the Admin dropdown
   */
  public isAdmin(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      switchMap((user) =>
        this.getResource<boolean>(`/accessManagement/apps/${user.clientName}/activities/AdminDropdown`)
      )
    );
  }

  /**
   * Returns a configuration from DSIS config
   * @param dataUrl The URL for the configuration in the form of {collection}/configurations/{configuration}
   */
  public getAppConfig<TData>(dataUrl: string): Observable<TData> {
    return this.getCurrentUser().pipe(
      switchMap((user) => this.getResource<TData>(`/applications/${user.clientName}/collections/${dataUrl}`))
    );
  }

  /**
   * Returns the main menu (sidenav) configuration from DSIS
   */
  public getMenuConfiguration(): Observable<AppMenuItem[]> {
    return this.getCurrentUser().pipe(
      switchMap((user) => {
        const endpoint = `/applications/${user.clientName}/collections/menuConfigurations/configurations/appmenuConfig`;

        return this.getResource<AppMenuItem[]>(endpoint);
      })
    );
  }

  /**
   * Sends a POST request to the URl, along with X-WF-OFFLINE-TOKEN header
   * @param url The URL for job
   * @param body The request body
   * @deprecated This API is no longer supported. Use HttpClient with a blank `X-WF-OFFLINE-TOKEN` instead.
   * See
   * https://webframework.pages.openearth.community/documents/getting-started/5.x/techniques/offline-token/
   * for more info.
   */
  public postJob(url: string, body: unknown = {}): Observable<unknown> {
    return this.getOfflineToken().pipe(
      switchMap((token) =>
        this.http.post(`${this.apiVersion || ''}` + url, body, {
          headers: {
            [OFFLINE_TOKEN_HEADER]: token,
            // Header renamed to X-WF-OFFLINE-TOKEN, add this token for backwards compatibility
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'WF-OFFLINE-TOKEN': token,
          },
        })
      )
    );
  }

  /**
   * Returns an `Observable` which resolves to offline token
   */
  public getOfflineToken(clientId?: string): Observable<string> {
    // Open a popup window and then login to keycloak again in that window
    // with scope as `offline_access`. Then get the token from that window
    // and send it over
    const token$ = new Observable<string>(subscriber => {
      const iframe = this.document.createElement('iframe');
      iframe.src = 'offline-token.html';
      iframe.hidden = true;
      document.body.appendChild(iframe);

      window.__WF_OFFLINE_TOKEN_API__ = {
        loadKeycloak: () => {
          (iframe.contentWindow as OfflineTokenWindow).loadKeycloak(`${this.auth.getAuthServerUrl()}/js/keycloak.js`);
        },
        setOfflineToken: (token: string) => {
          this.offlineToken = token;
          iframe.remove();
          subscriber.next(token);
          subscriber.complete();
        },
        error: (error: string) => {
          iframe.remove();
          subscriber.error(error);
        }
      };
    });

    if (!this.offlineToken) {
      return token$;
    } else {
      return this.auth.verifyTokenValid(this.offlineToken, clientId).pipe(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
        switchMap((isValid) => (isValid ? of(this.offlineToken!) : token$))
      );
    }
  }

  /**
   * Returns a Layout configuration from DSIS config
   * @param layoutId The page id for the layout
   */
  public getLayoutConfiguration<TLayout>(layoutId: string): Observable<TLayout> {
    return this.getAppConfig<TLayout>(`pages/configurations/${layoutId}`);
  }

  /**
   * Returns the branding configuration from DSIS config
   */
  public getBrandingConfig(): Observable<BrandConfig> {
    return this.getAppConfig<BrandConfig>('brandingConfigurations/configurations/default');
  }

  /**
   * Returns the current Web Framework client library version
   */
  public getWfVersion(): string {
    return this.version;
  }
}
