import { Action, createReducer, on } from '@ngrx/store';

import * as sidenavItemActions from '../actions/sidenav-item.actions';
import * as sidenavActions from '../actions/sidenav.actions';

export interface State {
  loaded: boolean;
  loading: boolean;
  ids: string[];
  rootIds: string[];
  expandedIds: string[];
  collapsed: boolean;
  popoverItemId: string | null;
}

const initialState: State = {
  loaded: false,
  loading: false,
  ids: [],
  rootIds: [],
  expandedIds: [],
  collapsed: true,
  popoverItemId: null,
};

const sidenavReducer = createReducer(
  initialState,
  on(sidenavActions.load, (state) => ({
    ...state,
    loading: true,
  })),
  on(sidenavActions.loadSuccess, (_, { items, rootItems }) => ({
    loaded: true,
    loading: false,
    ids: items.map((item) => item.id),
    rootIds: rootItems,
    expandedIds: [],
    collapsed: true,
    popoverItemId: null,
  })),
  on(sidenavActions.addItem, (state, { payload, parentId }) => {
    if (state.ids.indexOf(payload.id) > -1) {
      return state;
    }

    return {
      ...state,
      ids: [...state.ids, payload.id],
      rootIds: parentId ? state.rootIds : state.rootIds.concat(payload.id),
    };
  }),
  on(sidenavActions.removeItem, (state, { payload }) => ({
    ...state,
    ids: state.ids.filter((id) => id !== payload),
    rootIds: state.rootIds.filter((id) => id !== payload),
    expandedIds: state.expandedIds.filter((id) => id !== payload),
  })),
  on(sidenavActions.toggle, (state) => ({
    ...state,
    collapsed: !state.collapsed,
  })),
  on(sidenavActions.popover, (state, { payload }) => ({
    ...state,
    popoverItemId: payload,
  })),
  on(sidenavItemActions.toggle, (state, { payload }) => ({
    ...state,
    expandedIds:
      state.expandedIds.indexOf(payload) >= 0
        ? state.expandedIds.filter((id) => id !== payload)
        : state.expandedIds.concat(payload),
  }))
);

export function reducer(state: State, action: Action) {
  return sidenavReducer(state, action);
}

export const getLoaded = (state: State) => state.loaded;

export const getLoading = (state: State) => state.loading;

export const getIds = (state: State) => state.ids;

export const getRootIds = (state: State) => state.rootIds;

export const getCollapsed = (state: State) => state.collapsed;

export const getPopoverItemId = (state: State) => state.popoverItemId;
