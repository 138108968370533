import { CommonModule } from '@angular/common';
import { NgModule, SystemJsNgModuleLoader } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule, SharedModule } from '@webframework/client-shared';
import { MessageModule } from 'primeng/message';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { PanelModule } from 'primeng/panel';

import { LayoutEffects } from './effects/layout.effects';
import { HostContainerComponent } from './host-container/host-container.component';
import { LayoutContainerComponent } from './layout-container/layout-container.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelHostComponent } from './panel-host/panel-host.component';
import { reducers } from './reducers/root';
import { SplitBarComponent } from './split-bar/split-bar.component';
import { SplitHostComponent } from './split-host/split-host.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    EffectsModule.forFeature([LayoutEffects]),
    IconModule,
    MessageModule,
    SharedModule,
    StoreModule.forFeature('layout', reducers),
    ScrollPanelModule,
    TooltipModule,
    TranslateModule,
    PanelModule
  ],
  declarations: [
    SplitHostComponent,
    SplitBarComponent,
    PanelHostComponent,
    HostContainerComponent,
    PanelHeaderComponent,
    LayoutContainerComponent,
  ],
  // TODO: SystemJsNgModuleLoader is deprecated: the `string` form of `loadChildren` ispart of its implementation.
  // TODO: See `LoadChildren` for more details.
  providers: [SystemJsNgModuleLoader], // tslint:disable-line

  exports: [LayoutContainerComponent, PanelHeaderComponent],
})
export class LayoutModule {}
