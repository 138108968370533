/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  HostListener,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  Renderer2,
  OnDestroy,
  ElementRef,
  NgZone,
  AfterViewChecked
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { MenuItem, SortEvent, PrimeTemplate } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { ScrollableView, Table, TableCheckbox, TableHeaderCheckbox } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
import { ObjectUtils, FilterUtils } from 'primeng/utils';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';

import { NotificationService } from '@webframework/notification';

import { WFC_VERSION } from '../version';
import { ColumnFilterComponent } from './column-filter/column-filter.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any  -- toggleRowsWithCheckbox type from primeng component
Table.prototype.toggleRowsWithCheckbox = function (this: Table, event: any, check: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- toggleRowsWithCheckbox in primeng component
  if (!event.clearAll && this.paginator && !this.lazy) {
    const rows = (this.filteredValue || this.value || []).slice(this.first, this.first + this.rows);
    this._selection = check
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- _selection type any from primeng component
      ? [...this._selection, ..._.differenceBy(rows, this._selection, this.dataKey)]
      : _.differenceBy(this._selection, rows, this.dataKey);
  } else {
    this._selection = check ? (this.filteredValue || this.value || []).slice() : [];
  }
  this.preventSelectionSetterPropagation = true;
  this.updateSelectionKeys();
  this.selectionChange.emit(this._selection);
  this.tableService.onSelectionChange();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- toggleRowsWithCheckbox from primeng component
  this.onHeaderCheckboxToggle.emit({ originalEvent: event, checked: check });

  if (this.isStateful()) {
    this.saveState();
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
Table.prototype.updateSelectionKeys = function (this: Table) {
  if (this.dataKey && this._selection) {
    this.selectionKeys = {};
    if (Array.isArray(this._selection)) {
      for (const data of this._selection) {
        if (!_.isObject(data)) {
          // eslint-disable-next-line max-len -- long comment for eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- property selectionKeys is of type any from primeng component
          this.selectionKeys[data] = 1;
        } else {
          // eslint-disable-next-line max-len -- long comment for eslint disable
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- property selectionKeys is of type any from primeng component
          this.selectionKeys[String(ObjectUtils.resolveFieldData(data, this.dataKey))] = 1;
        }
      }
    } else {
      if (!_.isObject(this._selection)) {
        // eslint-disable-next-line max-len -- long comment for eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- property selectionKeys is of type any from primeng component
        this.selectionKeys[this._selection] = 1;
      } else {
        // eslint-disable-next-line max-len -- long comment for eslint disable
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- property selectionKeys is of type any from primeng component
        this.selectionKeys[String(ObjectUtils.resolveFieldData(this._selection, this.dataKey))] = 1;
      }
    }
  }
};

TableHeaderCheckbox.prototype.updateCheckedState = function (this: TableHeaderCheckbox) {
  const rows = this.dt.filteredValue || this.dt.value || [];
  const val = this.dt.paginator && !this.dt.lazy ? rows.slice(this.dt.first, this.dt.first + this.dt.rows) : rows;
  this.cd.markForCheck();

  return (
    val &&
    val.length > 0 &&
    this.dt.selection &&
    // eslint-disable-next-line max-len -- long comment for eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- selection is of type any from primeng  component
    this.dt.selection.length > 0 &&
    (this.dt.filteredValue ? this.isAllFilteredValuesChecked() : !_.find(val, (v: any) => !this.dt.isSelected(v)))
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- toggleRowsWithCheckbox type from primeng component
TableCheckbox.prototype.onClick = function (this: TableCheckbox, event: any) {
  // eslint-disable-next-line max-len -- long comment for eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- toggleRowsWithCheckbox type from primeng component
  event.preventDefault();
  // eslint-disable-next-line max-len -- long comment for eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- toggleRowsWithCheckbox type from primeng component
  event.stopPropagation();
  if (!this.disabled) {
    this.dt.toggleRowWithCheckbox(
      {
        // eslint-disable-next-line max-len -- long comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- toggleRowsWithCheckbox type from primeng component
        originalEvent: event,
        rowIndex: this.index
      },
      this.value
    );
  }
  DomHandler.clearSelection();
};

// Rajesh: disable infinite scroll when paginator enabled
// eslint-disable-next-line sonarjs/cognitive-complexity
ScrollableView.prototype.onScrollIndexChange = function (this: ScrollableView, index: number) {
  if (this.dt.lazy) {
    const page = Math.floor(index / this.dt.rows);
    // Rajesh:
    // infinite scrolling doesn't work well with paginator
    // so, if the row index is greater than the actual page size,
    // then return here instead of loading next page on scroll
    if (page > 0 && this.dt.paginator) {
      return;
    }
    const virtualScrollOffset = this.dt.paginator
      ? (this.dt.rows * page)
      : (page === 0 ? 0 : (page - 1) * this.dt.rows);
    const virtualScrollChunkSize = this.dt.paginator ? this.dt.rows
      : (page === 0 ? this.dt.rows * 2 : this.dt.rows * 3);

    if (page !== this['virtualPage']) {
      _.set(this, 'virtualPage', page);
      this.dt.onLazyLoad.emit({
        first: virtualScrollOffset,
        rows: virtualScrollChunkSize,
        sortField: this.dt.sortField,
        sortOrder: this.dt.sortOrder,
        filters: this.dt.filters,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FilterMetadata type in primeng component
        globalFilter: this.dt.filters && this.dt.filters['global']
          ? (this.dt.filters['global']).value : null,
        multiSortMeta: this.dt.multiSortMeta
      });
    }
  }
};

// eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any -- FilterMetadata type in primeng component
FilterUtils['in'] = (value: any, filter: any[]): boolean => {
  if (_.isNil(filter) || _.isEmpty(filter)) {
    return true;
  }

  if (_.isNil(value)) {
    return false;
  }

  // eslint-disable-next-line max-len -- multiple eslint disable
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- value according to type of FilterUtils from angular core
  const val = value.toLowerCase();
  for (let i = 0; i < filter.length; i++) {
    // eslint-disable-next-line max-len -- multiple eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- type of FilterMetadata from primeng component
    const filterValue = (filter[i] || '').toLowerCase();
    if (filterValue === val || _.includes(val, filterValue)) {
      return true;
    }
  }

  return false;
};

Dropdown.prototype.onItemClick = function (this: Dropdown, event: any) {
  event.originalEvent.preventDefault();
  event.originalEvent.stopPropagation();

  const option = event.option;
  // tslint:disable-next-line: no-commented-code
  // this.itemClick = true;
  if (!option.disabled) {
    this.selectItem(event, option);
    // tslint:disable-next-line: no-commented-code
    // this.focusViewChild.nativeElement.focus();
  }
  setTimeout(() => {
    this.hide(event);
    // tslint:disable-next-line: no-commented-code
    // this.itemClick = false;
  }, 150);
};

/* *`
 * A Webframework Component which displays the user info
 */
@Component({
  selector: 'wfc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  viewProviders: [NotificationService]
})
export class GridComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit, AfterViewChecked, OnDestroy {
  public static version = WFC_VERSION.full;
  @Input() dataKey = 'id';
  @Input() hideDisable = false;
  @Input() showSort = true;
  @Input() showFilter = true;
  @Input() customSort = false;
  @Input() actionsRefTemplate: any;
  @Input() actionsPosition: 'left' | 'right' = 'left';
  @Input() showExcelexport = false;
  @Input() customExcelevent = false;
  @Input() fileName = 'Grid';
  @Input() columnFilterTemplate: any = null;
  @Input() nestedGridTemplate: any = null;
  @Input() selectionMode?: 'single' | 'multiple';
  @Input() emptyMessage = 'GRID.EMPTY_RECORDS';
  @Input() pageLinkSize = '10';
  @Input() rowsPerPageList = [5, 10, 15, 20, 50, 100, 200, 500, 1000];
  @Input() reorderableColumns = true;
  @Input() resizableColumns = true;
  @Input() sortMode = 'single';
  @Input() resetPageOnSort = true;
  @Input() maxSelectedLabels = 0;
  @Input() metaKeySelection = false;

  @Input() scrollable = true;
  @Input() virtualScroll = false;
  @Input() virtualRowHeight = 33;
  @Input() scrollHeight = 'flex';
  @Input() tableStyleClass = this.scrollable ? 'p-datatable-scrollable-body-table' : 'p-datatable-body-table';

  @Input() lazy = true;
  @Input() defaultPageSize = 10;
  @Input() totalRecords = 0;
  @Input() gridHeaderActions = [];
  @Input() offsetHeight = 0;
  @Input() gridContainerSelector = '';
  @Input() globalActions = '';
  @Input() loading: boolean;
  @Input() virtualRows = 10;
  @Input() selectedFilterMode = 'startsWith';
  @Input() gridStatisCols = 3;
  @Input() alwaysShowPaginator = true;
  @Input() paginator = true;
  @Input() first = 0;
  @Input() editable = false;
  @Input() columnResizeMode = 'fit';
  @Input() disableHeader = false;
  @Input() export = false;
  @Input() tableStyle: any;
  @Input() paginatorDropdownAppendTo = 'body';
  @Input() columnChooserDisabled = false;
  @Input() editMode: 'row' | 'cell' = 'cell';
  @Input() frozenColumns: any[];
  @Input() frozenWidth: string;
  @Input() showConfigButton = true;
  @Input() showRefreshButton = true;
  @Input() showImportButton = true;
  @Input() showAddButton = true;

  @Input() stateKey: string;
  @Input() stateStorage = 'session';

  @Output() public gridItemSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public getGridPreferences = new EventEmitter<any>();
  @Output() public getAddToCartItems = new EventEmitter<any>();
  @Output() public getExportToExcel = new EventEmitter<any>();
  @Output() public genericGridEvent = new EventEmitter<any>();
  @Output() public colReorder = new EventEmitter<any>();
  @Output() public headerCheckboxToggle = new EventEmitter<any>();
  @Output() public pageChange = new EventEmitter<any>();
  @Output() public rowCollapse = new EventEmitter<any>();
  @Output() public rowExpand = new EventEmitter<any>();
  @Output() public rowSelect = new EventEmitter<any>();
  @Output() public rowUnSelect = new EventEmitter<any>();
  @Output() public lazyLoad = new EventEmitter<any>();
  @Output() public gridLoaded = new EventEmitter<any>();
  @Output() public filterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public exportToExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickConfigButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickRefreshButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAddButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickImportButton: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix -- intentional naming
  @Output() onPageReport = new EventEmitter<any>();
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() sortFunction: EventEmitter<any> = new EventEmitter();
  @Output() public editInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public editComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public editCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt', { static: false }) grid!: Table;
  @ViewChild('spanLink', { static: false }) spanLink!: any;

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  public items: MenuItem[] = [];
  public selectedMenu: any;
  public selectedColumn: any;
  public display = false;
  public height = '';
  public columnMinWidth = false;
  public dataColumns: any[] = [];
  public selectedItems: any[] = [];
  public filterColumns: any[] = [];
  public columnFilters = [
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
    { label: 'Equals', value: 'equals' },
    { label: 'In', value: 'in' }
  ];
  public selectedColumns: Column[];
  public staticCols = Array(this.gridStatisCols).fill('');
  // eslint-disable-next-line @typescript-eslint/naming-convention -- intentional naming
  public get EmptyMessageColSpan(): number {
    let colspan = (this.dataColumns || []).filter((col: Column) => !col.hidden).length;

    if (this.nestedGridTemplate) {
      colspan++;
    }
    if (this.selectionMode === 'multiple') {
      colspan++;
    }
    if (this.actionsRefTemplate) {
      colspan++;
    }
    if (this.editMode === 'row') {
      colspan++;
    }

    return colspan;
  }

  headerTemplate: TemplateRef<any>;
  captionTemplate: TemplateRef<any>;
  cellTemplates: { name: string; template: TemplateRef<any> }[] = [];
  readonly translate$: Observable<any>;

  readonly pageReportTemplate = 'GRID.PAGE_REPORT_TEMPLATE';
  private readonly gridSelectallResultmsgKey = 'GRID.ROW_SELECTION.SELECT_ALL_RESULTS';
  private readonly gridSelectallRowsmsgKey = 'GRID.ROW_SELECTION.ALL_ROWS';
  private _data: any[] = [];
  private _columns: Column[] = [];
  private _selection: any[] = [];
  private _notificationId;
  private recordsOnPageSelected = false;
  private _clonedData: { [d: string]: any } = {};
  private columnFilterComponent: ColumnFilterComponent;

  private readonly valueSource = new BehaviorSubject<any>(this._data);
  private readonly valueSource$ = this.valueSource.asObservable();

  private readonly selectionSource = new Subject<any>();
  private readonly selectionSource$ = this.selectionSource.asObservable();

  private readonly columnSource = new BehaviorSubject<Column[]>(this._columns);
  private readonly columnSource$ = this.columnSource.asObservable();
  private readonly _translateSubject = new BehaviorSubject<any[]>([
    'GRID.COLUMN_CHOOSER_DEFAULT_LABEL',
    'GRID.COLUMN_CHOOSER_SELECTED_LABEL',
    'GRID.EMPTY_RECORDS'
  ]);

  private readonly destroy$: Subject<void> = new Subject<void>();
  constructor(
    public el: ElementRef,
    public zone: NgZone,
    private readonly renderer: Renderer2,
    private readonly translate: TranslateService,
    private readonly notificationService: NotificationService
  ) {
    this.translate$ = this._translateSubject.pipe(
      // eslint-disable-next-line arrow-body-style -- required
      switchMap(items => {
        return this.translate.stream(items)
          .pipe(
            filter((tx: unknown) => !_.isEqual(items, items.map(i => tx[i]))),
            map(tx => ({
              loaded: true,
              labels: items.map(i => tx[i])
            })));
      }));
  }

  @Input('gridData') get data(): any[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._data;
  }
  set data(value: any[]) {
    this._data = value;
    this.valueSource.next(value);
  }

  @Input('gridColumns') get columns(): Column[] {
    return this._columns;
  }

  set columns(value: Column[]) {
    this._columns = value;
    this.columnSource.next(value);
  }

  @Input('selectedResults') get selection(): any[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._selection;
  }

  set selection(value: any[]) {
    this.selectionSource.next(value);
  }

  ngOnInit(): void {
    this.columnSource$.pipe(takeUntil(this.destroy$)).subscribe((cols: Column[]) => {
      this.validateColumns();
      this.filterColumns = this.showFilter
        ? (cols || []).filter((col: Column) => (_.isNil(col.filter) || col.filter) && !col.hidden)
        : [];
      this.selectedColumns = (cols || []).filter((col: Column) => !col.hidden && !col.columnChooserDisabled);
      this.restoreColumnSelection();
      this.selectedColumns.forEach((col: Column) => {
        col.hidden = false;
      });
      this.dataColumns = [...this.selectedColumns, ..._.filter(this.columns, 'columnChooserDisabled')];
    });

    this.valueSource$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this._clonedData = {};
      this.clearNotification();
      this.setViewportHeight();
      this.setViewportWidth();
    });

    this.selectionSource$
      .pipe(
        filter((value: any[]) => this.grid && !_.isEqual(this.grid.selection, value)),
        takeUntil(this.destroy$)
      )
      .subscribe((value: any[]) => {
        if (this.grid && this.grid.preventSelectionSetterPropagation) {
          this.grid.preventSelectionSetterPropagation = false;
        }
        this._selection = value;
      });
  }

  ngOnChanges(): void {
    if (this.globalActions) {
      this.genericGridEvent.emit({ component: this.globalActions, event: this });
    }

    this.items = [
      { label: 'View', icon: 'pi pi-search', command: () => console.log(this.selectedMenu) },
      { label: 'Delete', icon: 'pi pi-times', command: () => console.log(this.selectedMenu) }
    ];
  }

  ngAfterContentInit(): void {
    this.templates.forEach(item => {
      const name = item.getType();
      if ((name || '').length > 0) {
        // tslint:disable-next-line: no-small-switch
        switch (name) {
          case 'header':
            this.headerTemplate = item.template;
            break;
          case 'caption':
            this.captionTemplate = item.template;
            break;
          default:
            this.cellTemplates = [...this.cellTemplates, { name: item.getType(), template: item.template }];
            break;
        }
      }
    });
  }

  hasTemplate(col: string): boolean {
    if ((this.cellTemplates || []).length === 0) {
      return false;
    }

    const ct = this.cellTemplates.find((t: { name: string; template: any }) =>
      // eslint-disable-next-line @typescript-eslint/unbound-method -- required
      _.includes(_.map(_.split(t.name, '|'), _.trim), col)
    );

    return !!ct;
  }

  getTemplate(col: string): TemplateRef<any> | undefined {
    return (
      (this.cellTemplates || []).find((t: { name: string; template: any }) =>
        // eslint-disable-next-line @typescript-eslint/unbound-method -- required
        _.includes(_.map(_.split(t.name, '|'), _.trim), col)
      ) || { template: undefined }
    ).template;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onHeaderCheckboxToggle(event: any): void {
    this.notificationService.clear();
    const evt = event.originalEvent && event.originalEvent.originalEvent ? { ...event, ...event.originalEvent } : event;
    this.recordsOnPageSelected = evt.checked;
    let _selectAll = false;
    if (!evt.clearAll) {
      const totalRows = this.lazy ? this.totalRecords : (this.grid.filteredValue || this.grid.value || []).length;
      let rows = this.defaultPageSize;
      const diff = totalRows % this.defaultPageSize;
      if (totalRows - this.grid.first === diff) {
        rows = diff;
      } else if (totalRows < rows) {
        rows = totalRows;
      }

      if (evt.checked && totalRows > rows) {
        this._notificationId = this.notificationService.addMessage({
          closable: true,
          detail: 'GRID.ROW_SELECTION.RECORDS_PER_PAGE_SELECTED',
          event: (message: any) => {
            this._selection = _.unionBy(
              !this.lazy ? this.grid.filteredValue || this.data : this.data,
              this.selection,
              this.dataKey
            );
            this.clearNotification(message.id);
            this.headerCheckboxToggle.emit({
              ...evt,
              recordsOnPageSelected: this.recordsOnPageSelected,
              selectAll: true,
              clearAll: false
            });
          },
          eventTitle: this.gridSelectallResultmsgKey,
          autoClear: true,
          notificationTimeout: 20000,
          severity: 'warn',
          summary: this.gridSelectallRowsmsgKey,
          translateParams: {
            rows: rows,
            totalRows: totalRows
          }
        });
      } else if (!evt.checked || (evt.checked && totalRows === rows)) {
        _selectAll = evt.checked;
        this._notificationId = this.notificationService.addMessage({
          closable: true,
          detail: evt.checked
            ? 'GRID.ROW_SELECTION.RECORDS_PER_PAGE_SELECTED'
            : 'GRID.ROW_SELECTION.RECORDS_PER_PAGE_UNSELECTED',
          event: (message: any) => {
            this._selection = [];
            this.clearNotification(message.id);
            this.grid.toggleRowsWithCheckbox({ ...evt, clearAll: true }, false);
          },
          eventTitle: !evt.checked && rows === totalRows ? '' : 'GRID.ROW_SELECTION.CLEAR_ALL_RESULTS',
          autoClear: true,
          notificationTimeout: 20000,
          severity: 'warn',
          summary: this.gridSelectallRowsmsgKey,
          translateParams: { rows: rows }
        });
      }
    }

    this.headerCheckboxToggle.emit({
      ...evt,
      recordsOnPageSelected: this.recordsOnPageSelected,
      selectAll: _selectAll,
      clearAll: evt.clearAll || false
    });
  }

  onSelectionChange(event: any): void {
    this.selectionChange.next(event);
  }

  onRowSelect(event: any): void {
    if (!_.eq(event.type, 'checkbox')) {
      this.rowSelect.emit(event);
    }
    this.gridItemSelectedEvent.emit({
      value: true,
      itemId: event.data[this.dataKey],
      recordsOnPageSelected: this.recordsOnPageSelected,
      result: event.data
    });
  }

  onRowUnselect(event: any): void {
    this.recordsOnPageSelected = false;
    if (!_.eq(event.type, 'checkbox')) {
      this.rowUnSelect.emit(event);
    }
    this.gridItemSelectedEvent.emit({
      value: false,
      itemId: event.data[this.dataKey],
      recordsOnPageSelected: this.recordsOnPageSelected,
      result: event.data
    });
  }

  onCustomSort(event: SortEvent): void {
    this.sortFunction.emit(event);
  }

  onRowEditInit(rowData: any): void {
    const dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    this._clonedData[dataKeyValue] = { ...rowData };
  }

  onRowEditSave(rowData: any): void {
    const dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    if (this._clonedData[dataKeyValue]) {
      delete this._clonedData[dataKeyValue];
    }
  }

  onRowEditCancel(rowData: any, index: number): void {
    const dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    if (this._clonedData[dataKeyValue]) {
      this._data[index] = this._clonedData[dataKeyValue];
      this._data = [...this.data];
      delete this._clonedData[dataKeyValue];
    }
  }

  public showDialog(): void {
    this.display = true;
  }

  columnsChange(event: any): void {
    this.selectedColumns = event.value.slice();
    // try to keep at least one column selected always
    if (!this.selectedColumns.length) {
      this.selectedColumns = [_.head(_.filter(this.columns, { hidden: false, columnChooserDisabled: false }))];
    }
    const cols = [...this.selectedColumns, ..._.filter(this.columns, 'columnChooserDisabled')];

    // sort the data columns the way they originally feeded
    this.dataColumns = _.sortBy(cols, (x: Column) =>
      _.findIndex(this.columns, (y: Column) => x.columnName === y.columnName)
    );

    const columnNames = _.map(cols, 'columnName');
    _.each(this.columns, (column: Column) => {
      column.hidden = _.indexOf(columnNames, column.columnName) < 0;
    });

    this.saveColumnSelection();
  }

  saveColumnSelection(): void {
    const key = this.getStateKey();
    if (_.isEmpty(key)) {
      return;
    }

    const storage = this.getStorage();
    if (!storage) {
      return;
    }

    const state: any = JSON.parse(storage.getItem(key) || '{}');
    if (!_.isEmpty(this.selectedColumns)) {
      state.columnSelection = _.map(this.selectedColumns, 'columnName');
      storage.setItem(key, JSON.stringify(state));
    }
  }

  restoreColumnSelection(): void {
    const key = this.getStateKey();
    if (_.isEmpty(key)) {
      return;
    }

    const storage = this.getStorage();
    if (!storage) {
      return;
    }

    const state: any = JSON.parse(storage.getItem(key) || '{}');
    if (!_.isEmpty(state.columnSelection)) {
      this.selectedColumns = _.filter(
        this._columns,
        (col: Column) => _.indexOf(state.columnSelection, col.columnName) > -1
      );
    }
  }

  public exportExcel(fileName: any): void {
    if (this.exportToExcel.observers.length > 0) {
      this.exportToExcel.emit({ eventName: 'exportToExcel', event });
    } else {
      const visibleData = this.getRecordsforVisiblecolumns(this.data, this.columns);
      const worksheet = XLSX.utils.json_to_sheet(visibleData);
      // eslint-disable-next-line @typescript-eslint/naming-convention -- intentional naming
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    }
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public catchEvent(event: any, component: string): void {
    switch (component) {
      case 'gridPreferences':
        this.getGridPreferences.emit({ eventName: component, event });
        break;
      case 'addToCart':
        this.getAddToCartItems.emit({ eventName: component, event: this.selectedItems });
        break;
      case 'exportToExcel':
        this.getExportToExcel.emit({ eventName: component, event });
        break;
      case 'onColReorder':
        this.dataColumns = event.columns;
        this.colReorder.emit(event);
        break;
      case 'onPageChange':
        this.clearNotification();
        if (this.grid.paginator && !this.grid.lazy) {
          const selected = (this.grid.selection || []).slice();
          this.grid.selection = [];
          setTimeout(() => {
            this.grid.selection = selected;
          }, 0);
        }

        this.defaultPageSize = event.rows;
        this.pageChange.emit({
          originalEvent: event,
          ...event,
          filters: this.grid.filters,
          sortField: this.grid.sortField,
          sortOrder: this.grid.sortOrder
        });
        break;
      case 'onRowCollapse':
        this.rowCollapse.emit(event);
        break;
      case 'onRowExpand':
        this.rowExpand.emit(event);
        break;
      case 'onLazyLoad':
        this.lazyLoad.emit(event);
        break;
      case 'spanlink':
        this.selectedColumn = event;
        break;
      case 'gridItemSelectedEvent':
        this.gridItemSelectedEvent.emit(event);
        break;
      default:
        this.genericGridEvent.emit({ eventName: component, event });
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    if (event) {
      this.setHeight();
      this.setViewportWidth();
    }
  }

  public filterColumn(fltr: any): void {
    // eslint-disable-next-line no-shadow
    const { column, value, filter } = fltr;

    if (this.filterChange.observers.length > 0) {
      this.filterChange.emit({ column: column, value: value, filter: filter });

      return;
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method -- required
    const val = filter === 'in' && !this.lazy ? _.map(_.split(value, ','), _.trim) : value;

    this.grid.filter(val, column, filter);
  }

  public setGridContentHeight(offset: number = 0, styleClass: string = ''): string {
    let elHeight = 0;
    const documentStyleClass: HTMLElement | null = document.querySelector(styleClass);
    const el: any = documentStyleClass ? documentStyleClass.parentNode : null;
    if (el) {
      elHeight = el.offsetHeight;
    }

    return `${elHeight - offset}px`;
  }

  public ngAfterViewInit(): void {
    this.gridLoaded.emit({ grid: this.grid, paginator: {} });
    // tslint:disable-next-line: no-commented-code
    // this.setHeight();
    this.setViewportWidth();
  }

  ngAfterViewChecked(): void {
    this.setViewportHeight();
  }

  public setHeight(): void {
    if (this.grid && this.grid.initialized) {
      const selector: HTMLElement | null = document.querySelector('.p-datatable-scrollable-body');
      if (selector !== null && this.offsetHeight && this.gridContainerSelector) {
        const height = this.setGridContentHeight(this.offsetHeight, this.gridContainerSelector);
        if (this.scrollable && !this.scrollHeight) {
          this.scrollHeight = `${height}px`;
        } else {
          selector.style.maxHeight = height;
        }
      }
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setViewportHeight(): void {
    if (this.grid && this.scrollable && this.virtualScroll && this.scrollHeight === 'flex') {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          const wrapper = DomHandler.findSingle(this.grid.el.nativeElement, '.p-datatable-scrollable-wrapper');
          if (wrapper) {
            const height = DomHandler.getOuterHeight(wrapper);

            const header = DomHandler.findSingle(wrapper, '.p-datatable-scrollable-header');
            const headerHeight = DomHandler.getOuterHeight(header);
            const content = DomHandler.findSingle(wrapper, '.cdk-virtual-scroll-content-wrapper');
            const contentHeight = DomHandler.getOuterHeight(content);

            const headerTable = DomHandler.findSingle(header, '.p-datatable-scrollable-header-table');
            const scrollbarHeight = headerTable.offsetWidth > wrapper.clientWidth ? 19 : 0;

            // tslint:disable-next-line: max-line-length
            // const scrollbarHeight = content.parentNode.offsetHeight > content.parentNode.clientHeight ? 19 : 0;

            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const actualHeight: string = headerHeight + contentHeight + scrollbarHeight;
            // eslint-disable-next-line sonarjs/no-duplicate-string
            const maxHeight = getComputedStyle(wrapper).getPropertyValue('max-height');
            if (maxHeight === `${actualHeight}px`) {
              return;
            }

            if (height !== actualHeight) {
              this.renderer.setStyle(wrapper, 'max-height', `${actualHeight}px`);
            } else if (getComputedStyle(wrapper).getPropertyValue('max-height') !== 'none') {
              this.renderer.removeStyle(wrapper, 'max-height');
            }
          }
        }, 50);
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  setViewportWidth(): void {
    if (this.grid && this.scrollable && _.isEmpty(this.data)) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          const handler = DomHandler;
          const wrapper = DomHandler.findSingle(this.grid.el.nativeElement, '.p-datatable-scrollable-wrapper');
          if (!wrapper) {
            return;
          }

          const scrollHeader = handler.findSingle(wrapper, '.p-datatable-scrollable-header');
          const scrollableHeaderTable = handler.findSingle(scrollHeader, '.p-datatable-scrollable-header-table');
          const headerWidth = scrollableHeaderTable ? handler.getOuterWidth(scrollableHeaderTable) : 0;

          const scrollBody =
            handler.findSingle(wrapper, '.p-datatable-scrollable-body') ||
            handler.findSingle(wrapper, '.cdk-virtual-scroll-content-wrapper');
          const scrollableBodyTable = handler.findSingle(scrollBody, '.p-datatable-scrollable-body-table');
          const bodyWidth = scrollableBodyTable ? handler.getOuterWidth(scrollableBodyTable) : 0;

          if (headerWidth !== bodyWidth) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            scrollableBodyTable.style.width = headerWidth + 'px';
          } else if (getComputedStyle(scrollableBodyTable).getPropertyValue('width') !== 'none') {
            this.renderer.removeStyle(scrollableBodyTable, 'width');
          }
        }, 50);
      });
    }
  }

  pageReport(page: any): { [key: string]: any } {
    const interpolateParams = {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      start: page.first + 1,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      end: page.totalRecords < page.first + +page.rows ? page.totalRecords : page.first + +page.rows,
      totalRows: page.totalRecords
    };

    this.onPageReport.emit({
      template: this.pageReportTemplate,
      interpolateParams: interpolateParams
    });

    return interpolateParams;
  }

  reset(): void {
    this.grid.reset();
  }

  clearNotification(id?: number): void {
    const messageId = id || this._notificationId;
    if (messageId) {
      this.notificationService.clearMessageById(messageId);
      this._notificationId = null;
    } else {
      this.notificationService.clear();
    }
  }

  toggleColumnFilter(cfc: ColumnFilterComponent): void {
    if (this.columnFilterComponent !== cfc && !_.isNil(this.columnFilterComponent)) {
      this.columnFilterComponent.hide();
    }
    this.columnFilterComponent = cfc;
  }

  onScrollChange(): void {
    if (this.columnFilterComponent) {
      this.columnFilterComponent.hide();
      this.columnFilterComponent = null;
    }
  }

  getStorage(): Storage {
    try {
      return this.grid.getStorage();
    } catch (e) { /* empty */ }

    return null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    // remove data from cache
    const storage = this.getStorage();
    _.each(
      _.filter(_.keys(storage), (key: string) => _.startsWith(key, 'X-WFC-G-')),
      (k: string) => {
        storage.removeItem(k);
      }
    );
  }

  isNestedCheckboxVisible(): number {
    if (this.grid?.filteredValue) {
      return this.grid.filteredValue.length;
    }

    return this.data.length;
  }

  onEditInit(event) {
    this.editInit.emit(event);
  }

  onEditComplete(event) {
    this.editComplete.emit(event);
  }

  onEditCancel(event) {
    this.editCancel.emit(event);
  }

  private getStateKey(): string {
    return _.isEmpty(this.stateKey) ? null : `X-WFC-G-${_.toUpper(this.stateKey)}`;
  }

  private validateColumns(): void {
    if ((this.columns || []).length === 0) {
      return;
    }

    const visibleColumIndexes: number[] = [];
    this.columns.forEach((col: Column) => {
      col.showHiddenCol = false;
      col.columnChooserDisabled = !!col.columnChooserDisabled;
      col.width = col.width || 'auto';
      this.columnMinWidth = (col.width === 'auto');
    });

    this.columns.forEach((col, i) => {
      if (!col.hidden) {
        visibleColumIndexes.push(i);
      }
    });
    this.columns.forEach((col, i) => {
      if (col.hidden && visibleColumIndexes.length) {
        const closestVisibleColumn = visibleColumIndexes.reduce((prev, curr) =>
          Math.abs(curr - i) < Math.abs(prev - i) ? curr : prev
        );
        this.columns[closestVisibleColumn].showHiddenCol = true;
      }
    });
    if (this.columns.filter(col => !col.hidden).length <= 1) {
      this.hideDisable = true;
    } else {
      this.hideDisable = false;
    }
  }

  private getRecordsforVisiblecolumns(rawData: any, columns: Column[]): any {
    const actualData: any = [];
    for (let i = 0; i < rawData.length; i++) {
      const rowData: any = {};
      for (let j = 0; j < columns.length; j++) {
        if (columns[j].hidden === undefined || !(columns[j].hidden)) {
          const columnObj = columns[j].columnName;
          const columnDisplay = columns[j].columnDisplayName;
          rowData[columnDisplay] = rawData[i][columnObj];
        }
      }
      if (Object.keys(rowData).length > 0) {
        actualData.push(rowData);
      }
    }

    return actualData;
  }
}

export interface Column {
  catalogueName?: string;
  columnDisplayName?: string;
  columnName: string;
  columnSize?: string;
  columnType?: string;
  decimalPrecision?: string;
  defaultValue?: string;
  renderingMode?: string;
  showHiddenCol?: boolean;
  showTooltip?: boolean;
  hidden?: boolean;
  columnChooserDisabled?: boolean;
  width?: string;
  format?: string;
  filter?: boolean;
  editable?: boolean;
  hideColumnName?: boolean;
  sortable?: boolean;
}
