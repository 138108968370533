import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromMasterPage from '../../reducers/root.reducer';
import * as appActions from '../../actions/app.actions';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent {
  public static group = 'MASTER_PAGE.USER_DRAWER.SETTINGS';
  public static title = 'MASTER_PAGE.USER_DRAWER.THEME';

  themes = [
    { label: 'MASTER_PAGE.USER_DRAWER.THEME_DARK', value: 'dark' },
    { label: 'MASTER_PAGE.USER_DRAWER.THEME_LIGHT', value: 'light' }
  ];

  currentTheme$ = this.store.pipe(
    select(fromMasterPage.getUserPreferences),
    map(preferences => (preferences ? preferences.theme : void 0))
  );

  constructor(private readonly store: Store<any>) {}

  setTheme(theme: string) {
    this.store.dispatch(appActions.setUserPreferences({ userPreferences: { theme } }));
  }
}
