import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { API_VERSION, WebFrameworkService } from '@webframework/client-core';
import { Observable, Subject } from 'rxjs';

interface SelectedConfig {
  path?: string;
  data?: unknown;
}

@Injectable()
export class ConfigEditorService {
  sendResultsToJsonEditor = new Subject<SelectedConfig>();

  constructor(
    @Inject(API_VERSION) public apiVersion: string,
    private readonly wfService: WebFrameworkService,
    private readonly http: HttpClient
  ) {}

  /**
   * @method hasPermission
   * @description return true if user is admin
   **/
  hasPermission(): Observable<boolean> {
    return this.wfService.getCurrentUser().pipe(
      map(user => user.roles.includes('dsis-admins'))
    );
  }

  /**
   * @method getCollections
   * @description get the DSIS configs
   **/
  getCollections<T>(path = ''): Observable<T> {
    return this.wfService.getCurrentUser().pipe(
      switchMap(user => this.http.get<T>(`${this.apiVersion}/applications/${user.clientName}/collections` + path))
    );
  }

  /**
   * @method sendConfigurations
   * @description save the DSIS configs
   **/
  sendConfigurations(data: unknown, path: string): Observable<unknown> {
    return this.wfService.getCurrentUser().pipe(
      switchMap(user => this.http.post(`${this.apiVersion}/applications/${user.clientName}/collections` + path, data))
    );
  }

  /**
   * @method sendResultToJsonEditor
   * @description make the subject as observable
   **/
  sendResultToJsonEditor(): Observable<SelectedConfig> {
    return this.sendResultsToJsonEditor.asObservable();
  }
}
