import { DataModelInt } from './dataservice.model-int';

export class DataModel {
  public application = '';
  public dataSource = '';
  public project = 'ALL_PROJECTS';
  public entity = '';
  public properties: string[] = [];
  public filter: Filter[] = [];
  public orderBy: OrderBy[] = [];
  public top = '';
  public skip = '';
  public count = false;
  public format = 'json';
  public inlineCount = false;
}

export class ResultModel implements DataModelInt {
  public application = '';
  public dataSource = '';
  public project = 'ALL_PROJECTS';
  public entity = '';
  public properties: string[] = [];
  public filter = '';
  public orderBy = '';
  public top = '';
  public skip = '';
  public count = false;
  public format = 'json';
  public inlineCount = false;
}

export abstract class Filter {
  filterName = '';
  operator = '';
  type ?= '';
  startDate ?= '';
  endDate ?= '';
  filterValue: string[] = [];
}

export abstract class OrderBy {
  column = '';
  order = '';
}
