import { createAction, props } from '@ngrx/store';
import { BrandConfig, UserPreferences, User } from '@webframework/client-core';

export const loadUser = createAction('[Master Page] Load User');
export const loadUserSuccess = createAction('[Master Page] Load User Success', props<{ user: User }>());
export const loadUserFail = createAction('[Master Page] Load User Success', props<{ error: string }>());

export const loadBrandingConfig = createAction('[Master Page] Load Branding Config');
export const loadBrandingConfigSuccess = createAction(
  '[Master Page] Load Branding Config Success',
  props<{ brandConfig: BrandConfig }>()
);
export const loadBrandingConfigFail = createAction('[Master Page] Load Branding Config Fail', props<{ error: any }>());

export const setUserPreferences = createAction('[Master Page] Set User Preferences', props<{ userPreferences: Partial<UserPreferences> }>());
export const setUserPreferencesSuccess = createAction('[Master Page] Set User Preferences Success', props<{ userPreferences: UserPreferences }>());
export const setUserPreferencesFail = createAction('[Master Page] Set User Preferences Failed', props<{ error: any }>());

export const updateBadge = createAction('[Header] Update Badge', props<{ id: string; payload: number }>());
