import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { breadcrumbActions } from '../actions/breadcrumb';

export interface SidenavItem {
  menuname?: string;
  menuurl?: string;
  id?: string;
}

@Injectable()
export class BreadcrumbEffects {
  @Effect()
  loadBreadcrumb$: Observable<Action> = this.action$.pipe(
    ofType(breadcrumbActions.LOAD_BREADCRUMB),
    map((action: breadcrumbActions.LoadBreadcrumb) => new breadcrumbActions.LoadBreadcrumbSuccess(action.items)),
    catchError(error => of(error))
  );

  @Effect()
  sidenavClick$: Observable<Action> = this.action$.pipe(
    ofType('[Sidenav Item] Click'),
    filter((action: any) => action.payload.type === 'navigation'),
    switchMap(action => this.translateService.get(action.payload.menuname).pipe(
      map(text => ({ ...action.payload, label: text })))),
    map((item: any) => {

      return new breadcrumbActions.AddBreadcrumbFromSidenav({
        id: item.id,
        label: item.label,
        routerLink: item.menuurl,
        command: event => {
          this.store.dispatch(
            new breadcrumbActions.RemoveRest(event.item)
          );
        }
      });
    }),
    catchError(error => of(error))
  );

  constructor(
    private readonly action$: Actions,
    private readonly translateService: TranslateService,
    private readonly store: Store<any>
  ) { }
}
