import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
// import { ApplicationServicesModule } from '@webframework/application-services';
import {
  AccessManagementServiceManager,
  APPLICATION_VERSION,
  AuthenticationService,
  AuthInterceptor,
  CoreModule,
  CustomRouterStateSerializer,
  NewrelicInteractionService,
  RouterEffects,
  translateConfig,
} from '@webframework/client-core';
import { LayoutModule } from '@webframework/client-layout';
import { HomeGuard, MasterPageModule } from '@webframework/client-master-page';
import { IconModule, SharedModule } from '@webframework/client-shared';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

import { environment } from '../environments/environment';
import { ApplicationComponent } from './app.component';
import { APPLICATION_ROUTES } from './app.router';

import { BreadcrumbEffects } from './effects/breadcrumb';
import { WellTreeEffects } from './effects/welltree';

import { reducers } from './reducers';
import { APP_VERSION } from './version';
import { CredentialsModule  } from '@webframework/set-credentials-dialog';
import { ConfigEditorModule } from '@webframework/config-editor';
import { ServiceWorkerModule } from '@angular/service-worker';

import {ProgressBarModule} from "primeng/progressbar"
import { SidebarModule } from 'primeng/sidebar';

import { SharedModule as AppSharedModule } from '../../../../libs/shared/src/lib/shared.module';
import { UserDrawerContractViewerModule } from '../../../../libs/user-drawer-contract-viewer/src/lib/user-drawer-contract-viewer.module';



@NgModule({
  declarations: [ApplicationComponent],
  imports: [
    APPLICATION_ROUTES,
    // BreadcrumbModule,
    // ng modules
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ProgressBarModule,

    // webframework modules
    CoreModule.forRoot(),
    IconModule.forRoot(),
    CredentialsModule.forRoot(),
    ConfigEditorModule.forRoot(),
    LayoutModule,
    MasterPageModule,
    SharedModule,
    // ApplicationServicesModule,
    // primeng modules
    ToastModule,
    ButtonModule,
    SidebarModule,
    AppSharedModule,
    UserDrawerContractViewerModule,

    // ngx-translate modules
    TranslateModule.forRoot(translateConfig),

    // ngrx modules
    EffectsModule.forRoot([RouterEffects, BreadcrumbEffects]),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    HomeGuard,
    { provide: APPLICATION_VERSION, useValue: APP_VERSION.full },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: AuthenticationService, useClass: AuthenticationService },
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: NewrelicInteractionService, useClass: NewrelicInteractionService},
     { provide: AccessManagementServiceManager, useClass: AccessManagementServiceManager },
  ],
  bootstrap: [ApplicationComponent]
})
export class AppModule { }
