import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
/**
 * Factory for ToastMessageService
 */
export class ToastMessageServiceFactory {

  constructor(private readonly _messageService: MessageService) { }

  /**
   * Creates ToastMessageService instance with translationID
   * @param translationID: string
   * @returns ToastMessageService
   */
  public create(translationID: string): ToastMessageService {
    return new ToastMessageService(this._messageService, translationID);
  }
}

/**
 * Service for primeng toast messages with translation support
 */
export class ToastMessageService {

  constructor(private readonly _messageService: MessageService,
    private readonly _translationID: string) { }

  /**
   * Displays info toast with messageID
   * @param messageID
   * @param data optional record for translation params
   */
  public info(messageID: string, data?: Record<string, string>): void {
    this.message('info', messageID, data);
  }

  /**
   * Displays warning toast with messageID
   * @param messageID
   * @param data optional record for translation params
   */
  public warn(messageID: string, data?: Record<string, string>): void {
    this.message('warn', messageID, data);
  }

  /**
   * Displays error toast with messageID
   * @param messageID
   * @param data optional record for translation params
   */
  public error(messageID: string, data?: Record<string, string>): void {
    this.message('error', messageID, data);
  }

  /**
   * Displays success toast with messageID
   * @param messageID
   * @param data optional record for translation params
   */
  public success(messageID: string, data?: Record<string, string>): void {
    this.message('success', messageID, data);
  }

  private message(severity: string, messageID: string, data?: Record<string, string>): void {
    this._messageService.add({
      severity: severity,
      sticky: severity === 'error' || severity === 'warn',
      summary: `${this._translationID}.${severity.toUpperCase()}.${messageID}.SUMMARY`,
      detail: `${this._translationID}.${severity.toUpperCase()}.${messageID}.DETAIL`,
      data
    });
  }
}
