import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../models/user';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SessionService {
  public onReady$: Observable<User>;

  private readonly _user?: User;

  /**
   * @deprecated Use onReady$
   */
  public get user(): User {
    if (!this._user) {
      throw Error('User not queried yet!');
    }

    return this._user;
  }

  /**
   * @deprecated This would be removed in next release. Use onReady$
   */
  public get isReady(): boolean {
    return !!this._user;
  }

  constructor(
    private readonly authService: AuthenticationService,
  ) {
    this.onReady$ = this.getCurrentUser();
  }

  public getCurrentUser(): Observable<User> {
    return this.authService.getUser();
  }
}
