import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@webframework/client-core';
import { IconModule, SharedModule, SidenavModule, UserDrawerModule } from '@webframework/client-shared';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { AppEffects } from './effects/app.effects';
import { SidenavItemEffects } from './effects/sidenav-item.effects';
import { SidenavEffects } from './effects/sidenav.effects';
import { MasterPageComponent } from './master-page/master-page.component';
import { reducers } from './reducers/root.reducer';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { WIDGETS_PROVIDER, WidgetsModule } from './widgets/widgets.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ButtonModule,
    CommonModule,
    CoreModule,
    EffectsModule.forFeature([AppEffects, SidenavEffects, SidenavItemEffects]),
    IconModule,
    ToastModule,
    MatSlideToggleModule,
    SharedModule,
    SidenavModule,
    StoreModule.forFeature('master-page', reducers),
    TranslateModule,
    UserDrawerModule,
    WidgetsModule,
  ],
  providers: [...WIDGETS_PROVIDER, MessageService],
  declarations: [MasterPageComponent, SplashScreenComponent],
  exports: [MasterPageComponent],
})
export class MasterPageModule { }
