<div class="w-100" style="height: calc(100vh - 250px); overflow: auto">
  <button
    style="width: 100%"
    pButton
    type="button"
    icon="pi-cloud-download"
    label="Actualizar mis contratos"
    (click)="reloadContracts()"
  ></button>

  <p-card
    *ngFor="let contract of contracts"
    [style]="{ marginBottom: '0.8rem' }"
  >
    <ng-container *ngIf="contract.contract">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-3">{{ contract.contract.vendor.vendor_name }}</h3>
      </div>
      <p class="mb-0"><b>Título:</b> {{ contract.contract.agreementname }}</p>
      <p class="mb-0"><b>NIT:</b> {{ contract.contract.stcd1 }}</p>
      <p class="mb-0">
        <b>No. Contrato ECC:</b> {{ contract.contract.agreementnoecc }}
      </p>
    </ng-container>

    <ng-container *ngIf="!contract.contract">
      <div class="d-flex justify-content-between align-items-center">
        <h3>Contrato No: {{ contract.agreementnoecc }}</h3>
        <span style="width: 30%;" class="text-danger"
          ><i class="pi pi-exclamation-triangle mr-2"></i>Contrato Añadido
          Manualmente</span
        >
      </div>
    </ng-container>
  </p-card>
</div>
