import { Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ItemInterface } from '../../model/item-interface';

@Component({
  selector: 'wfc-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class ContextMenuComponent {
  @Input() butIconClass = '';
  @Input() appendTo: HTMLDivElement;
  @Input() height = 'auto';
  @Input() width = '200px';
  @Input() left = 'auto';
  @Input() right = 'auto';
  @Input() textAlign = 'left';
  @Input() top = 'auto';
  @Input() float = 'right';
  @Input() menuHeadingText = '';
  @Input() displayMenuTextArrow = false;
  @Input() minWidth = '200px';
  @Input() lineHeight = '12px';
  @Input() renderMenuIconAsImage = false;
  @Input() iconAsImageMargin = '0  0 -5px 0';
  @Input() iconAsImageHeight = '20px';
  @Input() iconAsImageWidth = '20px';
  @Input() iconAsImageElementBorderRadius = '10px';
  @Input() iconAsImageSpanQuerySelectorPrefix = 'wfc-context-menu-icon';
  @ViewChild('menu', { static: false }) contextMenu?: Menu;
  @ViewChild('defaultMenuContainer', { static: false }) defaultMenuContainer?: ElementRef;

  isMenuToggleStateShow = false;
  menuHostEl: EventTarget;
  menuItems$: Observable<MenuItem[]>;
  @Input() set hideMenu(item: boolean) {
    if (this.contextMenu && item) {
      this.contextMenu.hide();
    }
  }
  private readonly _menuSubject = new BehaviorSubject<MenuItem[]>([]);

  constructor(private readonly renderer: Renderer2, el: ElementRef, translate: TranslateService) {
    // eslint-disable-next-line max-len -- multiple eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- ElementRef<any> type of el
    this.menuHostEl = el.nativeElement;
    this.menuItems$ = this._menuSubject.pipe(
      switchMap(items => {
        const labels = items.map(i => i.label).filter(l => l);

        return (labels.length > 0)
          ? translate.stream(labels).pipe(
            map((tx: MenuItem[]) => items.map(i => ({
              ...i,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              label: tx[i.label]
            }))))
          : of(items);
      })
    );
  }

  @Input()
  set items(items: MenuItem[]) {
    this._menuSubject.next(items);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  toggle(e: Event): void {
    if (this.appendTo && typeof this.appendTo === 'string' && this.appendTo !== '') {
      // eslint-disable-next-line max-len -- multiple eslint disable
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-template-expressions
      this.appendTo = this.renderer.selectRootElement(`#${this.appendTo}`);
    } else {
      if (typeof this.appendTo === 'undefined' && this.defaultMenuContainer) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.appendTo = this.defaultMenuContainer.nativeElement;
      }
    }
    if (this.appendTo && this.appendTo.style) {
      this.appendTo.style.minWidth = this.minWidth;
      this.appendTo.style.width = this.width;
      this.appendTo.style.height = this.height;
      this.appendTo.style.top = this.top;
      this.appendTo.style.left = this.left;
      this.appendTo.style.right = this.right;
      this.appendTo.style.float = this.float;
      this.appendTo.style.lineHeight = this.lineHeight;
    }
    this.menuHostEl = e.target;
    if (this.contextMenu) {
      this.isMenuToggleStateShow = true;
      this.contextMenu.toggle(e);
      e.preventDefault();
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('document:click', ['$event']) onDocumentClick(e: Event): void {
    if (this.contextMenu) {
      if (this.isMenuToggleStateShow && this.menuHostEl !== e.target) {
        this.isMenuToggleStateShow = false;
        this.contextMenu.hide();
      } else {
        if (this.contextMenu.container && this.contextMenu.container.style) {
          this.contextMenu.container.style.textAlign = this.textAlign;
          this.contextMenu.container.style.width = this.width;
          this.contextMenu.container.style.height = this.height;
          this.contextMenu.container.style.top = this.top;
          this.contextMenu.container.style.left = this.left;
          this.contextMenu.container.style.right = this.right;
          this.contextMenu.container.style.minWidth = this.minWidth;
          this.contextMenu.container.style.lineHeight = this.lineHeight;
          this.menuIconAsImageRenderer();
        }
      }
    }
  }

  menuIconAsImageRenderer(): void {
    if (this.renderMenuIconAsImage) {
      this._menuSubject.value.forEach((item: ItemInterface) => {
        if (this.contextMenu && this.contextMenu.container && item.id) {
          const itemId = item.id ? item.id.replace(' ', '-') : item.id;
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- selectRootElement(): any from angular core
          const span =
            this.renderer.selectRootElement(`.${this.iconAsImageSpanQuerySelectorPrefix}-${itemId}`);
          if (span) {
            const image = this.buildIconImage(item);
            this.renderer.appendChild(span, image);
          }
        }
      });
    }
  }

  buildIconImage(item: ItemInterface): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- createElement() is any from angular core
    const image = this.renderer.createElement('IMG');
    // eslint-disable-next-line max-len -- multiple eslint disable
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- image is any as createElement() is any
    image.setAttribute('src', item.iconAsImageUrl);
    if (item.iconAsImageStyleBorderRadius) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access -- image is any as createElement() is any */
      image.style.borderRadius = item.iconAsImageStyleBorderRadius;
    } else {
      image.style.borderRadius = this.iconAsImageElementBorderRadius;
    }

    image.style.height = this.iconAsImageHeight;
    image.style.width = this.iconAsImageWidth;
    image.style.margin = this.iconAsImageMargin;
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */

    return image;
  }

}
