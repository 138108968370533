export class WfcVersion {
  public readonly major: string;
  public readonly minor: string;
  public readonly patch: string;
  constructor(public full: string) {
    this.major = full.split('.')[0];
    this.minor = full.split('.')[1];
    this.patch = full.split('.').slice(2).join('.');
  }
}
export const WFC_VERSION = new WfcVersion('6.16.1');
