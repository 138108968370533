import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface BroadcastEvent {
  key: string;
  data?: unknown;
}

export class BroadcastService {
  private readonly _eventBus: Subject<BroadcastEvent>;

  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: string , data?: unknown): void {
    this._eventBus.next({ key, data });
  }

  on(key: string): Observable<unknown> {
    return this._eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data)
    );
  }
}
