import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class CurrentUserInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthenticationService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- type inherited from angular
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.toLocaleLowerCase().localeCompare('/currentuser')) {
      return this.authService.getUser().pipe(switchMap(user => of(new HttpResponse({ status: 200, body: user }))));
    }

    return next.handle(req);
  }
}
