import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthenticationService) {}

  canActivate(): Observable<boolean> {
    return this.authService.initializeAuthentication().pipe(map(u => !!u));
  }
}
