/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, Subject, throwError, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { API_VERSION } from '@webframework/client-core';

import {
  CredentialServiceApiModal,
  CredentialServiceModal
} from './../models/credentials.interface';
import {
  SaveCredentialsPayload,
  CredentialsError,
  GetCredentialsResponse
} from '../models/credentials.interface';

const individualAuthenticationType = 'Explicit – individual';

@Injectable()
export class CredentialsService {
  public saveCredResponseSubject = new Subject<any>();
  public servicesWithoutCred: string[] = [];
  public appRowData: any;
  public apiVersion: string;

  private readonly landmarkSSServiceURL: string = '/api/securestore';
  constructor(@Inject(API_VERSION) apiVersion: string,
    private readonly http: HttpClient) {

    this.apiVersion = apiVersion || '';
    if (apiVersion !== '') {
      this.landmarkSSServiceURL = '${this.apiVersion}/api/securestore';
    }
  }

  /**
   * @method saveCredetials
   * @description this method is used to set the credentials for the service
   * @param payload - SaveCredentialsPayload
   */
  saveCredetials(appName: string, serviceName: string, payload: SaveCredentialsPayload): Observable<any> {
    return this.http
      .post(`${this.landmarkSSServiceURL}/apps/${appName}/services/${serviceName}`, payload)
      .pipe(catchError((err: Response) => throwError(err)));
  }

  /**
   * @method checkExplicitIndividual
   * @description function to get the service and check whether service is explicit individual or explicit group
   * @param response:{any} response from getProjects method
   */
  checkExplicitIndividual(service: string = ''): Observable<any> {
    return this.http.get(`${this.apiVersion}/api/services/${service}`);
  }

  /**
   * @method getAllServices
   * @description function to get the service
   * @param value: boolean
   */
  getAllServices(value: boolean): Observable<CredentialServiceModal[]> {
    return this.http
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .get<CredentialServiceApiModal[]>(`${this.apiVersion}/serviceregistry/eiservices/${value}`)
      .pipe(map(response => this.transformServices(response)));
  }

  /**
   * @method getAppServices
   * @description function to get the application specific service
   * @param value: boolean
   */
  getAppServices(appName: string): Observable<CredentialServiceModal[]> {
    return this.http
      .get<CredentialServiceApiModal[]>(`${this.apiVersion}/serviceregistry/eiservices/false/${appName}`)
      .pipe(map(response => this.transformServices(response)));
  }


  /**
   * @method transformServices
   * @description this method is used to transform
   * @param data - data source which is explicit-individual
   */
  transformServices(
    data: CredentialServiceApiModal[]
  ): CredentialServiceModal[] {
    const services: CredentialServiceModal[] = [];
    for (let i = 0, len = data.length; i < len; i++) {
      const res = data[i];
      for (let j = 0, datlength = res.data.length; j < datlength; j++) {
        const obj = this.createDsisObject();
        obj.Application = data[i].appName;
        obj.ServiceName = res.data[j].ServiceName;
        obj.ServiceType = res.data[j].ServiceType || '';
        obj.UserName = res.data[j].Username;
        obj.Password = res.data[j].Credentials;
        obj.Connectionurl = res.data[j].ConnectionURL || '';
        services.push(obj);
      }
    }

    return services;
  }

  /**
   * @method getCredentials
   * @description this method is used to fetch the saved credentials for the service
   * @param appName - ApplicationName
   * @param serviceName - ServiceName
   */
  getCredentials(
    appName: string, serviceName: string
  ): Observable<GetCredentialsResponse> {
    return this.http
      .get<GetCredentialsResponse>(
      `${this.landmarkSSServiceURL}/apps/${appName}/services/${serviceName}`
    )
      .pipe(
        map(response => {
          response.serviceName = serviceName;

          return response;
        })
      );
  }

  /**
   * @method updateServicesWithoutCred
   * @description function to validate whether services are explicit individual
   * and update the servicesWithoutCred accordingly
   * @param response:{any} response from getProjects method
   */
  updateServicesWithoutCred(errorResponses: CredentialsError[]): void {
    const servicesExplicitObservables: Observable<any>[] = [];
    for (
      let k = 0, errorResponsesLength = errorResponses.length;
      k < errorResponsesLength;
      k++
    ) {
      servicesExplicitObservables.push(
        this.checkExplicitIndividual(errorResponses[k].serviceName)
      );
    }
    if (servicesExplicitObservables.length) {
      forkJoin(servicesExplicitObservables).subscribe((serviceNames: any) => {
        for (let l = 0, len = serviceNames.length; l < len; l++) {
          if (
            serviceNames[l].AuthenticationType.toLowerCase() ===
              individualAuthenticationType.toLowerCase() &&
            this.servicesWithoutCred.indexOf(serviceNames[l].ServiceName) === -1
          ) {
            this.servicesWithoutCred.push(serviceNames[l].ServiceName);
          }
        }
      });
    }
  }
  /**
   * @method isError
   * @description function to validate whether the response is an error or not
   * @param response:{any} response from getProjects method
   */
  isError(response: any): response is CredentialsError {
    return 'stacktrace' in response;
  }

  /**
   * Error handler
   * @param error - response error
   */
  public handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      errMsg = error.status
        .toString()
        .concat(
          ' ',
          error.statusText,
          ': ',
          error.message.toString().replace(/[\n\r]/g, '')
        );
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(errMsg);
  }

  /**
   * @method createDsisObject
   * @description method to create a new dsisObject
   */
  private createDsisObject(): CredentialServiceModal {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      Application: '',
      Connectionurl: '',
      Password: '',
      ServiceName: '',
      ServiceType: '',
      UserName: ''
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
