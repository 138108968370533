import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as sessionActions from '../actions/session.actions';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class SessionEffects {
  logout$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(sessionActions.logOut),
        tap(() => this.authService.logout())
      ),
    { dispatch: false }
  );

  constructor(private readonly action$: Actions, private readonly authService: AuthenticationService) {}
}
