import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_VERSION } from '@webframework/client-core';

@Injectable()
export class AppdropdownserviceService {
  constructor(private readonly http: HttpClient, @Inject(API_VERSION) public apiVersion?: string) {}

  getAllApplications(): Observable<any> {
    return this.http.get<any>(
      `${this.apiVersion}/applications/runtimeenvironment/` +
      'collections/applicationsConfigurations/configurations/appConfig1',
    );
  }
}
