import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { IconModule } from '@webframework/client-shared';
import { NotificationModule, NotificationService } from '@webframework/notification';

import { GenericPipe } from './pipes/generic.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SelectBoxComponent } from './components/select-box/select-box.component';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { GridComponent } from '../src/components/grid.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    PaginatorModule,
    ContextMenuModule,
    MenuModule,
    ButtonModule,
    OverlayPanelModule,
    DialogModule,
    NotificationModule,
    TranslateModule,
    RadioButtonModule,
    CheckboxModule,
    IconModule,
    MultiSelectModule,
    InputTextModule,
    TooltipModule
  ],
  declarations: [GridComponent, SelectBoxComponent, ColumnFilterComponent, GenericPipe, FilterPipe],
  entryComponents: [GridComponent, SelectBoxComponent, ColumnFilterComponent],
  exports: [GridComponent, SelectBoxComponent, ColumnFilterComponent, GenericPipe, FilterPipe],
  providers: [NotificationService]
})
export class GridModule {
  public static entry = GridComponent;
}
