import { Action } from '@ngrx/store';


interface WellTreeActionPayload{
  id:string;
}
export namespace WellTreeActions {
  export const WELL_SELECTED = '[WellTree] WellSelected';
  export const EVENT_SELECTED = '[WellTree] EventSelected';

  export class WellSelected implements Action {
    readonly type = WELL_SELECTED;
    constructor(public payload: WellTreeActionPayload) { }
  }

  export class EventSelected implements Action {
    readonly type = EVENT_SELECTED;
    constructor(public payload: WellTreeActionPayload) { }
  }

  export type Actions = WellSelected | EventSelected;
}
