import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import * as fromMasterPage from '../reducers/root.reducer';

@Injectable()
/**
 * Activates the requeste path whenever app is ready for rendering
 */
export class ReadyGuard implements CanActivate {
  constructor(private readonly _store: Store<fromMasterPage.MasterPageState>) {}

  canActivate() {
    return this._store.pipe(
      select(fromMasterPage.selectAppReady),
      filter((ready) => ready)
    );
  }
}
