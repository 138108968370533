import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  InjectionToken,
  Injector,
  Input,
  NgModuleFactory,
  OnDestroy,
  Output,
  SystemJsNgModuleLoader,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  COMPONENT_OPTIONS,
  ResizeObserver as CustomResizeObserver,
  ResizeObserverEntry
} from '@webframework/client-shared';

import { HostPanel } from '../models/host-panel';

export const PANEL_CONFIG: InjectionToken<{}> = COMPONENT_OPTIONS;

interface VersionedModuleType extends Type<unknown> {
  entry: Type<unknown> & { version: string };
}

declare let window: {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- global constructor
  ResizeObserver: Type<CustomResizeObserver>;
};

@Component({
  selector: 'wfc-panel-host',
  templateUrl: './panel-host.component.html',
  styleUrls: ['./panel-host.component.scss']
})
export class PanelHostComponent implements AfterViewInit, OnDestroy {

  @HostBinding('style.max-width')
  maxWidth?: string;

  @HostBinding('style.grid-template-rows')
  get gridTemplateRows(): string {
    return this.panel && this.panel.config.hasHeader ? 'auto 1fr' : '';
  }

  @ViewChild('panelContainer', { static: false })
  panelContainerElementRef?: ElementRef;
  @ViewChild('anchorContainer', { read: ViewContainerRef, static: false })
  anchorContainerRef?: ViewContainerRef;

  @Input()
  set panel(value: HostPanel | undefined) {
    if (!value) {
      throw new Error('Panel has to be defined');
    }
    this._panel = value;
    if (value.config.maxWidth) {
      this.maxWidth = value.config.maxWidth;
      setTimeout(() => {
        this.changeWidth.emit(value.config.maxWidth);
      }, 0);
    }
  }

  get panel(): HostPanel | undefined {
    return this._panel;
  }

  @Output()
  maximize = new EventEmitter<HostPanel>();
  @Output()
  collapse = new EventEmitter<HostPanel>();
  @Output()
  restore = new EventEmitter<HostPanel>();
  @Output()
  changeWidth: EventEmitter<string> = new EventEmitter<string>();

  public error?: string;

  height!: string;
  width!: string;
  private _panel?: HostPanel;
  private componentRef?: ComponentRef<unknown>;

  constructor(private readonly moduleLoader: SystemJsNgModuleLoader) { }

  ngAfterViewInit(): void {
    if (!this.panel) {
      return;
    }
    // Insert the template into the view...
    // but wait a tick first to avoid one-time devMode
    // unidirectional-data-flow-violation error
    setTimeout(() => {
      this.createComponentFromConfig();
    }, 0);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  private createComponentFromConfig() {
    this.moduleLoader
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .load(this.panel!.config.module)
      .then((moduleFactory: NgModuleFactory<unknown>) => {
        const entryComponent = (moduleFactory.moduleType as VersionedModuleType).entry;

        if (!entryComponent) {
          throw new Error(`Static property "entry" not found in ${moduleFactory.moduleType.name}`);
        }

        if (!this.anchorContainerRef) {
          throw new Error('Anchor container not found for panel');
        }

        const componentVersion = entryComponent.version;

        const injector = Injector.create({
          providers: [
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            { provide: PANEL_CONFIG, useValue: { ...this.panel!.config.config, id: this.panel!.id } }
          ],
          parent: this.anchorContainerRef.injector
        });
        const ngModuleRef = moduleFactory.create(injector);
        const compFactory = ngModuleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
        this.componentRef = this.anchorContainerRef.createComponent(compFactory, 0, ngModuleRef.injector);

        if (componentVersion) {
          (this.componentRef.location.nativeElement as HTMLElement).setAttribute('wfc-version', componentVersion);
        }
        this.setupExplicitResize();
      })
      .catch((error: string) => {
        this.error = error;
        console.error(error);
      });
  }

  // tslint:disable-next-line: cognitive-complexity
  private setupExplicitResize() {
    if (!this.panel || !this.componentRef || !this.panelContainerElementRef) {
      throw new Error('Panel host not initialized yet');
    }

    const { scroll, resizeStrategy } = this.panel.config;
    if (!scroll && resizeStrategy !== 'explicit') {
      return;
    }

    const element = this.componentRef.location.nativeElement as HTMLElement;
    const hostElement = this.panelContainerElementRef.nativeElement as HTMLElement;

    const observer: CustomResizeObserver =
      new (window.ResizeObserver || CustomResizeObserver)(
        (entries: ResizeObserverEntry[]) => {
          for (const entry of entries) {
            const { width, height } = entry.contentRect;
            if (scroll) {
              // Add 1rem equivalent px to actual width/height to fill the gap between scrollbar and panel edge
              const remTopx = 1 * parseFloat(getComputedStyle(hostElement).fontSize || '14px');
              this.height = `${height + remTopx}px`;
              this.width = `${width + remTopx}px`;
            }
            if (resizeStrategy === 'explicit') {
              element.style.height = `${height}px`;
              element.style.width = `${width}px`;
            }
          }
        }
      );

    observer.observe(hostElement);
  }
}
