import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromMasterPage from '../../reducers/root.reducer';
import * as appActions from '../../actions/app.actions';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './mouse-scroll-direction.component.html',
})
export class MouseScrollDirectionComponent {
  public static group = 'MASTER_PAGE.USER_DRAWER.SETTINGS';
  public static title = 'MASTER_PAGE.USER_DRAWER.MOUSESCROLLDIRECTION';

  currentScrollDirection$ = this.store.pipe(
    select(fromMasterPage.getUserPreferences),
    map(userPreferences => userPreferences.reverseMouseScrollDirection)
  );

  constructor(private readonly store: Store<any>) {}

  setDirection(checked: boolean) {
    this.store.dispatch(appActions.setUserPreferences({ userPreferences: { reverseMouseScrollDirection: checked } }));
  }
}
