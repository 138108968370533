/**
 * User Model
 */
export interface DataModelInt {
  /**
   * Application Name
   */
  application: string;

  /**
   * Datasource Name
   */
  dataSource: string;

  /**
   * Project Name
   */
  project: string;

  /**
   * Entity Name
   */
  entity: string;

  /**
   * Properties
   */
  properties: string[];
  /**
   * Filter
   */
  filter: string;

  /**
   * Order By
   */
  orderBy: string;

  /**
   * Top
   */
  top: string;

  /**
   * Skip
   */
  skip: string;

  /**
   * count
   */
  count: boolean;

  /**
   * Format
   */
  format: string;
}
