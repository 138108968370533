import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { Widget } from '../../widget';

@Component({
  selector: 'wfc-widget-host',
  templateUrl: './widget-host.component.html',
  styleUrls: ['./widget-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WidgetHostComponent implements OnInit {
  @Input() widget?: Widget;

  @ViewChild('widgetHost', { read: ViewContainerRef, static: true }) widgetHost!: ViewContainerRef;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.loadWidget();
  }

  private loadWidget() {
    if (!this.widget) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.widget);
    this.widgetHost.createComponent(componentFactory);
  }
}
