import { Component, EventEmitter, Input, Output } from '@angular/core';

import { HostPanel } from '../models/host-panel';

@Component({
  selector: 'wfc-panel-header',
  templateUrl: './panel-header.component.html',
  styleUrls: ['./panel-header.component.scss']
})
export class PanelHeaderComponent {
  @Input()
  panel?: HostPanel;

  @Output()
  maximize = new EventEmitter<HostPanel>();
  @Output()
  collapse = new EventEmitter<HostPanel>();
  @Output()
  restore = new EventEmitter<HostPanel>();
}
