import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigEditorService } from './services/config-editor.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ConfigEditorModule {
  static forRoot(): ModuleWithProviders<ConfigEditorModule> {
    return {
      ngModule: ConfigEditorModule,
      providers: [
        ConfigEditorService
      ]
    };
  }
}
