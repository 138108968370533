export interface User {
  clientName: string;
  exp: number;
  expiryInSeconds: number;
  prefUserName: string;
  roles: string[];
  clientRoles: string[];
  email: string;
  token: string;
  userId: string;
  userName: string;
  groups: Group[];
}

export interface Group {
  id: string;
  name: string;
  path: string;
}
