import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfig, HeaderItem, Locale } from '@webframework/client-core';

@Component({
  selector: 'wfc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './_theming.scss'],
})
export class HeaderComponent {
  @Input()
  logoPath?: string;

  @Input()
  userName?: string;

  @Input()
  appList?: AppConfig[];

  @Input()
  headerItems: HeaderItem[] = [];

  @Input()
  locales: Locale[] = [];

  @Output()
  toggleMenuVisibility = new EventEmitter();

  @Output()
  action = new EventEmitter();

  @Output()
  showUserDrawer = new EventEmitter();

  @Output()
  toggleSidenav = new EventEmitter();

  trackByHeaderItem(_index: number, item: HeaderItem): string {
    return item.id;
  }

}
