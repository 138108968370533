import { Component } from '@angular/core';


@Component({
  templateUrl: './set-credentials.widget.component.html',
  styleUrls: ['./set-credentials.widget.component.scss']

})
export class SetCredentialsWidgetComponent {
  public static group = 'SET_CREDENTIALS_DIALOG.SETTINGS';
  public static title = 'SET_CREDENTIALS_DIALOG.DIALOG_TITLE';
  isPopoverOpen = false;
}
