/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call -- due to the type of column*/
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { WFC_VERSION } from '../../version';
import { SelectItem } from 'primeng/api';
import { OnchangeEvent } from '../../model/onchange-event-interface';

@Component({
  selector: 'wfc-grid-column-filter',
  styleUrls: ['./column-filter.component.scss'],
  templateUrl: './column-filter.component.html'
})
export class ColumnFilterComponent implements OnInit {
  public static version = WFC_VERSION.full;
  @Input() column: any = null;
  @Input() inRequired = true;
  @Input() searchFilterText = '';
  @Output() onChange = new EventEmitter<OnchangeEvent>();
  @Output() toggle = new EventEmitter<any>();

  @ViewChild('colFilterPanel', { static: false }) overlayPanel: OverlayPanel;

  public columnFilters: SelectItem[] = [];
  public filterText = '';
  public selectedFilter = '';
  public isNumericFiltering = false;

  ngOnInit(): void {
    // If filtered by text, set text upon reinit
    if (this.column.activeFilter) {
      this.filterText = this.column.activeFilter;
    }

    if (this.column.columnType &&
      (this.column.columnType.toLowerCase() === 'int' ||
        this.column.columnType.toLowerCase() === 'real' || this.column.columnType === 'number')) {
      this.isNumericFiltering = true;
      this.columnFilters = [
        { label: '=', value: 'equals' },
        { label: '!=', value: 'notEquals' },
        { label: '<=', value: 'lte' },
        { label: '>=', value: 'gte' }
      ];
    } else {
      this.isNumericFiltering = false;
      this.columnFilters = [
        { label: 'GRID.FILTER.STARTS_WITH', value: 'startsWith', title: 'Starts With'},
        { label: 'GRID.FILTER.ENDS_WITH', value: 'endsWith', title: 'Ends With' },
        { label: 'GRID.FILTER.CONTAINS', value: 'contains', title: 'Contains' },
        { label: 'GRID.FILTER.EQUALS', value: 'equals', title: 'Equals' }
      ];
      if (this.inRequired) {
        const inFilter = { label: 'GRID.FILTER.IN', value: 'in', title: 'In' };
        this.columnFilters.push(inFilter);
      }
    }

    if (this.isNumericFiltering) {
      this.selectedFilter = 'equals';
    } else {
      this.selectedFilter = this.column.filterMatchMode ? this.column.filterMatchMode : 'startsWith';
    }
  }

  filterColumn(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onChange.emit({
        column: this.column.columnName,
        filter: this.selectedFilter,
        value: this.filterText
      });
    }
  }

  changeFilter(filter: string): void {
    this.selectedFilter = filter;
    this.onChange.emit({
      column: this.column.columnName,
      filter: this.selectedFilter,
      value: this.filterText
    });
  }

  toggleOverlayPanel(event: Event): void {
    this.toggle.emit(this);
    if (this.overlayPanel) {
      this.overlayPanel.toggle(event);
    }
  }

  hide(): void {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }
}
