import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import supportedBrowsersList from '../../../supportedBrowsers';

const browsers = require('../../../supportedBrowserslist.json');

function createStorage(name, value) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(name, value);
  }
}
function getStorage(cname) {
  return window.sessionStorage ? window.sessionStorage.getItem(cname) : '';
}

const supportedBrowsers = (browsers.browsers as string[])
  .map(browser => browser.split(' ')[0])
  .reduce((p, c) => p.includes(c) ? p : p.concat(c), [])
  .join(' or ');


if (!supportedBrowsersList.test(navigator.userAgent)) {
  const alertMsg = getStorage('BrowserAlertShown') || '';
  const userMsg = `You are using a web browser that isn’t supported.
  To get a better experience, please use ${supportedBrowsers} browser.`;
  if (alertMsg !== 'yes') {
    alert(userMsg);
    createStorage('BrowserAlertShown', 'yes');
  }

}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
