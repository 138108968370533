import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { KeycloakConfig, KeycloakInstance } from 'keycloak-js';
import { Observable, of, Subscriber, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { Group, User } from '../models/user';

export const SKIP_AUTH_HEADER = 'X-WF-SKIP-AUTH';
export const KEYCLOAK_FACTORY = new InjectionToken<(config: KeycloakConfig) => KeycloakInstance>(
  'KEYCLOAK_FACTORY'
);

interface TokenResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- Property from Keycloak
  refresh_token: string;
}

interface IKeycloak {
  resource: string;
  realm: string;
  'auth-server-url': string;
}

// eslint-disable-next-line no-undef
type Token = Keycloak.KeycloakInstance['tokenParsed'] & {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- intentional naming to avoid clashes
  preferred_username: string;
  name: string;
  email: string;
};

/** @dynamic */
@Injectable({
  providedIn: 'root',
})
/**
 * The service to connect to Keycloak server and perform all the authentication and
 * authorization related tasks.
 */
export class AuthenticationService {
  // eslint-disable-next-line no-undef
  private _keycloak?: Keycloak.KeycloakInstance;
  private readonly init$: Observable<User>;
  private _wFToken?: string; // = 'X-WF-TOKEN';
  private _wFRefreshToken?: string; // = 'X-WF-REFRESH-TOKEN';
  private _wfTimeSkew?: string; // = 'X-WF-TIME-SKEW';
  private _wfUser?: string; // = 'X-WF-USER';
  private _userGroups$?: Observable<Group[]>;

  constructor(
    private readonly _injector: Injector,
    private readonly _http: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.init$ = _http
      .get<IKeycloak>('keycloak.json', {
      headers: {
        [SKIP_AUTH_HEADER]: 'true',
      },
    })
      .pipe(
        switchMap((config) => this._initKeycloak(config)),
        shareReplay()
      );
  }

  /**
   * Verifies a token's validity. Resolves to a boolean indicating weather a token is valid or not.
   * @param token The token to verify
   */
  public verifyTokenValid(token: string, clientId?: string): Observable<boolean> {
    return this.getToken(
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property from Keycloak
        grant_type: 'refresh_token',
        // eslint-disable-next-line @typescript-eslint/naming-convention -- Property from Keycloak
        refresh_token: token,
      },
      clientId
    ).pipe(
      map(() => true),
      // Keycloak returns 400 if the token has expired
      // Return false if it has expired, otherwise re-throw the error
      catchError((e: HttpErrorResponse) => (e.status === 400 ? of(false) : throwError(e)))
    );
  }

  /**
   * Gets an offline token from Keycloak
   * @param password The password for the current user
   */
  public getOfflineToken(password: string, clientId?: string): Observable<string> {
    return this.init$.pipe(
      switchMap((user) =>
        this.getToken(
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention -- Property from Keycloak
            grant_type: 'password',
            username: user.prefUserName,
            password,
            scope: 'openid info offline_access',
          },
          clientId
        )
      ),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- Property from Keycloak
      map(({ refresh_token }) => refresh_token)
    );
  }

  /**
   * Logs out the user and ends the current session in Keycloak
   */
  public logout(): void {
    this.removeItemsFromSession(this._wFRefreshToken, this._wFToken, this._wfTimeSkew);

    if (this._keycloak) {
      const newUrl = this.clearPathnameAndSearchFromUrl(window.location.href);
      void this._keycloak.logout({
        redirectUri: newUrl,
      });
    }
  }

  /**
   * Gets the current user object
   */
  public getUser(): Observable<User> {
    return this.initializeAuthentication().pipe(
      switchMap(
        (user) =>
          new Observable((subs: Subscriber<User>) => {
            if (!navigator.onLine) {
              subs.next(user);
              subs.complete();
            } else {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is not null in this context
              this._keycloak!.updateToken(5)
                .then(() => {
                  // removing the state parameter from browser url
                  this.removeStateParameterFromURL();
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value not null in this context
                  user.token = this._keycloak!.token!;
                  this.addItemsToSession(user);

                  if (!this._userGroups$) {
                    this._userGroups$ = this._http
                      .get<Group[]>(`/api/securityManagement/groups?userName=${user.prefUserName}`, {
                      headers: {
                        [SKIP_AUTH_HEADER]: 'true',
                        // eslint-disable-next-line @typescript-eslint/naming-convention -- HTTP Header
                        Authorization: `Bearer ${user.token}`,
                      },
                    })
                      .pipe(shareReplay());
                  }

                  this._userGroups$.subscribe({
                    next: (groups) => subs.next({ ...user, groups }),
                    error: () => subs.next(user),
                    complete: () => subs.complete(),
                  });
                })
                .catch(() => {
                  window.location.reload();
                  subs.error();
                });
            }
          })
      )
    );
  }

  /**
   * Gets the current user object
   */
  public initializeAuthentication(): Observable<User> {
    return this.init$;
  }

  public getAuthServerUrl(): string {
    return this._keycloak?.authServerUrl || '';
  }

  private getToken(params: Record<string, string>, clientId?: string) {
    let formData = new HttpParams()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
      .set('client_id', clientId || this._keycloak!.clientId!);

    formData = Object.keys(params).reduce((fd, key) => fd.append(key, params[key]), formData);

    return this.init$.pipe(
      switchMap(() =>
        this._http.post<TokenResponse>(
          // eslint-disable-next-line max-len -- multiple disables for eslint
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/restrict-template-expressions  -- the value is not null in this context and the template expression is required
          `${this._keycloak!.authServerUrl}/realms/${this._keycloak!.realm}/protocol/openid-connect/token`,
          formData.toString(),
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- HTTP Header
              'Content-Type': 'application/x-www-form-urlencoded',
              [SKIP_AUTH_HEADER]: 'true',
            },
          }
        )
      )
    );
  }

  private clearPathnameAndSearchFromUrl(url: string): string {
    const newUrl = new URL(url);

    // if multi application scenario append application name to pathname
    if (
      this._keycloak &&
      this._keycloak.clientId &&
      newUrl.pathname &&
      newUrl.pathname.indexOf(this._keycloak.clientId) !== -1
    ) {
      newUrl.pathname = `/${this._keycloak.clientId}`;
    } else {
      newUrl.pathname = '/';
    }

    newUrl.search = '';

    return newUrl.toString();
  }

  private removeStateParameterFromURL() {
    const newUrl = new URL(window.location.href);
    if (newUrl.hash) {
      let pathName = '/';
      const indexOfPathName = newUrl.hash.lastIndexOf(pathName);
      const indexOfHash = newUrl.hash.lastIndexOf('#');

      if (indexOfPathName >= 0) {
        if (indexOfPathName < indexOfHash) {
          pathName = newUrl.hash.substring(indexOfPathName, indexOfHash);
        } else {
          pathName = newUrl.hash.substring(indexOfPathName);
        }
      }

      if (pathName !== '/') {
        newUrl.pathname = pathName;
      }

      newUrl.hash = '';
      window.history.replaceState(window.history.state, this.document.title, newUrl.toString());
    }
  }

  private removeItemsFromSession(...items: (string | undefined)[]) {
    items.forEach((item) => sessionStorage.removeItem(item || ''));
  }

  private addItemsToSession(user: User) {
    if (this._keycloak) {
      /* eslint-disable @typescript-eslint/no-non-null-assertion -- the value is not null in this context */
      sessionStorage.setItem(this._wFToken!, this._keycloak.token || '');
      sessionStorage.setItem(this._wFRefreshToken!, this._keycloak.refreshToken || '');
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands -- required
      sessionStorage.setItem(this._wfTimeSkew!, this._keycloak.timeSkew + '');
      sessionStorage.setItem(this._wfUser!, JSON.stringify(user));
      /* eslint-enable */
    }
  }

  private _initKeycloak(config: IKeycloak) {
    this._wFToken = `X-WF-${config.resource.toUpperCase()}-TOKEN`;
    this._wFRefreshToken = `X-WF-${config.resource.toUpperCase()}-REFRESH-TOKEN`;
    this._wfTimeSkew = `X-WF-${config.resource.toUpperCase()}-TIME-SKEW`;
    this._wfUser = `X-WF-${config.resource.toUpperCase()}-USER`;

    return new Observable((subs: Subscriber<User>) => {
      this._keycloak = this._injector.get(KEYCLOAK_FACTORY)({
        url: config['auth-server-url'],
        realm: config['realm'],
        clientId: config['resource'],
      });

      if (!navigator.onLine) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
        subs.next(JSON.parse(sessionStorage.getItem(this._wfUser!)!));
        subs.complete();
      } else {
        this._keycloak.onAuthRefreshError = () =>
          this.removeItemsFromSession(this._wFRefreshToken, this._wFToken, this._wfTimeSkew);

        this._keycloak
          .init({
            onLoad: 'login-required',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is not null in this context
            token: sessionStorage.getItem(this._wFToken!) || '',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is not null in this context
            refreshToken: sessionStorage.getItem(this._wFRefreshToken!) || '',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- value is not null in this context
            timeSkew: +(sessionStorage.getItem(this._wfTimeSkew!) || 0),
          })
          .then(() => {
            if (
              this._keycloak &&
              this._keycloak.clientId &&
              this._keycloak.token &&
              this._keycloak.tokenParsed &&
              this._keycloak.realmAccess &&
              this._keycloak.realmAccess.roles
            ) {
              const token = this._keycloak.tokenParsed as Token;
              const userId = token.sub || '';

              subs.next(this.composeUser(token, userId));
              subs.complete();
            } else {
              subs.error('Login failed');
            }
          })
          .catch(() => subs.error('Keycloak initialization failed!'));
      }
    });
  }

  private composeUser(token: Token, userId: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
    const clientId = this._keycloak!.clientId!;
    const user: User = {
      clientName: clientId,
      exp: 0,
      expiryInSeconds: 0,
      prefUserName: token.preferred_username,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
      roles: this._keycloak!.realmAccess!.roles,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
      clientRoles: (this._keycloak!.resourceAccess![clientId] !== undefined) ?
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
        this._keycloak!.resourceAccess![clientId].roles : [],
      email: token.email,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the value is not null in this context
      token: this._keycloak!.token!,
      userName: token.name,
      userId,
      groups: []
    };
    this.addItemsToSession(user);

    return user;
  }
}
