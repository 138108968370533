import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { IconModule } from '../icon/icon.module';
import { SharedModule } from '../shared.module';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    IconModule,
    OverlayPanelModule,
    TooltipModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  declarations: [SidenavItemComponent, SidenavComponent],
  exports: [SidenavComponent]
})
export class SidenavModule { }
