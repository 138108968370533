import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import {
  AppMenuItem,
  BroadcastActions,
  BroadcastMenuItem,
  CustomMenuItem,
  NavigationMenuItem,
  RouterActions,
} from '@webframework/client-core';
import { panelActions } from '@webframework/client-layout';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as sidenavItem from '../actions/sidenav-item.actions';
import * as sidenav from '../actions/sidenav.actions';
import * as masterPage from '../reducers/root.reducer';

@Injectable()
export class SidenavItemEffects {
  click$ = createEffect(() =>
    this.action$.pipe(
      ofType(sidenavItem.click),
      switchMap(({ payload }) => [sidenav.popover({ payload: null }), this.getActionByItem(payload)])
    )
  ); // Close popover on every click

  selectAfterNavigation$ = createEffect(() =>
    this.action$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigationAction) => action.payload.routerState.url),
      withLatestFrom(this.store$.pipe(select(masterPage.getAllSidenavItems))),
      filter(([, items]) => (items || []).length > 0),
      map(([url, items]) => {
        let currentItem: any = items.find((item: any) => (<NavigationMenuItem>item).menuurl === url || item.url === url);
        // check whether the selected item has any parent.
        currentItem = currentItem 
                      ? items.find((item: any) => (item.children || []).includes(currentItem.id)) || currentItem
                      : currentItem

        return sidenavItem.select({ payload: currentItem ? currentItem.id : null });
      })
    )
  );

  constructor(private readonly action$: Actions, private readonly store$: Store<masterPage.MasterPageState>) { }

  private getActionByItem(item: AppMenuItem): Action {
    switch (item.type) {
      case 'parent':
        return sidenavItem.toggle({ payload: item.id });
      case 'navigation':
        if (item.newWindow) {
          window.open((item as NavigationMenuItem).menuurl);
          break;
        } else {
          return RouterActions.go({
            payload: {
              path: [(item as NavigationMenuItem).menuurl],
              query: (item as NavigationMenuItem).queryParam,
            },
          });
        }
      case 'popover':
        return sidenav.popover({ payload: item.id });
      case 'broadcast':
        // tslint:disable-next-line:no-non-null-assertion
        return BroadcastActions.broadcast({
          key: (item as BroadcastMenuItem).menuEvent,
          data: (item as BroadcastMenuItem).eventData,
        });
      case 'custom':
        if ((item as CustomMenuItem).data === 'sidenav-child') {
          return panelActions.restore({ payload: item.id });
        }

        return sidenavItem.custom();
      default:
    }

    return sidenavItem.custom();
  }
}
