import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as fromBroadcast from '../actions/broadcast.actions';
import { BroadcastService } from '../services/broadcast.service';

@Injectable()
export class BroadcastEffects {
  broadcast$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromBroadcast.broadcast),
      tap(({ key, data }) => this.broadcastService.broadcast(key, data)),
      map(() => fromBroadcast.broadcastSuccess())
    )
  );

  constructor(private readonly action$: Actions, private readonly broadcastService: BroadcastService) {}
}
