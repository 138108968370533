import { Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren } from '@angular/core';

import { HostContainerComponent } from '../host-container/host-container.component';
import { HostPanel } from '../models/host-panel';
import { Panel } from '../models/panel';
import { SplitPanel } from '../models/split-panel';
import { minimized } from './split-host.component.animations';
import { DragDelta } from '../split-bar/split-bar.component';

@Component({
  selector: 'wfc-split-host',
  templateUrl: './split-host.component.html',
  styleUrls: ['./split-host.component.scss'],
  animations: [minimized]
})
export class SplitHostComponent {
  @ViewChildren(HostContainerComponent)
  containers?: QueryList<HostContainerComponent>;
  @Input()
  panel?: SplitPanel;

  @Output()
  maximize = new EventEmitter<HostPanel>();
  @Output()
  collapse = new EventEmitter<HostPanel>();
  @Output()
  restore = new EventEmitter<HostPanel>();
  @Output()
  ratioChange = new EventEmitter<Panel>();

  @HostBinding('style.flex-direction')
  public get flexDirection() {
    return this.panel && this.panel.orientation === 'horizontal' ? 'column' : 'row';
  }

  onSplitDragStart() {
    if (!this.containers) {
      return;
    }

    this.containers.forEach(c => {
      if (this.panel) {
        c.updateRatioFromView(this.panel.orientation);
      }
    });
  }

  onSplitDrag(index: number, event: DragDelta): void {
    if (!this.panel) {
      return;
    }

    const before: Panel = <any>this.panel.layouts[index];
    const after: Panel = <any>this.panel.layouts[index + 1];
    const delta = this.panel.orientation === 'vertical' ? event.dx : event.dy;
    before.ratio += delta;
    after.ratio -= delta;
  }

  isInnerCollapsed(innerPanel: Panel): boolean {
    return !!innerPanel.collapsed;
  }

  isLastVisibleChild(innerLayout: Panel): boolean {
    if (!this.panel) {
      return false;
    }

    const visibleLayouts = this.panel.layouts.filter(l => !(<any>l).collapsed);

    return <any>visibleLayouts[visibleLayouts.length - 1] === innerLayout;
  }

  public getInnerLayoutStyle(innerLayout: Panel): Record<string, string | number> | undefined {
    return innerLayout.style || { flex: innerLayout.ratio };
  }

  public trackById(_index: number, panel: Panel): string {
    return panel.id;
  }
}
