export interface AppMenuItem {
  disabled?: boolean;
  displayOrder: number;
  expanded?: boolean;
  flashIcon?: boolean; // ! remove when refactor
  hidden?: boolean;
  icon: string;
  id: string;
  menuname: string;
  newWindow?: boolean;
  notificationcount?: number;
  type: string;
  userGroups?: string[];
  userRoles?: string[];

  lastNotificationCount: number; // ! remove when refactor
}

export interface NavigationMenuItem extends AppMenuItem {
  type: 'navigation';
  menuurl: string;
  queryParam?: { [key: string]: string };
  url?: string;
}

export interface ParentMenuItem<TChildItem extends AppMenuItem | string = AppMenuItem> extends AppMenuItem {
  type: 'parent';
  children: TChildItem[];
}

export interface PopoverMenuItem extends AppMenuItem {
  type: 'popover';
  module: string;
}

export interface BroadcastMenuItem extends AppMenuItem {
  type: 'broadcast';
  module: string;
  menuEvent: string;
  eventData: unknown;
}

export interface SeparatorMenuItem extends AppMenuItem {
  type: 'separator';
}

export interface CustomMenuItem extends AppMenuItem {
  type: 'custom';
  data: unknown;
}
