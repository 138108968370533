import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';

import { IconModule } from '../icon/icon.module';
import { UserDrawerComponent } from './user-drawer.component';
import { WidgetHostComponent } from './widgets/host/widget-host.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    SelectButtonModule,
    SidebarModule,
    TabViewModule,
    TranslateModule
  ],
  declarations: [
    UserDrawerComponent,
    WidgetHostComponent
  ],
  exports: [
    UserDrawerComponent,
    WidgetHostComponent
  ],
})
export class UserDrawerModule {

}
