import { Injectable, Inject } from '@angular/core';
import { SessionService } from './session.service';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { API_VERSION } from './webframework.service';

interface IContent {
  [key: string]: unknown;
}
const CACHE_SIZE = 1;

@Injectable()
export class ContentService {
  // Storage Objects for All and Singular Component content
  private readonly localComponent: IContent[] = [];
  private readonly userLocale: string;

  constructor(
    private readonly sessionService: SessionService,
    private readonly http: HttpClient,
    @Inject(API_VERSION) public apiVersion: string
  ) {
    // Mock Below
    this.userLocale = 'en-US';
  }

  /**
   * @method getContent
   * @description entry point for component content requests
   * @param compontentName used to filter out first request or return already stored content
   */
  public getContent(componentName: string): Observable<unknown> {
    const uniqueContent = this.getUniqueComponentContent(componentName);
    if (!uniqueContent.length) {
      return this.getComponentContent(componentName).pipe(shareReplay(CACHE_SIZE));
    } else {
      return of(uniqueContent[0][componentName]);
    }
  }

  /**
   * @method getCurrentUser
   * @description get the current user data
   */
  public getCurrentUser(): Observable<User> {
    return this.sessionService.onReady$;
  }

  /**
   * @method getUniqueComponentContent
   * @description get the unique content for requested component
   */
  private getUniqueComponentContent(componentName: string): IContent[] {
    return this.localComponent.filter((v) => !!v[componentName]);
  }

  /**
   * @method getComponentContent
   * @description requests components content, stores it, and returns requested component content
   * @param compontentName use to filter out all results
   */
  private getComponentContent(componentName: string): Observable<IContent> {
    return this.getCurrentUser().pipe(
      switchMap((user: User) => {
        const url =
          `${this.apiVersion}/applications/${user.clientName}` +
          `/collections/localization-${this.userLocale}/configurations`;
        const apiUrl = `${url}/${componentName}-${this.userLocale}`;

        return this.http.get<IContent>(apiUrl).pipe(
          map((response) => {
            this.localComponent.push({ [componentName]: response });

            return response;
          })
        );
      })
    );
  }
}
