/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Component,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  TemplateRef,
  ContentChildren,
  QueryList,
  AfterContentInit,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Optional
} from '@angular/core';

import { Subject, of } from 'rxjs';
import { takeUntil, map, finalize } from 'rxjs/operators';

import { PrimeTemplate } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

import * as _ from 'lodash';

import { ContentService, SessionService, User } from '@webframework/client-core';
import { GridComponent } from '@webframework/grid';

import { SaveCredentialsPayload, CredentialServiceModal } from './../../models/credentials.interface';
import { CredentialsService } from './../../services/credentials.service';
import { WFC_VERSION } from '../../../version';
import { COMPONENT_OPTIONS } from '@webframework/client-shared';
import { ColsInterface } from '../../models/cols-interface';
@Component({
  selector: 'wfc-set-credentials',
  styleUrls: ['./set-credentials.component.scss'],
  templateUrl: './set-credentials.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetCredentialsComponent implements OnInit, AfterContentInit, OnDestroy {
  public static version = WFC_VERSION.full;
  @Input() applicationName: string;

  @Input() appServices = true;

  @Input()
  set closable(value: boolean) {
    if (value) {
      this.panel.hide();
    }
  }

  /* eslint-disable @angular-eslint/no-output-on-prefix -- intentional naming */
  @Output() onEdit = new EventEmitter();
  @Output() onEditComplete = new EventEmitter();
  @Output() onEditCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  @Output() onError = new EventEmitter();
  /* eslint-enable @angular-eslint/no-output-on-prefix */

  @ViewChild('dt', { static: false }) grid!: GridComponent;
  @ViewChild('overlay', { static: false }) panel!: OverlayPanel;
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

  editable = true;
  password = '';
  serviceName = '';
  appName = '';
  successmodal = false;
  username = '';
  passWordValue = '';
  userNameValue = '';
  buttonDisable = false;

  loading = false;
  row: any;
  rowData: any;
  data: any = [];
  cols: ColsInterface[] = [
    {
      columnName: 'Application',
      columnDisplayName: 'SET_CREDENTIALS_DIALOG.POPOVERLABELS.APPLICATION',
      editable: false,
      width: '150px'
    },
    {
      columnName: 'ServiceName',
      columnDisplayName: 'SET_CREDENTIALS_DIALOG.POPOVERLABELS.SERVICENAME',
      editable: false,
      width: '150px'
    },
    {
      columnName: 'ServiceType',
      columnDisplayName: 'SET_CREDENTIALS_DIALOG.POPOVERLABELS.SERVICETYPE',
      editable: false,
      width: '150px'
    },
    { columnName: 'UserName', columnDisplayName: 'SET_CREDENTIALS_DIALOG.POPOVERLABELS.USERNAME', width: '150px' },
    { columnName: 'Password', columnDisplayName: 'SET_CREDENTIALS_DIALOG.POPOVERLABELS.PASSWORD', width: '150px' }
  ];
  totalRecords = (this.data || []).length;
  captionTemplate: TemplateRef<unknown>;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
  @Optional() @Inject(COMPONENT_OPTIONS) config,
    private readonly sessionService: SessionService,
    public readonly contentService: ContentService,
    public credentialsService: CredentialsService,
    private readonly ref: ChangeDetectorRef) {

    this.appServices = _.get<boolean>(
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      _.transform(config, function(result: any, val, key: string) {
        result[key.toLowerCase()] = val;
      }),
      'appservices',
      this.appServices
    );

    if (this.appServices) {
      this.cols = _.pullAllBy(this.cols, [{ columnName: 'Application' }], 'columnName');
    }
  }

  ngOnInit(): void {
    (this.appServices && !this.applicationName
      ? this.sessionService.onReady$.pipe(map((userObj: User) => userObj.clientName))
      : of(this.applicationName))
      .subscribe((clientName: string) => {
        this.loading = true;
        this.applicationName = clientName;
        this.getCredetialforServices();
        this.ref.markForCheck();
      });
  }

  ngAfterContentInit(): void {
    const pTemplate = this.templates.find((item: PrimeTemplate) => (item.getType() || '') === 'caption');
    if (pTemplate) {
      this.captionTemplate = pTemplate.template;
    }
  }

  /**
   * @method getCredetialforServices
   * @description method to get all services and their credential
   */
  public getCredetialforServices(): void {
    (this.applicationName
      ? this.credentialsService.getAppServices(this.applicationName)
      : this.credentialsService.getAllServices(false))
      .pipe(takeUntil(this.destroy$)
        , finalize(() => {
          this.loading = false;
          this.ref.markForCheck();
        }))
      .subscribe((res: CredentialServiceModal[]) => {
        this.data = res;
        this.credentialsService.appRowData = JSON.parse(JSON.stringify(res));
      }, (error: any) => {
        this.credentialsService.handleError(error);
      });
  }

  /**
   * @method onEditInit
   * @param $event: result from the cell-editor
   * @description To handle the intial data when user start cell editing
   */
  public onEditInit($event: any): void {
    this.buttonDisable = true;
    this.serviceName = $event.ServiceName;
    this.appName = $event.Application;
    this.rowData = $event;
    this.password = $event.Password;
    this.username = $event.UserName;
    this.userNameValue = this.username;
    this.passWordValue = this.password;
    this.onEdit.emit($event);
  }

  /**
   * @method onEditcell
   * @param rowData: row data of the data table
   * @param field: field name
   * @description To update row and column data variable on keyup event
   */
  public onEditcell(rowData: unknown, field?: string, value?: string): void {
    this.row = rowData;
    this.buttonDisable = false;
    if (field === 'UserName') {
      this.userNameValue = value || '';
    }
    if (field === 'Password') {
      this.passWordValue = value || '';
    }
  }

  /**
   * @method editSuccess
   * @param overlaypanel: instance of overlayPanel component
   * @description To handle the result after cell data edit is completed
   */
  public editSuccess(event: Event, overlaypanel: OverlayPanel): void {
    event.preventDefault();
    event.stopPropagation();

    overlaypanel.hide();

    const payload: SaveCredentialsPayload = {
      password: this.passWordValue,
      username: this.userNameValue
    };
    this.credentialsService
      .saveCredetials(this.appName,this.serviceName,payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.successmodal = true;
          this.updateCellValue(this.passWordValue, this.userNameValue);
        },
        (error: any) => {
          if (error.error && error.error.message) {
            this.onError.emit({ message: error.error.message, severity: 'error' });
            this.credentialsService.handleError(error.error);
          } else {
            this.onError.emit({ message: error.message, severity: 'error' });
            this.credentialsService.handleError(error);
          }
        }
      );
  }

  /**
   * @method editCancel
   * @param overlaypanel: instance  of overlayPanel component
   * @description To handle the result after cell data edit is cancelled
   */
  public editCancel(event: Event, overlaypanel: OverlayPanel): void {
    event.preventDefault();
    event.stopPropagation();

    overlaypanel.hide();
    this.onEditCancel.emit();
  }

  /**
   * @method updateCellValue
   * @param password: updated value of password cells
   * @param username: updated value of username cells
   * @description To handle the result after cell data edit is completed
   */
  public updateCellValue(password: string, username: string): void {
    this.row.Password = password;
    this.row.UserName = username;
    this.ref.markForCheck();
    this.onEditComplete.emit(this.row);
  }

  /**
   * @method hideDialog
   * @description To hide the credentil dialog
   */
  public hideDialog(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.successmodal = false;
    this.onSuccess.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    if (this.panel) {
      this.panel.hide();
    }
  }
}
