import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDrawerContractViewerComponent } from './user-drawer-contract-viewer/user-drawer-contract-viewer.component';
import { USER_DRAWER_WIDGET } from '@webframework/client-shared';
import {CardModule} from "primeng/card"
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [CommonModule, CardModule, ButtonModule],
  declarations: [UserDrawerContractViewerComponent],
  entryComponents: [UserDrawerContractViewerComponent],
  providers: [
    {
        provide: USER_DRAWER_WIDGET,
        useValue: UserDrawerContractViewerComponent, 
        multi: true
    }
  ]
})
export class UserDrawerContractViewerModule {
  public static entry = UserDrawerContractViewerComponent;
}
