import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionManagementService {
  private readonly _versions = new BehaviorSubject<Record<string, string>>({});

  public get versions(): Observable<Record<string, string>> {
    return this._versions.asObservable();
  }

  public registerVersion(componentName: string, version: string): void {
    this._versions.next({
      ...this._versions.value,
      [componentName]: version,
    });
  }
}
