import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { pulseAnimation } from './sidenav-item.component.animation';
import { AppMenuItem } from '@webframework/client-core';

@Component({
  selector: 'wfc-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss', './_theming.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [pulseAnimation],
})
export class SidenavItemComponent {
  @Input()
  item?: AppMenuItem;
  @Input()
  selectedItem?: AppMenuItem;
  @Input()
  children?: AppMenuItem[];
  @Input()
  sidenavCollapsed = false;
  @Input()
  popoverTarget?: any;

  @HostBinding('class.wfc-sidenav-item-primary-menu-item-selected')
  get selected() {
    return (this.item && this.item.id) === (this.selectedItem && this.selectedItem.id);
  }

  @Output()
  itemClick = new EventEmitter<AppMenuItem>();
  @Output()
  toggleSidenavItem = new EventEmitter<AppMenuItem>();
  @Output()
  iconFlashed = new EventEmitter<AppMenuItem>();

  mouseHover = false;

  get showToolTip() {
    return this.sidenavCollapsed && this.item && !this.item.expanded;
  }

  get pulsateIcon() {
    return (
      this.item && this.item.notificationcount && this.item.notificationcount !== (this.item.lastNotificationCount || 0)
    );
  }

  @HostBinding('class.wfc-sidenav-item-expanded')
  get expanded() {
    return this.item && this.item.expanded && this.children && this.children.length > 0;
  }

  get showSecondary() {
    return (
      (this.item && !this.sidenavCollapsed && this.expanded) || (this.item && this.sidenavCollapsed && this.mouseHover)
    );
  }

  @HostListener('mouseenter')
  mouseEnter() {
    this.mouseHover = true;
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.mouseHover = false;
  }

  isSelected(item?: Pick<AppMenuItem, 'id'>) {
    return (item && item.id) === (this.selectedItem && this.selectedItem.id);
  }

  trackById(item: Pick<AppMenuItem, 'id'>) {
    return item.id;
  }
}
