import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { GenericResourceAccessDialogComponent } from './dsp.generic-resource-access-dialog.component';
import { NotificationModule } from '@webframework/notification';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ListboxModule } from 'primeng/listbox';
@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    ListboxModule
  ],
  exports: [GenericResourceAccessDialogComponent],
  declarations: [GenericResourceAccessDialogComponent]
})
export class GenericResourceAccessDialogModule { }
