import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { BroadcastService, NewrelicInteractionService } from '@webframework/client-core';
import * as fromIndex from './reducers';
import { CoreService } from '../../../../libs/shared/src/lib/Services/core.service';
/**
 * The root component of the application
 */
@Component({
  selector: 'gs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationComponent {

  items$: Observable<MenuItem[]>;
  showSidebar$:Observable<Boolean>;

  constructor(
    private readonly store: Store<fromIndex.State>,
    private newrelicInteractionService: NewrelicInteractionService,
    public coreService: CoreService,
    private cdRef: ChangeDetectorRef,
    private broadcastService: BroadcastService
  ) {

    this.store.dispatch({
      type: '[Breadcrumb]: Add Breadcrumb',
      payload: {
        id: 'homeMenuItem',
        label: 'Home',
        routerLink: ['home'],
      },
    });

    this.items$ = this.store.pipe(
      select(fromIndex.getBreadcrumbItem),
      map((items: MenuItem[]) => {
        return items;
      })
    );

    this.showSidebar$ = this.store.select("notificationBar").pipe(
      pluck("isVisible")
    );

    this.broadcastService.on('showNotifBar').subscribe((event: any) => {
      this.store.dispatch({
        type:"[NotificationBar] Show"
      })
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
