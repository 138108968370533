import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { API_VERSION } from './webframework.service';
import { Observable } from 'rxjs';

export interface ActivityAccess{
  access: boolean;
}

@Injectable()
export class AccessManagementServiceManager {
  accessManagementServiceURL = '/api/accessManagement';
  constructor(
    private readonly sessionService: SessionService,
    private readonly http: HttpClient,
    @Inject(API_VERSION) public apiVersion?: string
  ) {}

  checkUserAccessOnActivity(activityId: string): Observable<boolean> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap((user) => {
        const url = `${this.accessManagementServiceURL}/apps/${user.clientName}/activities/${activityId}/accesstype`;

        return this.http.get<ActivityAccess>(url).pipe(map(acc => acc.access));
      })
    );
  }
}
