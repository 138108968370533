import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthenticationService, User } from '@webframework/client-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTokenGuardGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const cPromise: Promise<boolean> = new Promise(async (resolve, reject) => {
      let tokens: string[] = await this.authService
        .getUser()
        .toPromise()
        .then((user: User) => user.roles);
      const url = state.url;

      if(url.includes('forbidden')){
        resolve(true);
        return;
      }
      if (
        !tokens.includes('controller') &&
        !tokens.includes('contractor') &&
        !tokens.includes('cost-control') &&
        !tokens.includes('dsis-admin') &&
        !tokens.includes('service_tickets')
      ) {
        this.router.navigate(['forbidden']);
        resolve(true);
        return;
      }
      if (
        url.includes('approvals') &&
        !tokens.includes('controller') &&
        tokens.includes('contractor')
      ) {
        this.router.navigate(['cost_notifications']);
        resolve(true);
        return;
      }

      if (
        url.includes('cost_notifications') &&
        (tokens.includes('controller') || tokens.includes('cost-control')) &&
        !tokens.includes('contractor')
      ) {
        this.router.navigate(['approvals']);
        resolve(true);
        return;
      }

      if (
        url.includes('cost_notifications') &&
        tokens.includes('service_tickets') &&
        !tokens.includes('contractor')
      ) {
        this.router.navigate(['service_tickets']);
        resolve(true);
        return;
      }

      resolve(true);
    });

    return cPromise;
  }
}
