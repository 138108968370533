import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'wfc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() icon?: string;
  @Input() size = 18;

  @HostBinding('style.height.px')
  get height() {
    return this.size;
  }

  @HostBinding('style.width.px')
  get width() {
    return this.size;
  }
}
