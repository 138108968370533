export interface HostConfig {
  module: string;
  title: string;
  hasHeader: boolean;
  disableCollapse: boolean;
  disableMaximize: boolean;
  scroll: boolean;
  maxWidth?: string;
  resizeStrategy: 'flex' | 'explicit';
  config: Record<string, string>;
}
