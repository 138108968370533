import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppMenuItem, PopoverMenuItem } from '@webframework/client-core';
import { OverlayPanel } from 'primeng/overlaypanel';

import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';

@Component({
  selector: 'wfc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss', './_theming.scss']
})
export class SidenavComponent {
  @Input()
  set popoverItemId(itemId: string) {
    this._popoverItemId = itemId;

    if (!this.popoverContainerRef) {
      return;
    }

    if (!itemId) {
      this.popoverContainerRef.hide();

      return;
    }

    if (!this.sidenavComponents) {
      return;
    }

    const sidenavComponent = this.sidenavComponents.find((c) => !!c.item && c.item.id === itemId);

    if (sidenavComponent && sidenavComponent.item) {
      this.popoverTitle = sidenavComponent.item.menuname;
      this.popoverContent = (sidenavComponent.item as PopoverMenuItem).module;
      this.popoverContainerRef.show({}, sidenavComponent.popoverTarget);
    }
  }

  get popoverItemId(): string {
    return this._popoverItemId || '';
  }

  @Input()
  collapsed?: boolean;
  @Input()
  nodes?: AppMenuItem[];
  @Input()
  selectedItem?: AppMenuItem;

  @Output()
  itemClick = new EventEmitter<AppMenuItem>();
  @Output()
  toggleSidenavItem = new EventEmitter<AppMenuItem>();
  @Output()
  toggleSidenav = new EventEmitter();
  @Output()
  popoverClose = new EventEmitter();
  @Output()
  iconFlashed = new EventEmitter<AppMenuItem>();

  popoverTitle?: string;
  popoverContent?: string;

  @ViewChild(OverlayPanel, { static: true })
  popoverContainerRef?: OverlayPanel;
  @ViewChild('popoverTarget', { static: true })
  popoverTarget: any;
  @ViewChildren(SidenavItemComponent)
  sidenavComponents?: QueryList<SidenavItemComponent>;
  private _popoverItemId?: string;

  trackById(item: Pick<AppMenuItem, 'id'>) {
    return item.id;
  }
}
