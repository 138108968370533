import { ActionBar } from './action-bar';

export interface BrandConfig {
  appList: AppConfig[];
  footer: string;
  hideSidenavOnLoad: boolean;
  headerItems: HeaderItem[];
  landingPageUrl?: string;
  languages?: string[];
  locales?: Locale[];
  logopath: string;
  longDateFormat: string;
  name: string;
  organizationName: string;
  shortDateFormat: string;
  subHeaderVisible: boolean;
  actionBar?: ActionBar[];
  enableTracking: boolean;
  theme?: string;
}

export interface HeaderItem {
  badge?: string | number;
  data: unknown;
  icon: string;
  fontSet?: string;
  id: string;
  target: string;
  text: string;
  type: 'popover' | 'action';
  popoverDirection?: 'ltr' | 'rtl';
}

export interface AppConfig {
  icon: string;
  label: string;
  value: string;
}

export interface Locale {
  value: string;
  label: string;
}
