import { ActivatedRoute } from '@angular/router';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'wfc-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorComponent {
    error: string;
    errorText: string;
    isShowAlertPopup = true;

    constructor(private readonly activateRoute: ActivatedRoute) {
        this.error = this.activateRoute.snapshot.params.error;
        this.errorText = this.activateRoute.snapshot.params.errorDetails;
    }

    dismissAlertMessage() {
        this.isShowAlertPopup = false;
    }
}
