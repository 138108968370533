import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigEditorService } from '../../services/config-editor.service';
import { jsonValidator } from './json.validator';
import { NotificationService } from '@webframework/notification';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
@Component({
  selector: 'wfc-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss']
})
export class JsonEditorComponent implements OnDestroy {
  public selectedConfig$ = new BehaviorSubject<string>('');
  form: FormGroup;
  resetConfig: string;
  configPath: string;
  loading = false;
  hasPermission$: Observable<boolean>;
  private resultSubscription!: Subscription;
  private saveConfigSubscription!: Subscription;
  constructor(public configEditorService: ConfigEditorService, private readonly notification: NotificationService) {
    this.hasPermission$ = this.configEditorService.hasPermission().pipe(
      filter(isAdmin => isAdmin),
      tap(() => this.getSelectedConfig())
    );
  }

  getSelectedConfig(): void {
    this.createForm();
    this.resultSubscription = this.configEditorService.sendResultToJsonEditor().subscribe(result => {
      if (result.data === undefined) {
        this.selectedConfig$.next('');
        this.form.get('configJson').disable();
        this.loading = true;
      } else {
        const config = JSON.stringify(result.data, undefined, 2);
        this.selectedConfig$.next(config);
        this.resetConfig = config;
        this.configPath = result.path;
        this.form.get('configJson').enable();
        this.loading = false;
      }
    });
  }

  createForm(): void {
    this.form = new FormGroup({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      configJson: new FormControl({ value: '', disabled: true }, [Validators.required, jsonValidator()])
    });
  }

  saveJsonConfiguration(): void {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config = this.form.get('configJson').value;
    this.saveConfigSubscription = this.configEditorService
      .sendConfigurations(JSON.parse(config), this.configPath)
      .subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.resetConfig = config;
        this.loading = false;
        const successMessage = 'CONFIG_EDITOR.CONFIGURATIONSAVESUCCESS';
        this.showSuccessMessage(successMessage);
      });
  }

  public showSuccessMessage(messageToShow: string): void {
    this.notification.clear();
    this.notification.addMessage({
      severity: 'success',
      summary: messageToShow,
      closable: true,
      autoClear: true,
      notificationTimeout: 2000
    });
  }

  ngOnDestroy(): void {
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    if (this.saveConfigSubscription) {
      this.saveConfigSubscription.unsubscribe();
    }
  }
}
