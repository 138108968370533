import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { HostPanel } from '../models/host-panel';
import { Panel } from '../models/panel';

@Component({
  selector: 'wfc-host-container',
  templateUrl: './host-container.component.html'
})
export class HostContainerComponent {
  @HostBinding('style.max-width')
  maxWidth?: string;
  @Input()
  panel?: Panel;

  @Output()
  maximize = new EventEmitter<HostPanel>();
  @Output()
  collapse = new EventEmitter<HostPanel>();
  @Output()
  restore = new EventEmitter<HostPanel>();
  @Output()
  ratioChange = new EventEmitter<Panel>();

  constructor(private readonly hostElement: ElementRef) {}

  changeWidth(event?: string) {
    this.maxWidth = event;
  }

  updateRatioFromView(orientation: 'vertical' | 'horizontal') {
    if (!this.panel) {
      return;
    }

    const nativeElement = this.hostElement.nativeElement;
    this.panel.ratio = orientation === 'vertical' ? nativeElement.offsetWidth : nativeElement.offsetHeight;
  }
}
