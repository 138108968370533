import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@webframework/client-core';
import { NotificationModule } from '@webframework/notification';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { ConfigEditorModule } from './config-editor.module';

@NgModule({
  declarations: [JsonEditorComponent],
  entryComponents: [JsonEditorComponent],
  imports: [
    CommonModule,
    CoreModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    NotificationModule,
    TranslateModule,
    InputTextareaModule,
    ConfigEditorModule],
  exports: [JsonEditorComponent]
})
export class JsonEditorModule {
  public static entry = JsonEditorComponent;
}
