import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@webframework/client-core';
import { TreeModule } from 'primeng/tree';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfigTreeComponent } from './components/config-tree/config-tree.component';
import { ConfigEditorModule } from './config-editor.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [ConfigTreeComponent],
  entryComponents: [ConfigTreeComponent],
  imports: [
    CommonModule,
    TreeModule,
    CoreModule,
    DialogModule,
    ButtonModule,
    ProgressSpinnerModule,
    TranslateModule,
    ConfigEditorModule
  ],
  exports: [ConfigTreeComponent]
})
export class ConfigTreeModule {
  public static entry = ConfigTreeComponent;
}
