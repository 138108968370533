import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { API_VERSION } from './webframework.service';
import { UserActivity } from '../models/user-activity';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerService {
  public organizationName = '';
  private _requestID = uuid();

  constructor(
    private readonly router: Router,
    private readonly _http: HttpClient,
    private readonly _sessionService: SessionService,
    @Inject(API_VERSION) public apiVersion: string
  ) {}

  track(activity: Partial<UserActivity>, useLastRequestId = false): void {
    this._requestID = useLastRequestId ? this._requestID : uuid();
    if (this.apiVersion === '') {
      this.apiVersion = '/api/v3';
    }
    this._sessionService
      .getCurrentUser()
      .pipe(
        switchMap(user =>
          this._http.post('$this.apiVersion/activity', {
            rid: this._requestID,
            timestamp: new Date().toISOString(),
            actor: user.userName,
            cmp: this.organizationName,
            verb: activity.verb ? activity.verb : activity.act,
            userId: user.userId ? user.userId : '',
            source: user.clientName,
            object: {
              type: activity.type ? activity.type : activity.act,
              description: activity.description ? activity.description : activity.mod
            },
            dataContext: activity.dataContext ? activity.dataContext : [],
            details: activity.details ? activity.details : {},
            duration: activity.duration ? activity.duration : ''
          })
        )
      )
      .subscribe();
  }

  enable(): void {
    this.router.events.subscribe((event: unknown) => {
      if (event instanceof NavigationEnd) {
        this.track({
          verb: 'navigated',
          type: 'Page',
          description: event.url
        });
      }
    });
  }
}
