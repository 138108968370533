import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  LowerCasePipe,
  PercentPipe,
  SlicePipe,
  TitleCasePipe,
  UpperCasePipe
} from '@angular/common';

@Pipe({
  name: 'generic'
})
export class GenericPipe implements PipeTransform {

  private readonly pipes: { [pipe: string]: PipeTransform } = {
    currency: new CurrencyPipe(this.localeId),
    date: new DatePipe(this.localeId),
    // eslint-disable-next-line id-blacklist
    number: new DecimalPipe(this.localeId),
    lowercase: new LowerCasePipe(),
    percent: new PercentPipe(this.localeId),
    slice: new SlicePipe(),
    titlecase: new TitleCasePipe(),
    uppercase: new UpperCasePipe()
  };
  constructor(@Inject(LOCALE_ID) private readonly localeId: string) { }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  transform(value: any, format: string): string {
    if (!format) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value;
    }

    const _pipes = format.split('|');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let _value = value;

    for (let i = 0; i < _pipes.length; i++) {
      const args = _pipes[i].trim().split(':');
      const pipe = this.pipes[args.shift() || ''];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      _value = pipe ? pipe.transform(value, ...args) : value;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return _value;
  }
}
