import { Component, Input } from '@angular/core';
import { AppConfig } from '@webframework/client-core';

@Component({
  selector: 'wfc-app-title',
  templateUrl: './app-title.component.html',
  styleUrls: ['./app-title.component.scss', './_theming.scss']
})
export class AppTitleComponent {
  @Input()
  titles?: AppConfig[];

  get imageSource() {
    return this.titles ? this.titles[0].icon : '';
  }

  get disabled() {
    return this.titles && this.titles.length <= 1;
  }

  onTitleSelected(event: any) {
    window.location.href = event.value;
  }
}
