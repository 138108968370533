import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Newrelic } from '../models/newrelic';

declare let newrelic: Newrelic;

@Injectable({
  providedIn: 'root'
})
export class NewrelicInteractionService {

  constructor(private readonly router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && ('newrelic' in window)) {
        const interaction = newrelic.interaction();
        interaction.setName(event.url);
        interaction.save();
      }
    });
  }
}
