import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import * as fromMasterPage from '../reducers/root.reducer';

@Injectable()
/**
 * Reroutes a path according to configuration:
 * The URL is picked from branding config, if not found, the first menuurl from the sidenav items is chosen
 */
export class HomeGuard implements CanActivate {
  constructor(private readonly _store: Store<fromMasterPage.MasterPageState>, private readonly _router: Router) {}

  canActivate() {
    return this._store.pipe(
      select(fromMasterPage.selectHomeUrl),
      filter((url) => !!url), // Only emit non-empty URLs
      map((url) => this._router.parseUrl(url))
    );
  }
}
