import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuItem, NavigationMenuItem, WebFrameworkService } from '@webframework/client-core';
import { PANEL_CONFIG } from '@webframework/client-layout';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotifyGroupItemInterface } from '../../model/notify-group-item-interface';

export class DetailDescription {
  image = '';
  designation = '';
  phone = '';
  email = '';
  description = '';
  appImage = '';
}

export interface OutputSplitURL {
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  search: string;
  hash: string;
}
@Component({
  selector: 'wfc-detail-view',
  templateUrl: './dsp.detail-view.html',
  styleUrls: ['./dsp.detail-view.css']
})
export class DetailViewComponent implements  OnInit {

  @Input() resources: unknown;
  @Input() selectionName = '';
  @Input() appImageTitle = '';
  @Input() noGroupMessage = '';
  @Input() noProductMessage = '';
  @Input() noUserMessage = '';
  @Input() noadGroupMessage = '';
  @Input() description: DetailDescription = new DetailDescription();
  @Input() product;
  @Input() usersList: [] = [];
  @Input() groupsListTitle = '';
  @Input() productsListTitle = '';
  @Input() usersListTitle = '';
  @Input() groupsList: [] = [];
  @Input() productsList: [] = [];
  @Input() adgroupsList: [] = [];
  @Input() detailViewcontext = '';
  @Input() groupListTitle = '';
  @Input() appName = '';
  @Output() notifyGroupItem: EventEmitter<NotifyGroupItemInterface> = new EventEmitter<NotifyGroupItemInterface>();
  userandRolesmenuurl: string;
  isUsersandRoles$: Observable<boolean> = of(false);

  constructor(private readonly router: Router, private readonly _wfService: WebFrameworkService,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    @Inject(PANEL_CONFIG) private readonly config: any,  ) {
    this.noGroupMessage = '';
    this.noProductMessage = '';
    this.noUserMessage = '';
    this.noadGroupMessage = '';
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
    this.userandRolesmenuurl = this.config!.usersandrolesurl;
  }
  ngOnInit (): void {

    this.isUsersandRoles$ = this._wfService.getMenuConfiguration().pipe (
      // eslint-disable-next-line arrow-body-style
      map((items: AppMenuItem[]) => {
        return ( items.find(item  => (<NavigationMenuItem>item).menuurl === this.userandRolesmenuurl)) ? true : false;
      }
      )
    );
  }

  setAltImage(property: string): void {
    if (this.description) {
      if (property === 'image') {
        this.description.image = '/uploadfiles/Misc/alternate.png';
      } else {
        this.description.appImage = '/uploadfiles/Misc/alternate.png';
      }
    }
  }

  onGroupItemClick(id: string, action: string): void {
    let url;
    let queryParams;
    switch (action) {
      case 'userDetail': {
        url = 'usersandroles';
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- intentional
        this.notifyGroupItem.observers.length > 0
          ? this.notifyGroupItem.emit({ context: 'users', username: id })
          : queryParams = { queryParams: { username: id } };

        break;
      }
      case 'groupDetail': {
        url = 'usersandroles';
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions -- intentional
        this.notifyGroupItem.observers.length > 0
          ? this.notifyGroupItem.emit({ context: 'groups', groupname: id })
          : queryParams = { queryParams: { groupname: id } };
        break;
      }
      case 'adgroupDetail': {
        url = 'usersandroles';
        queryParams = { queryParams: { context: 'adgroups', groupname: id } };
        break;
      }
      default: {
        url = 'products';
        queryParams = { queryParams: { productid: id } };
        break;
      }
    }

    if (url && queryParams) {
      void this.router.navigate([url], queryParams);
    }
  }

  splitURL(): OutputSplitURL {
    const href = window.parent.location.href;
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec, no-useless-escape -- intentional
    const match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    let output = { protocol: '', host: '', hostname: '', port: '', pathname: '', search: '', hash: '' };
    if (match) {
      output = {
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
      };

      if (output.port === undefined) {
        output.port = '80';
      }
    }

    return output;
  }
}
