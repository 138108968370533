import { Component, OnInit } from '@angular/core';
import { WebFrameworkService } from '@webframework/client-core';
import { AppdropdownserviceService } from './appdropdownservice.service';

@Component({
  selector: 'wfw-dropdown', // tslint:disable-line
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  providers: [AppdropdownserviceService]
})
export class DropdownMenuComponent implements OnInit {
  applications: any[] = [];
  dropdownExists = false;
  dropdownState = false;
  selectedApp = '';
  constructor(public webFrameworkService: WebFrameworkService, public appdropdownservice: AppdropdownserviceService) {}
  ngOnInit() {
    this.appdropdownservice.getAllApplications().subscribe((res: any) => {
      if (res.length > 0) {
        this.dropdownExists = true;
        this.applications = res;
        this.selectedApp = this.applications[0].name;
      } else {
        this.dropdownExists = false;
      }
    });
    this.dropdownState = false;
  }
  closeMenu() {
    this.dropdownState = false;
  }

  toggleDropdown() {
    this.dropdownState = !this.dropdownState;
  }
  onItemSelect(txt: any) {
    this.selectedApp = txt;
    this.toggleDropdown();
  }
}
