import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: unknown[], field: string, value: unknown): unknown[] {
    if (!items) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return items.filter(it => it[field] === value);
  }
}
