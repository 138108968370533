import { Injectable } from '@angular/core';
import { Router, NavigationEnd, UrlSerializer } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  AppMenuItem,
  CustomMenuItem,
  NavigationMenuItem,
  ParentMenuItem,
  WebFrameworkService,
} from '@webframework/client-core';
import { getPanelEntities, layoutActions, LayoutState, panelActions } from '@webframework/client-layout';
import { normalize, schema } from 'normalizr';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import * as sidenavItem from '../actions/sidenav-item.actions';
import * as sidenav from '../actions/sidenav.actions';
import * as fromMasterPage from '../reducers/root.reducer';
import { LAYOUT_PANELS_ID } from '../reducers/sidenav-item.reducer';

const LAYOUT_SEPARATOR_ID = '__LAYOUT_SEPARATOR__';

@Injectable()
export class SidenavEffects {
  loadSidenav$ = createEffect(() =>
    this.action$.pipe(
      ofType(sidenav.load),
      switchMap(() => this.webFrameworkService.getMenuConfiguration()),
      map((data) => this.normalizeSidenav(data)),
      map((normalized) => sidenav.loadSuccess(normalized)),
      catchError((error) => of(sidenav.loadFail({ error })))
    )
  );

  /**
   * Once sidenav is loaded, select the appropriate sidenav item
   * based on the router URL
   */
  selectAfterSidenavLoad$ = createEffect(() =>
    this.action$.pipe(
      ofType(sidenav.loadSuccess),
      map((props) => props),
      withLatestFrom(this.store.pipe(select(fromMasterPage.selectHomeUrl))),
      switchMap(([{ items }, homePageUrl]) =>
        this.navigationUrl$
          .pipe(
            filter((url: string) => url != null),
            map((url: string) => this.getSideNavItemByUrl(items, url, homePageUrl)),
            take(1))
      ),
      map((item) => sidenavItem.select({ payload: item ? item.id : null }))
    )
  );

  addLayoutItem$ = createEffect(() =>
    this.action$.pipe(
      ofType(sidenav.loadSuccess),
      switchMap(() => [
        sidenav.addItem({
          payload: {
            id: LAYOUT_SEPARATOR_ID,
            type: 'separator',
            icon: '',
            displayOrder: 100,
            menuname: '',
            lastNotificationCount: 0,
          },
        }),
        sidenav.addItem({
          payload: <ParentMenuItem>{
            id: LAYOUT_PANELS_ID,
            type: 'parent',
            icon: 'show_panel',
            displayOrder: 101,
            menuname: 'SIDENAV.SHOW_PANELS',
            notificationcount: 0,
            children: [] as AppMenuItem[],
          },
        }),
      ])
    )
  );

  addInitialCollapsedPanels$ = createEffect(() =>
    this.action$.pipe(
      ofType(layoutActions.loadSuccess),
      (loadSuccess$) => combineLatest([loadSuccess$, this.action$.pipe(ofType(sidenav.loadSuccess))]),
      map(([successAction, _other]) => successAction), // Get the layoutActions.LOAD_SUCCESS action
      withLatestFrom(this.store.pipe(select(fromMasterPage.getAllSidenavItems))),
      switchMap(([action, appMenuItems]) => [
        ...(appMenuItems || [])
          .filter((i: CustomMenuItem) => i.data === 'sidenav-child')
          .map((i) => sidenav.removeItem({ payload: i.id })),
        ...action.panels.filter((p) => p.collapsed).map((p) => this.getCollapsePanelAction(p as any)),
      ])
    )
  );

  addCollapsedPanel$ = createEffect(() =>
    this.action$.pipe(
      ofType(panelActions.collapse),
      withLatestFrom(this.store.pipe(select(getPanelEntities))),
      map(([{ payload }, panels]) => panels[payload] as any),
      map((minimizedPanel) => this.getCollapsePanelAction(minimizedPanel))
    )
  );

  removeCollapsedPanel$ = createEffect(() =>
    this.action$.pipe(
      ofType(panelActions.restore),
      map(({ payload }) => sidenav.removeItem({ payload }))
    )
  );

  replaceSidenav$ = createEffect(() =>
    this.action$.pipe(
      ofType(sidenav.replaceItems),
      map(({ payload }) => this.normalizeSidenav(payload)),
      map((normalized) => sidenav.loadSuccess(normalized)),
      catchError((error) => of(sidenav.loadFail({ error })))
    )
  );

  private readonly navigationUrlSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public readonly navigationUrl$ = this.navigationUrlSource.asObservable();

  constructor(
    private readonly store: Store<LayoutState>,
    private readonly action$: Actions,
    private readonly webFrameworkService: WebFrameworkService,
    private readonly router: Router,
    private readonly serializer: UrlSerializer
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event)
    ).subscribe((event: NavigationEnd) => {      
      this.navigationUrlSource.next(event.url);
    });
  }

  private normalizeSidenav(appMenuItems: AppMenuItem[]) {
    const menuEntityname = 'appMenu';

    // define schema
    const menu = new schema.Entity(menuEntityname);
    const children = new schema.Array(menu);
    menu.define({ children });

    // normalize
    const normalized = normalize(appMenuItems, children);

    // extract entities
    const appMenuEntities = normalized.entities[menuEntityname];

    if (!appMenuEntities) {
      return { items: [], rootItems: [] };
    } else {
      return {
        items: Object.keys(appMenuEntities).map((key) => ({
          ...appMenuEntities[key],
          url: this.serializer.serialize(
            this.router.createUrlTree(
              [appMenuEntities[key].menuurl],
              { queryParams: appMenuEntities[key].queryParam }))
        })),
        rootItems: normalized.result,
      };
    }
  }

  private getCollapsePanelAction(minimizedPanel: any) {
    return sidenav.addItem({
      payload: {
        id: minimizedPanel.id,
        type: 'custom',
        data: 'sidenav-child',
        icon: 'show_panel',
        displayOrder: 0,
        menuname: minimizedPanel.config.title,
      } as CustomMenuItem,
      parentId: LAYOUT_PANELS_ID,
    });
  }

  private getSideNavItemByUrl(items: AppMenuItem[], url: string, homePageUrl: string): AppMenuItem {
    url = url === '/' ? homePageUrl : url;
    let currentItem: AppMenuItem = items
      // Either find exact match for URL or find the sidenav menu item with this URL as root
      .find((item: NavigationMenuItem) =>
        item.menuurl && (item.menuurl === url ||
          item.url === url ||
          url.startsWith(item.menuurl + '/'))
      ) || ({} as AppMenuItem);

    // check whether the selected item has any parent.
    return currentItem.id
      ? items.find((item: any) => (item.children || []).includes(currentItem.id)) || currentItem
      : currentItem;
  }
}
