import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProgressSpinnerModule as PProgressSpinnerModule} from 'primeng/progressspinner';
import { ProgressSpinnerService } from './services/progress-spinner.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    PProgressSpinnerModule,
    TranslateModule
  ],
  declarations: [ProgressSpinnerComponent],
  providers: [
    ProgressSpinnerService
  ],
  exports: [ProgressSpinnerComponent]
})
export class ProgressSpinnerModule { }
