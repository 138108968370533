import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { delay, first } from 'rxjs/operators';

import { Notification } from '../models/notification';

@Injectable()
export class NotificationService {
  private notification: Notification[] = [];
  private readonly _autoClearMessages: { [id: number]: Subscription } = {};
  private readonly _messages: BehaviorSubject<Notification[]> = new BehaviorSubject(this.notification);

  private readonly messages: Observable<Notification[]> = this._messages.asObservable();

  /**
   * @method clearMessageById
   * @description this method used to clear a message by an id.
   * @param id - the id of the message to be cleared
   */
  clearMessageById(id: number): void {
    const index = this.notification.findIndex(item => item.id === id);
    if (index > -1) {
      this.removeNotificationFromAutoClearQueue(id);
      this.notification.splice(index, 1);
      this._messages.next(this.notification);
    }
  }

  /**
   * @method addMessage
   * @description add a message to the behavior subject
   * @param message - A message object to be displayed
   */
  addMessage(notification: Notification): unknown {
    const message: Notification = this._addMessageId(notification);
    this.notification.push(message);
    // All banners need to have a global hide timer
    // that will automatically hide the banner from the user after 3 seconds (3000ms).
    if (message.autoClear) {
      this._autoClearMessages[message.id] = of(message).pipe(
        delay(message.notificationTimeout || 3000),
        first()
      ).subscribe((msg: Notification) => {
        if (msg.id) {
          this.clearMessageById(msg.id);
        }
      });
    }
    this._messages.next(this.notification);

    return message.id;
  }

  /**
   * @method clear
   * @description clear out all the messages
   */
  clear(): void {
    this.notification = [];
    this._messages.next(this.notification);

    Object.keys(this._autoClearMessages).forEach((id: string) => {
      this.removeNotificationFromAutoClearQueue(+id);
    });

  }

  /**
   * @method getMessages
   * @description return all messages as an observable
   */
  getMessages(): Observable<Notification[]> {
    return this.messages;
  }

  /**
   * @method _addMessageId
   * @description this is a utility method used to add an id to a message object
   * @param message - message to add the id
   */
  private _addMessageId(message: Notification): Notification {
    const messageId = this.notification.length + 1;
    this.removeNotificationFromAutoClearQueue(messageId);

    return { ...message, id: messageId };
  }

  /**
   * Removes message from auto clear queue
   * @param id auto clear message id
   */
  private removeNotificationFromAutoClearQueue(id: number): void {
    const message$ = (this._autoClearMessages || {})[id];
    if (message$) {
      message$.unsubscribe();
      delete this._autoClearMessages[id];
    }
  }
}
